// 法人数据结构
export class corporate {
    constructor(id) {
        this.staffType = 2
        this.addition = ""
        this.agency = 0
        this.applyStatus = 11
        this.busModel = 2
        this.certificate = ""
        this.nameE1 = ""
        this.nameE1 = ""
        this.companyAddress = []
        this.companyEmail = ""
        this.companyName = ""
        this.companyNameE = ""
        this.companyType = 1
        this.companyNameE = ""
        this.companyNameE = ""
        this.infoType = 0
        this.isDelete = 0
        this.keeping = 0
        this.ordinary = ""
        this.preference = ""
        this.priceOrdinary = "1"
        this.pricePre = "1"
        this.remark = ""
        this.files1 = []
        this.files2 = []
        this.files3 = []
        this.members1 = []
        this.members2 = []
        this.members3 = []
        this.members4 = []
        this.members5 = []
        this.members6 = []
        this.controller = []
    }

}

export function newCorporate(id) {
    return new corporate(id)
}

//整理公司中的自然人数据格式
export function humanMember(member) {
    return member.map(d => ({
        "region": d.region,
        "state": d.state,
        "street": d.street,
        "building": d.building,
        "address": d.address,
        "email": d.email,
        "exCName": d.exCName,
        "exEName": d.exEName,
        "idNumbers": d.idNumbers,
        "nameC": d.nameC,
        "nameE1": d.nameE1,
        "nameE2": d.nameE2,
        "otherCName": d.otherCName,
        "otherEName": d.otherEName,
        "passports": d.passports,
        "staffId": d.staffId,
        "staffType": d.staffType,
        "shares": d.shares,
        selPassport: d.selPassport,
        selIdNumber: d.selIdNumber
    }))
}
// 整理公司中的法人数据格式
export function legalMember(member) {
    return member.map(d => ({
        // "region": d.region,
        // "state": d.state,
        // "street": d.street,
        // "building": d.building,
        // "address": d.address,
        "companyAddress": d.companyAddress,
        "companyName": d.companyName,
        "companyNameE": d.companyNameE,
        "staffId": d.staffId,
        "staffType": d.staffType,
        "shares": d.shares
    }))
}

