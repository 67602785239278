<template>
    <div>
        <el-card class="box-card">
            <el-form ref="form" :model="form" label-width="150px">
                <el-form-item label="公司名称">
                    {{ form.companyName }}
                </el-form-item>

                <el-form-item :label="$t('nd2b.txt1')">
                    <el-table :data="directors" style="width: 100%">

                        <el-table-column prop="nameC" :label="$t('dialog.cname')" width="180">
                        </el-table-column>
                        <el-table-column prop="nameE1" :label="$t('dialog.efirst')" width="180">
                        </el-table-column>
                        <el-table-column prop="nameE2" :label="$t('dialog.elast')" width="180">
                        </el-table-column>
                        <el-table-column prop="memberType" :label="$t('nd2b.txt4')">
                            <template v-slot="scope">
                                <span v-if="scope.row.memberType == 3">{{ $t('nd2b.txt2') }}</span>
                                <span v-if="scope.row.memberType == 4">{{ $t('nd2b.txt2') }}</span>
                                <span v-if="scope.row.memberType == 5">{{ $t('nd2b.txt3') }}</span>
                                <span v-if="scope.row.memberType == 6">{{ $t('nd2b.txt3') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="staffType" label="类型">
                            <template v-slot="scope">
                                {{ scope.row.staffType == 1 ? '自然人' : '法人团体' }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="180px" label="操作">
                            <template v-slot="scope">
                                <el-button icon="el-icon-edit" class="table-btn" size="small"
                                    @click="handleEdit(scope.$index, scope.row)">
                                    修改资料</el-button>

                            </template>
                        </el-table-column>
                    </el-table>

                </el-form-item>
                <el-form-item label="生效日期" prop="date1">
                    <el-date-picker type="date" placeholder="选择日期" v-model="date1" style="width: 100%;"
                        value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item label="">
                    <submitor_select @selectSubmit="selectSubmit" ref="submitor" :submitor="submitor" :show_btn="show_btn">
                    </submitor_select>
                </el-form-item>
                <el-form-item v-if="show_btn == true">
                    <el-button type="primary" @click="">{{ $t('subAud') }}</el-button>
                    <el-button @click="handleCancel">{{ $t('cancel') }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import * as api from "@/api/api"
import submitor_select from "../submitor/submitor_select.vue"
export default {
    props: {
        company_info: Object,
        form_id: Number,
        c_id: Number,
    },
    components: {
        submitor_select
    },
    watch: {
        'company_info': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined) {

                    this.form_editing = JSON.stringify(newVal);
                    this.form = JSON.parse(this.form_editing);

                    console.log(this.form)
                    this.directors = this.form.members3

                    this.show_btn = true

                }


            },
            deep: true
        },
        'form_id': {
            immediate: true,
            handler(newVal) {
                console.log(newVal)
                if (newVal != undefined && newVal != "") {
                    console.log(newVal)
                    this.get_data_form(newVal)
                    this.show_btn = false
                }


            },
            deep: true
        },
        'c_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {

                    this.get_data(newVal)



                }

            },
            deep: true
        },
    },
    data() {
        return {
            date1: '',
            date2: '',
            date3: "",
            form: {
                "companyName": "",
                "companyNameE": "",
                "companyAddr1": "",
                "submitBy": "",
                "submitUser": "",
                "companyAddr2": "",
                "companyAddr3": "",
                "companyCode": "",
                "companyEmail": "",
                "companyName": "",
                "companyNameE": "",
                "companyCode": "",
                "newEmail": '',

            },
            submitor: {},
            new_submitor: {},
            show_btn: true,
            directors: [],
            select_name: "",
            show_signName: false
        }
    },
    created() {
        // this.form = this.company_info

    },
    methods: {
        get_data_form(id) {

            // let id = this.$route.query.id

            var params = { id: id }
            api.getRequest('/nd4',
                params).then(resp => {
                    console.log(resp.data)
                    this.form = resp.data.nd4
                    this.submitor = resp.data.submitor
                    this.select_name = resp.data.nd4.memberName
                    this.date1 = resp.data.nd4.date1
                    this.show_signName = true
                    // let obj = {
                    //     ...resp.data,

                    // }

                    // this.form = obj
                    // this.date1 = this.form.date1
                    // this.date2 = this.form.date2

                    // this.$refs.submitor.form = null
                    // if (resp.data.submitors != null) {
                    //     this.submitor = resp.data.submitors

                    // }

                    // this.show_signName = true
                    // this.select_name = this.submitor.signName


                })
        },

        get_data(id) {



            var params = {}
            api.getRequest('/company/' + id,
                params).then(resp => {
                    this.form = resp.data
                    var director = []
                    this.form.members4.forEach(function (items) {
                        director.push(items)
                    })
                    this.form.members3.forEach(function (items) {
                        director.push(items)
                    })
                    this.form.members5.forEach(function (items) {
                        director.push(items)
                    })
                    this.form.members6.forEach(function (items) {
                        director.push(items)
                    })
                    this.directors = director
                    this.$refs.submitor.directors = director
                    this.$refs.submitor.show_signName = false

                    this.show_btn = true
                })
        },

        audit_submit() {

            var nd4 = {
                memberId: this.select_name,
                companyId: this.form.id,
                date1: this.date1
            }



            var data = {
                "auditBy": "",
                "auditStatus": 0,
                "auditTime": "",
                "auditUser": "",
                "companyId": this.form.id,
                "companyName": this.form.companyName,
                "companyNameE": this.form.companyNameE,
                "formType": "ND4",
                "remark": "",
                "submitBy": sessionStorage.getItem("adminKey"),
                "submitUser": sessionStorage.getItem("realName"),
                submitors: this.new_submitor,
                nd4: nd4

            }

            console.log(data)
            this.$confirm('提交审核公司资料?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.postRequest('/nd4',
                    data).then(resp => {
                        console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }

                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        selectSubmit(val) {
            console.log(val)

            this.new_submitor = val
            this.new_submitor.submitType = 4
        },

        handleCancel() {
            this.$refs['form'].resetFields();
            this.$emit('Cancel', false)
        },
    }
}
</script>