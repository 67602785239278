<template>
    <div>
        <!-- 用户列表 -->
        <div class="ibox ">
            <div class="ibox-title">
                <el-button-group>
                    <el-button type="primary" icon="el-icon-edit" @click="handleNew">添加用户</el-button>
                </el-button-group>
            </div>
            <div class="ibox-content">
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ color: '#666', fontSize: '14px', backgroundColor: '#f7f8f9', }" size="medium"
                    empty-text="没有符合的数据">
                    <el-table-column label="姓名" prop="realName" sortable> </el-table-column>
                    <el-table-column label="用户名" prop="username" sortable> </el-table-column>
                    <el-table-column label="用户类型" prop="adminType" sortable> </el-table-column>
                    <el-table-column align="right">
                        <template v-slot:header="">
                            <el-input v-model="search" size="small" placeholder="关键字搜索" @keyup.enter.native="get_data"
                                v-on:input="empty_key">
                                <el-button slot="append" icon="el-icon-search" @click="get_data"></el-button>
                            </el-input>
                        </template>
                        <template v-slot="scope">
                            <el-button icon="el-icon-edit" class="table-btn" size="small"
                                @click="handleEdit(scope.$index, scope.row)">
                                编辑</el-button>
                            <el-button icon="el-icon-delete" class="table-btn" size="small" type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-row class="line-up-row">
                    <el-pagination @current-change="paging" :current-page="currentpage" :page-size="4"
                        @size-change="handleSizeChange" layout="total,sizes, prev, pager, next" :total="totalRecord"
                        :page-sizes="[10, 20, 50]">
                    </el-pagination>
                </el-row>
            </div>

        </div>

        <!-- 用户表单 -->
        <el-dialog title="用户资料" :visible.sync="dialogFormVisible" :destroy-on-close="true"
            :close-on-click-modal="false">
            <el-form :model="form" size="big" label-position="left">
                <el-form-item label="用户姓名" :label-width="formLabelWidth" prop="realName">
                    <el-input v-model="form.realName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="登录用户名" :label-width="formLabelWidth" prop="username">
                    <el-input v-model="form.username" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
                    <el-input v-model="form.password" autocomplete="off" show-password></el-input>
                </el-form-item>


                <el-form-item label="用户类型" :label-width="formLabelWidth" prop="adminType">
                    <el-select v-model="form.adminType">
                        <el-option label="超级管理员" value="super_admin"></el-option>
                        <el-option label="普通用户" value="admin"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="save">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import * as api from "../../api/api"
export default {
    name: "user_list",
    data() {
        return {
            currt_index: 1,
            tableData: [],
            search: "",
            dialogFormVisible: false,
            editting: false,
            editId: 0,
            totalRecord: 0,
            currentpage: 1,
            pagesize: 10,
            form: {
                id: "",
                realName: "",
                username: "",
                password: "",
                adminType: "",
            },
            edit_password: "",
            form_editing: {},
            formLabelWidth: "120px",
        };
    },
    created: async function () {
        this.get_data();
    },
    methods: {
        get_data() {
            var params = {
                keywords: this.search,
                topage: this.currentpage,
                pagesize: this.pagesize
            }
            api.getRequest('/admins',
                params).then(resp => {
                    console.log(resp)
                    this.tableData = resp.data.records
                    this.totalRecord = resp.data.total
                    this.total
                })
        },
        handleEdit(index, row) {
            console.log(index, row);
            this.form_editing = JSON.stringify(row);
            this.form = JSON.parse(this.form_editing);
            this.dialogFormVisible = true;
            this.editting = true;
            this.editId = index;
            this.edit_password = row.password
        },
        handleNew() {
            this.editting = false;
            this.editId = 0;
            this.dialogFormVisible = true;
            console.log(this.editId + "--" + this.editting);
        },
        handleCancel() {
            this.dialogFormVisible = false;
            this.form = this.form = {
                id: "",
                realName: "",
                username: "",
                password: "",
                adminType: "",
            };
        },
        handleDelete(index, row) {
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/admins/' + row.id,
                    {}).then(resp => {
                        console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }
                        let x = this.tableData.length
                        if (x == 1) {
                            let y = this.currentpage
                            this.currentpage = y - 1
                        }
                        this.get_data()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        paging(val) {
            console.log(val)
            this.currentpage = val;
            this.get_data();
        },
        handleSizeChange(val) {
            this.pagesize = val
            this.get_data();
            console.log(`每页 ${val} 条`);
        },
        empty_key() {
            if (this.search == "") {
                this.get_data()
            }
        },
        save() {



            if (this.editting == false) {
                this.form.password = this.$md5(this.form.password)
                api.postRequest('/admins', this.form).then(resp => {
                    console.log(resp)
                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.get_data();
                        this.handleCancel();
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }
            if (this.editting == true) {
                if (this.form.password != this.edit_password) {
                    this.form.password = this.$md5(this.form.password)
                }
                console.log(this.form)
                api.putRequest('/admins', this.form).then(resp => {
                    console.log(resp)
                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.get_data();
                        this.handleCancel();
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }

        },
    }
}
</script>