<template>
  <div>
    <div class="search-box flex-start align-center" v-if="type != '3'">
      <div> {{ $t('search-txt') }}：</div>
      <el-autocomplete v-model="name" :fetch-suggestions="querySearch" :placeholder="$t('keyWord')"
        :trigger-on-focus="false" @select="handleSelect"></el-autocomplete>
    </div>

    <el-autocomplete v-model="name" :fetch-suggestions="querySearch" :placeholder="$t('keyWord')" v-if="type == '3'"
      :trigger-on-focus="false" @select="handleSelect" style="font-size: 12px;width: 100%;"></el-autocomplete>
  </div>
</template>

<script>
import bus from "@/bus";
import * as api from "@/api/api"
export default {

  props: {
    type: String
  },

  data() {
    return {
      name: ''
    }
  },

  methods: {
    // 选择
    async handleSelect(e) {
      console.log(e)
      this.$emit('selCom', e)
    },

    // 搜索
    async querySearch(queryString, cb) {

      var params = {
        keywords: queryString,
        pagesize: 100,
        applyStatus: "datas"
      }
      api.getRequest('/company/v2',
        params).then(resp => {
          // console.log(resp.data)
          let res = resp.data.records.map(d => ({ ...d, value: d.companyName[0] }));
          let results = queryString ? res : '';
          console.log(results)
          // 调用 callback 返回建议列表的数据
          cb(results);

        })

    },
  }
}
</script>

<style lang="less" scoped>
.search-box {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EBEEF5;
}

.label {
  font-size: 18px;
}

.el-autocomplete {
  width: 250px;
}
</style>
