<template>
  <div>

    <el-row :gutter="config.gutter || 20">
      <el-col v-for="(val, i) in config.list" :key="i" :span="val.span || 8">



        <div class="box">
          <div class="form-title"> <span v-if="val.required" class="must">*</span> {{ val.title }}:</div>

          <div v-if="val.type == 'input'">
            <el-input v-model="val.value" :placeholder="$t('company.basic-t1') + val.title"></el-input>
          </div>

          <div v-if="val.type == 'textarea'">
            <el-input type="textarea" v-model="val.value" :rows="4"
              :placeholder="$t('company.basic-t1') + val.title"></el-input>
          </div>

          <div v-if="val.type == 'select'">
            <el-select v-model="val.value" :placeholder="$t('company.basic-t1') + val.title"
              @change="show_rs(val.key, val.value)" allow-create>
              <el-option v-for="(v, idx) in val.options" :key="idx" :label="v.title" :value="v.id">
              </el-option>
            </el-select>
          </div>

          <div v-if="val.type == 'checkbox'">

            <el-checkbox-group v-model="val.value">
              <el-checkbox v-for="(v, idx) in val.options" :key="idx" :label="v.title" :value="v.id"></el-checkbox>
            </el-checkbox-group>

          </div>
        </div>

      </el-col>
    </el-row>

  </div>
</template>

<script>
import * as api from "@/api/api"
export default {
  props: {
    config: Object,
    lang: String,
    show1: Boolean
  },

  watch: {
    'config': {
      immediate: true,
      handler(newVal) {
        this.config = newVal
      },
      deep: true
    },
    'show1': {
      immediate: true,
      handler(newVal) {
        console.log("new" + newVal)
        this.show1 = newVal
      },
      deep: true
    }
  },
  created() {


  },
  data() {
    return {
      item: {},
      box_value: [],
      // show1: true
    }
  },
  methods: {
    show_rs(val, val1) {
      console.log(val, val1)
      this.$emit('sel_value', { val: val, val1: val1 });
      // if (val == "agency" && val1 == 1) {
      //   console.log("a")
      //   this.show1 == false
      // } else {
      //   console.log("b")
      //   this.show1 == true
      // }
      // console.log(this.show1)
      // this.$emit('box_value', val);

    },
  }
}
</script>

<style  scoped>
.box {
  padding-bottom: 10px;
}

.form-title {
  font-size: 16px;
  padding: 10px 0;
}

.must {
  color: red;
}

.el-input,
.el-select {
  width: 100%;
}
</style>
