<template>
  <div>
    <el-form ref="form" :model="task" label-width="120px" :rules="rules">
      <el-form-item label="任务名称" prop="title">
        <el-input v-model="task.title" v-show="!cantEdit"></el-input>
        <span v-show="cantEdit">  {{task.title}}</span>
      </el-form-item>
      <el-form-item label="截止日期时间" prop="endDate">
        <el-col :span="11">
          <el-date-picker type="date" placeholder="选择日期" v-model="task.endDate" value-format="yyyy-MM-dd"
            style="width: 100%;"  v-show="!cantEdit"></el-date-picker>
          <span v-show="cantEdit">  {{task.endDate+"--"+task.endTime}}</span>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="11">
          <el-time-picker placeholder="选择时间" v-model="task.endTime" style="width: 100%;" value-format="hh:mm"
                          v-show="!cantEdit"></el-time-picker>
        </el-col>
      </el-form-item>
      <el-form-item label="相關單位" prop="companyName">
        <el-input v-model="task.companyName" v-show="!editing"></el-input>
        <span v-show="editing">  {{task.companyName}}</span>

        <search :type="2" @selectStaff="selectStaff" v-show="!editing"></search>
      </el-form-item>
      <el-form-item label="項目負責人" prop="assignTo">
        <admin_select @selectAdmin="selectAdmin" :cantEdit="cantEdit" :select_admin="task.assignTo"></admin_select>
      </el-form-item>
      <el-form-item label="項目類別" prop="taskType">
        <task_type_select @selectType="selectType" :selectTypes="task.taskType"></task_type_select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="task.remark"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">{{ btnTxt }}</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import * as api from "@/api/api";
import admin_select from "@/components/system/admin_select.vue";
import search from "@/function/hk/compoemts/search.vue";
import task_type_select from "./task_type_select.vue";


export default {
  components: { admin_select, search, task_type_select },
  data() {
    return {
      task: {
        "assignTo": 0,
        "crtBy": "",
        "endDate": "",
        "endTime": "",
        "fatherId": 0,
        "clientId": 0,
        "remark": "",
        "repeatType": "",
        "tags": "",
        "taskStatus": 0,
        "taskType": "",
        "title": "",
        "updateBy": "",
        "companyName":""
      },
      task1: {
        "assignTo": "",
        "crtBy": "",
        "endDate": "",
        "endTime": "",
        "fatherId": 0,
        "clientId": 0,
        "remark": "",
        "repeatType": "",
        "tags": "",
        "taskStatus": 0,
        "taskType": "",
        "title": "",
        "updateBy": "",
        "companyName":""
      },
      checked: true,
      editing: false,
      cantEdit: false,
      btnTxt: "创建任务",
      rules: {
        title: [
          { required: true, message: "任务名称必填", trigger: 'blur' },
          { min: 3, max: 50, message: "任务名称必填", trigger: 'blur' }
        ],

      },
      showName: ""///选择客户显示的名称
    }
  },
  props: {
    taskDetail: Object
  },
  watch: {
    'taskDetail': {
      immediate: true,
      handler(newVal) {
        console.log(newVal)

        if (newVal != null) {
          this.task = newVal
          this.editing = true
          this.btnTxt = "修改任务"
          this.cantEdit = true
          if (this.task.crtBy != sessionStorage.getItem("adminKey")) {
            this.cantEdit = true
          } else {
            this.cantEdit = false
          }
        } else {
          this.task = JSON.parse(JSON.stringify(this.task1))
          this.editing = false
          this.btnTxt = "创建任务"
          this.cantEdit = false
        }
      },
      deep: true
    },
  },
  methods: {

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.onSubmit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onSubmit() {

      if (this.task.assignTo == 0) {
        this.$message.error("请选择任务负责人");
      } else {
        if (this.editing == false) {
          this.task.crtBy = sessionStorage.getItem("adminKey")
          console.log(this.task)
          api.postRequest('/tasks', this.task).then(resp => {
            console.log(resp)

            if (resp.data.count == 1) {
              this.$message.success(resp.data.message);
              this.$emit("saveOk", true)
            } else {
              this.$message.error(resp.data.message);
            }
          })
        } else {
          this.task.updateBy = sessionStorage.getItem("adminKey")
          console.log(this.task)
          api.putRequest('/tasks', this.task).then(resp => {
            console.log(resp)
            if (resp.data.count == 1) {
              this.$message.success(resp.data.message);
              this.$emit("saveOk", true)
            } else {
              this.$message.error(resp.data.message);
            }
          })
        }

      }
    },
    changeStatus() {
      console.log(this.task1)
      if (this.task1.taskStatus == 1) {
        this.task1.taskStatus = 0
      }
      else if (this.task1.taskStatus == 0) {
        this.task1.taskStatus = 1
      }
    },
    onCancel() {
      this.$emit("saveOk", true)
    },
    selectAdmin(val) {
      this.task.assignTo = val
    },
    selectStaff(val) {
      this.task.companyName = val.companyName[0] + "--" + val.companyNameE[0]
      this.task.clientId = val.id

    },
    selectType(val) {
      this.task.taskType = api.return_string(val)
      console.log(val)
    }
  }
}
</script>
<style scoped></style>
