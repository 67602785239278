<template>
  <el-card>
    <div slot="header" class="clearfix">
      <span>{{ show_title }}</span>
    </div>

    <div class="header-btn" v-if="is_edit">
      <el-button type="primary" @click="open(1)">{{ $t("company.add1") }}</el-button>
      <el-button type="primary" @click="open(2)">{{ $t("company.add3") }}</el-button>
    </div>
    <div class="header-btn" v-if="show_appoint == true">
      <el-button type="primary" @click="open(1)">{{ $t("company.add1") }}</el-button>
      <el-button type="primary" @click="open(2)">{{ $t("company.add3") }}</el-button>
    </div>


    <!-- 自然人列表 -->
    <Table :title="foundTitleX" :table-data="found1">

      <template v-slot:type="scope" >
        {{ scope.scope.staffType == 1 ? $t("company.nature") : $t("company.legal") }}
      </template>

      <template v-slot:nameE1="scope" >
        {{ scope.scope.nameE1 || ' ' }} {{ scope.scope.nameE2 || '' }}
      </template>


      <template v-slot:edit="scope" >
        <el-button type="danger" size="small" plain round @click="deleteRow(scope.scope)" v-if="is_edit">{{
            $t("del-btn")
          }}
        </el-button>
        <el-button type="primary" size="small" plain round @click="unappoint_director(scope.scope)"
                   v-if="show_list_btn == true && scope.scope.appointStatus !== 2">{{
            $t("stop-btn")
          }}
        </el-button>
        <span v-if="scope.scope.appointStatus == 2">{{
            $t("dismissed")
          }}</span>
      </template>
    </Table>
    <!-- 法人列表 -->
    <Table :title="foundTitle1X" :table-data="found2">

      <template v-slot:type="scope" >
        {{ scope.scope.staffType == 1 ? $t("company.nature") : $t("company.legal") }}
      </template>


      <template v-slot:edit="scope" >
        <el-button type="danger" size="small" plain round @click="deleteRow(scope.scope)" v-if="is_edit">{{
            $t("del-btn")
          }}
        </el-button>
        <el-button type="primary" size="small" plain round @click="unappoint_director(scope.scope)"
                   v-if="show_list_btn == true && scope.scope.appointStatus !== 2">{{
            $t("stop-btn")
          }}
        </el-button>
        <!-- <el-button class="table-btn" size="small" @click="handleEdit(scope.scope)" plain round>
            {{ $t('edit') }}</el-button> -->
        <span v-if="scope.scope.appointStatus == 2">{{
            $t("dismissed")
          }}</span>
      </template>
    </Table>

    <el-dialog :title="dialog.type == 1 ? $t('company.add1') : dialog.type == 3 ? $t('company.add2') : $t('edit')"
               :visible.sync="dialog.show" append-to-body width="900px" :close-on-click-modal="false"
               :destroy-on-close="true">

      <Search :type="dialog.type"/>

      <!--          自然人表单-->
      <DialogOne ref="foundDialogOne" :package="new_price" v-show="dialog.type == 1" @onCancel="onCancel"
                 :show_share="show_share" @onConfirm='onConfirm1'/>
      <!--法人表单-->
      <DialogTwo ref="foundDialogTwo" :package="new_price" v-show="dialog.type == 2" @onCancel="onCancel"
                 :detail="select_data" :show_share="show_share" @onConfirm='onConfirm'/>

    </el-dialog>


  </el-card>
</template>

<script>
import Table from "@/components/table/table";
import Search from '../search.vue'
import DialogOne from "./dialog-one.vue";
import DialogTwo from "./dialog-two.vue";
import bus from "@/bus";

export default {
  components: {
    Table,
    Search,
    DialogOne,
    DialogTwo,

  },
  props: {
    is_edit: Boolean,
    stack_price_ordinary: Number,
    stack_price_pre: Number,
    show_appoint: Boolean,
    title: Number,
    show_list_btn: Boolean

  },
  watch: {


    'show_appoint': {
      immediate: true,
      handler(newVal) {
        this.show_appoint = newVal

      },
      deep: true
    },
    'show_list_btn': {
      immediate: true,
      handler(newVal) {
        console.log(newVal)
      },
      deep: true
    },
    'title': {
      immediate: true,
      handler(newVal) {
        console.log(newVal)
        if (newVal == 1) {
          this.show_title = "现有董事"
        }
        if (newVal == 2) {
          this.show_title = "新委任董事"
        }
        if (newVal == 0) {
          this.show_title = this.$("company.title2")//"董事成员"
        }

      },
      deep: true
    },


  },
  data() {
    return {
      price: 1,
      price2: 1,
      show_edit: true,
      show_title: "",
      dialog: {
        show: false,
        type: 1, // 1、添加 2、编辑
        item: {},
      },
      show_share: false,
      found1: [], // 自然人
      found2: [], // 法人
      found3: [], // 法人

      appoint_member3: [],
      appoint_member4: [],
      unappoint_member3: [],
      unappoint_member4: [],


      select_data: {},///選擇的公司

      new_price: {},
      ////自然人表格
      foundTitle: [
        {
          id: 1,
          prop: 'type',
          label: '类型',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'nameC',
          label: '中文姓名',
          align: 'center'
        },
        {
          id: 3,
          prop: 'nameE1',
          label: '英文名',
          align: 'center',
          template: true
        },

        {
          id: 4,
          prop: 'region',
          label: '地區',
          align: 'center'
        },
        {
          id: 9,
          prop: 'state',
          label: '鄉村/市鎮',
          align: 'center'
        },
        {
          id: 14,
          prop: 'street',
          label: '街道',
          align: 'center'
        },
        {
          id: 15,
          prop: 'building',
          label: '建築',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: '详细地址',
          align: 'center'
        },

        {
          id: 13,
          prop: 'edit',
          label: '操作',
          align: 'center',
          width: '100px',
          template: true
        },

      ],
      foundTitleX: [],

      foundTitle1X: [],
      foundTitle1: [
        {
          id: 1,
          prop: 'type',
          label: '类型',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'companyName',
          label: this.$t('basic.txt1'),
          align: 'center'
        },
        {
          id: 3,
          prop: 'companyNameE',
          label: this.$t('basic.txt4'),
          align: 'center',

        },

        {
          id: 4,
          prop: 'region',
          label: '地區',
          align: 'center'
        },
        {
          id: 9,
          prop: 'state',
          label: '鄉村/市鎮',
          align: 'center'
        },
        {
          id: 14,
          prop: 'street',
          label: '街道',
          align: 'center'
        },
        {
          id: 15,
          prop: 'building',
          label: '建築',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: '详细地址',
          align: 'center'
        },

        {
          id: 13,
          prop: 'edit',
          label: '操作',
          align: 'center',
          width: '100px',
          template: true
        },
      ],
      foundTitleEn: [
        {
          id: 1,
          prop: 'type',
          label: 'Type',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'nameC',
          label: 'Chinese Name',
          align: 'center'
        },
        {
          id: 3,
          prop: 'nameE1',
          label: 'Englist Name',
          align: 'center',

        },

        {
          id: 4,
          prop: 'area',
          label: 'Area',
          align: 'center'
        },
        {
          id: 9,
          prop: 'town',
          label: 'Country/Town',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: 'Address',
          align: 'center'
        },

        {
          id: 13,
          prop: 'edit',
          label: 'Operation',
          align: 'center',
          width: '100px',
          template: true
        },
      ],
      foundTitle1En: [
        {
          id: 1,
          prop: 'type',
          label: 'Type',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'nameC',
          label: 'Chinese Name',
          align: 'center'
        },
        {
          id: 3,
          prop: 'nameE1',
          label: 'Englist Name',
          align: 'center',

        },

        {
          id: 4,
          prop: 'area',
          label: 'Area',
          align: 'center'
        },
        {
          id: 9,
          prop: 'town',
          label: 'Country/Town',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: 'Address',
          align: 'center'
        },

        {
          id: 13,
          prop: 'edit',
          label: 'Operation',
          align: 'center',
          width: '100px',
          template: true
        },
      ],

    }
  },
  watch: {
    "$i18n.locale"() {

      if (localStorage.getItem("lang") == "en") {
        this.foundTitle1X = this.foundTitle1En
        this.foundTitleX = this.foundTitleEn
        this.show_title = "Board members"
      }
      if (localStorage.getItem("lang") == "cn") {
        this.foundTitle1X = this.foundTitle1
        this.foundTitleX = this.foundTitle
        this.show_title = "董事成员"
      }


    }
  },
  created() {
    if (localStorage.getItem("lang") == "en") {
      this.foundTitle1X = this.foundTitle1En
      this.foundTitleX = this.foundTitleEn
      this.show_title = "Board members"
    }
    if (localStorage.getItem("lang") == "cn") {
      this.foundTitle1X = this.foundTitle1
      this.foundTitleX = this.foundTitle
      this.show_title = "董事成员"
    }
  },
  methods: {


    deleteRow(e) {
      let self = this

      let index = ''
      if (e.staffType == 1) {
        index = self.found1.findIndex(d => {
          return d.id == e.id
        })
      } else {
        index = self.found2.findIndex(d => {
          return d.id == e.id
        })
      }

      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        e.staffType == 1 ? self.found1.splice(index, 1) : self.found2.splice(index, 1);
      }).catch(() => {

      });

    },


    // 打开弹窗
    open(type, item = {}) {

      this.dialog = {
        show: true,
        type: type + ""
      }

    },

    /////停任董事
    unappoint_director(row) {
      row.appointStatus = 2
      console.log(row)
      if (row.staffType == "1") {
        var newMember3 = {
          staffId: row.staffId,
          nameC: row.nameC,
          nameE1: row.nameE1,
          nameE2: row.nameE2,
          selIdNumber: row.selIdNumber,
          selPassport: row.selPassport,
          selAddress:row.selAddress,
        }
        this.unappoint_member3.push(newMember3)
      }
      if (row.staffType == "2") {
        var member4 = {
          "companyAddress": row.companyAddress,
          "companyName": row.companyName,
          "companyNameE": row.companyNameE,
          "staffId": row.staffId
        }
        console.log(member4)
        this.unappoint_member4.push(member4)
      }
      console.log(this.unappoint_member4)


    },
    handleEdit(row) {
      console.log(row)
      this.select_data = row
      this.open(2)
    },
    onCancel() {
      this.dialog.show = false
    },

    //添加自然人董事
    onConfirm1(item) {
      if (this.found1 == undefined) {
        this.found1 = []
      }
      item.appointStatus = 1


      let after = JSON.stringify(item);
      let new_one = true

      let change = JSON.parse(after);
      if (item.staffType == "1") {
        var newMember3 = {
          staffId: change.staffId,
          nameC: change.nameC,
          nameE1: change.nameE1,
          nameE2: change.nameE2,
          selIdNumber: change.selIdNumber,
          selPassport: change.selPassport,
          selAddress:change.selAddress,
        }
        this.appoint_member3.push(newMember3)
      }

      console.log("新委任董事" + this.appoint_member3)
      this.found1.forEach(function (item) {

        if (item.staffId == change.id) {
          new_one = false
        }

      })

      if (new_one == true) {
        this.found1.push(change)
      } else {
        this.$message({
          showClose: true,
          message: '已经存在这个成员,不能重复添加',
          type: 'warning'
        });
      }
      this.onCancel()
    },

    ///增加法人董事
    onConfirm(item) {
      if (this.found2 == undefined) {
        this.found2 = []
      }
      item.appointStatus = 1

      let after = JSON.stringify(item);
      // console.log("dire" + after)
      let change = JSON.parse(after);
      if (item.staffType == "2") {
        var member4 = {
          "companyAddress": item.companyAddress,
          "companyName": item.companyName,
          "companyNameE": item.companyNameE,
          "staffId": item.staffId,
        }
        this.appoint_member4.push(member4)
      }
      let new_one = true
      this.found2.forEach(function (item) {
        if (item.staffId == change.id) {
          new_one = false
        }

      })
      if (new_one == true) {
        this.found2.push(change)
      } else {
        this.$message({
          showClose: true,
          message: '已经存在这个成员,不能重复添加',
          type: 'warning'
        });
      }
      this.onCancel()

    },

    // callBack(val) {
    //     console.log(val)

    // },
    // 单价

  },


}
</script>

<style>
.header-btn {
  padding-bottom: 10px;
}

.el-card {
  margin-bottom: 20px;
}

.el-input,
.el-select,
.el-autocomplete {
  width: 100%;
}

.box {
  padding-bottom: 20px;
}

.form-title {
  font-size: 16px;
  padding-bottom: 10px;
}

.must {
  color: red;
}

.el-input,
.el-select {
  width: 100%;
}

.question {
  color: #409eff;
  cursor: pointer;
}


.box {
  padding-right: 20px;
}

.form-title {
  padding-right: 10px;
  padding-bottom: 0px;
}
</style>
