<template>
    <div>
        <basic ref="basic"></basic>
        <found ref="found" :is_edit="false" @update_price="update_price" />
        <director ref="director" :is_edit="show_btn" />
        <secretary ref="secretary" :is_edit="show_btn" />
        <controller ref="controller" :is_edit="show_btn"></controller>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-bottom: 50px;margin-right: 20px;">
            <el-button @click="handleCancel" v-if="show_btn">{{ $t("cancel") }}</el-button>
            <el-button type="primary" @click="submitForm" v-if="show_btn">{{ $t("save") }}</el-button>

            <el-button type="warning" @click="openConfirm" v-if="show_btn">{{ $t("subAud") }}</el-button>
            <!-- <el-button type="success" @click="audit_submit(3)" v-if="!show_btn">通过审核</el-button>
            <el-button type="warning" @click="audit_submit(1)" v-if="!show_btn">退回修改</el-button> -->
        </div>
        <el-dialog :title="$t('basic.txt18')" :visible.sync="showOpen">
            <el-form>
                <el-form-item :label="$t('basic.txt19')">
                    <el-select v-model="select_company" :label="$t('basic.txt20')">
                        <el-option :label="item" :value="item" v-for="item in company_detail.companyName "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt21')">
                    <el-select v-model="select_companyE" :label="$t('basic.txt20')">
                        <el-option :label="item" :value="item" v-for="item in company_detail.companyNameE "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt22')" v-if="company_detail.agency == 2">
                    <el-select v-model="select_companyAddress" :label="$t('basic.txt20')">
                        <el-option :label="item.region + item.state + item.street + item.building + item.address"
                            :value="item" v-for="item in company_detail.companyAddress "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt23')">
                    <el-select v-model="select_companyEmail" :label="$t('basic.txt20')">
                        <el-option :label="item" :value="item" v-for="item in company_detail.companyEmail "></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showOpen = false">取 消</el-button>
                <el-button type="primary" @click="audit_submit(0)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Table from "@/components/table";
import * as api from "@/api/api"
import basic from "./compoemts/basic_view.vue"
import found from "./compoemts/found/index.vue"
import director from "./compoemts/found/director.vue";
import secretary from "./compoemts/found/secretary.vue"
import controller from "./compoemts/found/controllers.vue"
import submitor_select from "@/function/hk/compoemts/submitor/submitor_select.vue"
export default {
    components: {
        Table,
        basic,
        found,
        director,
        secretary,
        controller,
        submitor_select

    },
    props: {
        c_id: Number,
    },
    watch: {
        'c_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {


                    this.get_data(newVal)
                    console.log("bb" + this.show_btn)
                }

            },
            deep: true
        },
    },
    data() {
        return {
            search: "",
            detail: {},
            editting: false,
            show_btn: false,
            edit_id: 0,
            abc_str: "4567",
            price_package: {
                priceOrdinary: 1,
                pricePre: 1,
                currencyOrdinary: "HKD",
                currencyPre: "HKD",
            },
            company_detail: {},
            member: {
                "area": "",
                "code": "",
                "country": "",
                "email": "",
                "exCName": "",
                "exEName": "",
                "id": 1,
                "idNumber": "",
                "isHkComp": 0,
                "memberType": 0,
                "nameC": "",
                "nameE1": "",
                "nameE2": "",
                "otherCName": "",
                "otherEName": "",
                "passport": "",
                "staffId": 0,
                "staffType": 0
            },
            found1: {},
            showOpen: false,
            select_company: null,
            select_companyE: null,
            select_companyEmail: null,
            select_companyAddress: null,


        }
    },

    created() {
        console.log(sessionStorage.getItem("realName"))
        if (this.c_id) {
            console.log(this.c_id)
            this.get_data(this.c_id)
        }

        if (this.$route.query.id) {
            if (this.$route.query.id > 0) {
                this.get_data(this.$route.query.id)
                this.editting = true
                this.edit_id = this.$route.query.id

            }
            if (this.$route.query.id == 0) {

                this.editting = false
                this.show_btn = true
                console.log("aa" + this.show_btn)
            }
        }


    },

    methods: {

        open(scope) {
            this.dialogConfig = {
                show: true,
                item: { ...scope, hk_account: '', hk_password: '' }
            }
        },

        get_data(id) {

            // let id = this.$route.query.id

            var params = {}
            api.getRequest('/company/v2/' + id,
                params).then(resp => {


                    this.detail = resp.data
                    let item = resp.data


                    if (this.detail.companyAddress != []) {
                        var address = this.detail.companyAddress[0].region + this.detail.companyAddress[0].state + this.detail.companyAddress[0].street + this.detail.companyAddress[0].building + this.detail.companyAddress[0].address
                        this.detail.companyAddress = address
                    }
                    else {
                        this.detail.companyAddress = ""
                    }
                    this.$refs.basic.basiConfig1 = this.detail

                    // // 创办人及高级人员 -- 自然人
                    this.$refs.found.found1 = item.members1
                    // // 创办人及高级人员法人 
                    this.$refs.found.found2 = item.members2

                    // 董事 -- 自然人
                    this.$refs.director.found1 = item.members3
                    // 董事 -- 法人 
                    this.$refs.director.found2 = item.members4


                    // 秘书 --  自然人
                    this.$refs.secretary.found1 = item.members5
                    // 秘书 --  法人
                    this.$refs.secretary.found2 = item.members6


                    ///实际控制人
                    if (item.controller != null) {
                        this.$refs.controller.controller = item.controller
                    }



                    console.log(item)
                    this.company_detail = item
                    if (item.priceOrdinary != "") {
                        this.$refs.found.price = item.priceOrdinary
                    }
                    if (item.currencyOrdinary != "") {
                        this.$refs.found.currencyOrdinary = item.currencyOrdinary
                    }
                    if (item.pricePre != "") {
                        this.$refs.found.price2 = item.pricePre
                    }
                    if (item.currencyPre != "") {
                        this.$refs.found.currencyPre = item.currencyPre
                    }



                })
        },
        submitForm() {

            let id = this.$route.query.id
            console.log(this.$refs.basic.basiConfig1)
            var company_detail = this.$refs.basic.basiConfig1

            // 创办人及高级人员 -- 自然人
            var found_member = this.$refs.found.found1
            // 创办人及高级人员法人 
            var legalPersonFounder = this.$refs.found.found2


            // 董事 -- 自然人
            var director_human = this.$refs.director.found1
            // 董事 -- 法人 
            var director_legal = this.$refs.director.found2

            // 秘书 --  自然人
            var secretary_human = this.$refs.secretary.found1

            // 秘书 --  法人
            var secretary_legal = this.$refs.secretary.found2

            let data = {

                members1: found_member.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 1,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType,
                    "shares": d.shares//api.return_string(d.shares)
                })),
                members2: legalPersonFounder.map(d => ({
                    "area": d.area,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 2,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType,
                    "shares": d.shares// api.return_string(d.shares)
                })),

                members3: director_human.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 3,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),
                members4: director_legal.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 4,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),

                members5: secretary_human.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 5,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),

                members6: secretary_legal.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 6,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),

            }

            // console.log(data)
            // let obj = {

            //     ...basic,
            //     ...price_package,
            //     ...data

            // }
            company_detail.members1 = data.members1
            company_detail.members2 = data.members2
            company_detail.members3 = data.members3
            company_detail.members4 = data.members4
            company_detail.members5 = data.members5
            company_detail.members6 = data.members6
            company_detail.controller = this.$refs.controller.controller
            company_detail.priceOrdinary = this.price_package.priceOrdinary
            company_detail.pricePre = this.price_package.pricePre
            company_detail.currencyOrdinary = this.price_package.currencyOrdinary
            company_detail.currencyPre = this.price_package.currencyPre

            console.log(company_detail)
            if (this.editting == false) {
                api.postRequest('/company/v2', company_detail).then(resp => {
                    // console.log(resp)
                    if (resp.data == 1) {

                        this.$message.success(resp.message);
                        this.$router.replace('/companys?apply=pre')
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            } if (this.editting == true) {
                company_detail.id = this.edit_id
                api.putRequest('/company/v2', company_detail).then(resp => {
                    // console.log(resp)
                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.$router.replace('/companys?apply=pre')
                    } else {
                        this.$message.error(resp.message);
                    }
                })

            }

        },
        update_price(item) {
            console.log(item)
            this.price_package = item

        },
        handleCancel(val) {
            this.$router.back()
        },


        openConfirm() {
            this.showOpen = true
        },


        audit_submit(status) {

            var companyName = []
            var companyNameE = []
            var companyEmail = []
            var companyAddress = []
            if (this.select_company != null) {
                companyName.push(this.select_company)
            }
            if (this.select_companyE != null) {
                companyNameE.push(this.select_companyE)
            }
            if (this.select_companyEmail != null) {
                companyEmail.push(this.select_companyEmail)
            }
            if (this.select_companyAddress != null) {
                companyAddress.push(this.select_companyAddress)
            }


            this.company_detail.companyName = companyName
            this.company_detail.companyNameE = companyNameE
            this.company_detail.companyEmail = companyEmail
            this.company_detail.companyAddress = companyAddress

            console.log(this.company_detail)

            this.$confirm('提交审核公司资料?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.putRequest('/company/v2', this.company_detail).then(resp => {
                    if (resp.data == 1) {
                        api.postRequest('/audit',
                            {
                                "auditBy": "",
                                "auditStatus": status,
                                "auditTime": "",
                                "auditUser": "",
                                "companyId": this.edit_id,
                                "companyName": this.select_company,
                                "companyNameE": this.select_companyE,
                                "formType": "NNC1",
                                "remark": "",
                                "submitBy": sessionStorage.getItem("adminKey"),
                                "submitUser": sessionStorage.getItem("realName"),
                            }).then(resp => {
                                if (resp.data == 1) {
                                    this.$message.success(resp.message)
                                }
                                else {
                                    this.$message.error(resp.message)
                                }

                            })

                    } else {
                        this.$message.error(resp.message);
                    }
                })


            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },




    }
}
</script>