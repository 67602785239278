import axios from "axios"
import { Message } from "element-ui"


// axios.defaults.headers.common['token'] = token;
axios.interceptors.request.use(config => {
    // console.log("前置拦截")
    var token = sessionStorage.getItem("token")
    var Authorization = sessionStorage.getItem("Authorization")
    // console.log(token)
    config.headers.token = token
    config.headers.Authorization = Authorization
    // 可以统一设置请求头
    return config
})
axios.interceptors.response.use(success => {
    // console.log(success.data.data)
    // console.log(success.status)
    if (success.status && success.status == 200) {
        if (success.data.code == 500 || success.data.code == 401 || success.data.code == 403) {
            Message.error({ message: success.data.message })
            return
        }
        if (success.data.message) {
            return success.data
            // Message.success({message:success.data.message})
        }
        if (success.data) {
            return success.data
            // Message.success({message:success.data.message})
        }
        else {
            // router.replace('/')
            Message.warning({ message: "登录超时,请重新登录" })

        }
    }

}, error => {
    // console.log(error.status)
    if (error.response.code == 504 || error.response.code == 404) {
        Message.error({ message: "服务器错误，请联系管理员或者供应商。" })
    } else if (error.response.code == 403) {
        Message.warning({ message: "你没有访问权限，请联系管理员。" })
    }
    else if (error.response.code == 401) {
        Message.error({ message: "未登录，请重新登录" })
        // this.$router.replace('/')
    } else {
        // if (error.response.data.message){
        //     Message.error({message:error.response.data.message})
        // }else{
        // router.replace('/')
        Message.warning({ message: "登录超时,请重新登录。" })
        // }
    }
})

let base = ""//'http://localhost:8082/api/admin/'
// let base = "https://apec.microbee.cn/api/admin/"//
let base2 = "http://18.166.55.146/admin/v1/" //url_python
let serverUrl = 'https://apec.microbee.cn/upload_file/'
// console.log(token)
// let headers = { 'token': "909090", 'extAccessToken': "bbb" }

// function check_status() {
//     if (token == "") {

//         this.$message.console.warn('登录超时,请重新登录');
//         this.$router.replace('/')
//         return false
//     }
//     else {
//         return true
//     }

// }

export const postRequest = (url, params) => {

    return axios({
        method: 'post',
        // headers: headers,
        url: `${base}${url}`,
        data: params
    })
}

export const getRequest = (url, params) => {

    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params,
    })
}

export const putRequest = (url, params) => {
    return axios({
        method: 'PUT',
        // headers: headers,
        url: `${base}${url}`,
        data: params,
    })
}

export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        // headers: headers,
        url: `${base}${url}`,
        data: params,
    })
}

export const return_jason = (str) => {
    if (str !== "" && str != "[]") {
        return JSON.parse(str)
    }
    else {
        return []
    }
}


////Python接口api

export const Py_postRequest = (url, params) => {

    return axios({
        method: 'post',
        // headers: headers,
        url: `${base2}${url}`,
        data: params
    })
}

export const Py_getRequest = (url, params) => {

    return axios({
        method: 'get',
        url: `${base2}${url}`,
        params: params,
    })
}


export const Py_putRequest = (url, params) => {
    return axios({
        method: 'PUT',
        // headers: headers,
        url: `${base2}${url}`,
        data: params,
    })
}

export const Py_deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        // headers: headers,
        url: `${base2}${url}`,
        data: params,
    })
}





export const return_string = (json) => {
    if (json !== "" && json != []) {
        return JSON.stringify(json)
    }
    else {
        return ""
    }
}

export const return_pics_string = (json) => {
    let tempPic = JSON.stringify(json);
    let edit_pics = JSON.parse(tempPic)

    edit_pics.forEach(function (item) {
        item.url = item.url.replace(serverUrl, "")
    });
    return JSON.stringify(edit_pics)
}

export const return_pics_json = (json) => {

    json.forEach(function (item) {
        item.url = serverUrl + item.url
    });
    return json
}



export const changeLang = () => {

    this.$i18n.locale = this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
}