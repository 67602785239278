<template>
    <div>

        <!-- 内容列表 -->
        <div class="ibox ">
            <div class="ibox-title">
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="3">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-edit" @click="handleNew">{{ $t('company.add')
                                }}</el-button>
                        </el-button-group>
                    </el-col>
                    <el-col :xs="4" :sm="6" :md="8" :lg="7" :xl="9">
                        <el-input v-model="search" size="medium" :placeholder="$t('keyWord')"
                            @keyup.enter.native="get_data" v-on:input="empty_key" style="width: 260px">

                            <el-button slot="append" icon="el-icon-search" @click="get_data"></el-button>
                        </el-input>


                    </el-col>
                    <el-col :xs="4" :sm="6" :md="8" :lg="7" :xl="11">
                        <el-input v-model="staffName" size="medium" :placeholder="$t('company.search')"
                            @keyup.enter.native="get_data_connect" v-on:input="empty_key_2" style="width: 360px">

                            <el-button slot="append" icon="el-icon-search" @click="get_data_connect"></el-button>
                        </el-input></el-col>
                </el-row>
            </div>

            <div class="ibox-content">
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ color: '#666', fontSize: '14px', backgroundColor: '#f7f8f9', }" size="medium"
                    :empty-text="$t('noRes')">
                    <el-table-column label="id" prop="id" width="80px" sortable> </el-table-column>
                    <el-table-column :label="$t('company.companyName')" prop="companyName" sortable> </el-table-column>
                    <el-table-column :label="$t('company.companyNameE')" prop="companyNameE" sortable>
                    </el-table-column>

                    <el-table-column :label="$t('company.companyCode')" prop="companyCode" sortable> </el-table-column>
                    <el-table-column :label="$t('company.Status')" prop="applyStatus" sortable>
                        <template v-slot="scope">
                            <span class="label label-success" v-if="scope.row.applyStatus === 0">{{ $t('company.status')
                                }}
                            </span>
                            <span class="label label-primary" v-if="scope.row.applyStatus === 1">{{
                                $t('company.status1') }}
                            </span>
                            <span class="label" v-if="scope.row.applyStatus === 2">{{ $t('company.status2') }} </span>
                            <span class="label label-info" v-if="scope.row.applyStatus === 4">{{ $t('company.status3')
                                }}
                            </span>
                            <span class="label label-danger" v-if="scope.row.applyStatus === 3">{{ $t('company.status4')
                                }}
                            </span>

                        </template> </el-table-column>
                    <el-table-column :label="$t('company.remark')" prop="remark" sortable> </el-table-column>
                    <el-table-column :label="$t('company.create')" prop="crtDate" sortable> </el-table-column>
                    <el-table-column align="center" width="280px" :label="$t('action')">
                        <template v-slot="scope">
                            <el-button class="table-btn" size="small" v-if="applyStatus == 'pre'"
                                @click=" handleEdit(scope.$index, scope.row)">
                                {{ $t('edit') }}</el-button>
                            <el-button class="table-btn" size="small" v-if="applyStatus !== 'pre'"
                                @click="handleOpen(1, scope.row)">
                                {{ $t('check') }}</el-button>
                            <el-dropdown v-if="applyStatus === 'registered'">
                                <el-button type="primary" size="small">
                                    {{ $t('company.forms') }}<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown" size="small">
                                    <el-dropdown-item @click.native="handleOpen(2, scope.row)">{{ $t('company.form1')
                                        }}</el-dropdown-item>
                                    <el-dropdown-item @click.native="handleOpen(6, scope.row)">{{ $t('company.form2')
                                        }}</el-dropdown-item>
                                    <el-dropdown-item @click.native="handleOpen(3, scope.row)">{{ $t('company.form3')
                                        }}</el-dropdown-item>
                                    <el-dropdown-item @click.native="handleOpen(4, scope.row)">{{ $t('company.form4')
                                        }}</el-dropdown-item>
                                    <el-dropdown-item @click.native="handleOpen(5, scope.row)">{{ $t('company.form5')
                                        }}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-button icon="el-icon-delete" class="table-btn" size="small" type="danger"
                                v-if="applyStatus == 'pre'" @click="handleDelete(scope.$index, scope.row)"> {{ $t('del')
                                }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-row class="line-up-row">
                    {{ $t('pagination.total') }} {{ totalRecord }} {{ $t('pagination.items') }}
                    <el-select v-model="pagesize" style="width: 120px" @change="handleSizeChange" size="small">
                        <el-option :label="10 + $t('pagination.pageSize')" value="10"></el-option>
                        <el-option :label="20 + $t('pagination.pageSize')" value="20"></el-option>
                        <el-option :label="50 + $t('pagination.pageSize')" value="50"></el-option>

                    </el-select>
                    <el-pagination @current-change="paging" :current-page="currentpage" :page-size="pagesize"
                        :total-text="$t('pagination.total')" @size-change="handleSizeChange" layout=" prev, pager, next"
                        :total="totalRecord" :prev-text="$t('pagination.prev')" :next-text="$t('pagination.next')"
                        :page-sizes="[10, 20, 50]">
                    </el-pagination>
                </el-row>
            </div>

        </div>



        <el-dialog title="关联注册易账户" :visible.sync="dialogConfig.show" :destroy-on-close="true"
            :close-on-click-modal="false" width="500px">
            <div>
                <el-form ref="form" :model="dialogConfig.item" label-width="110px">
                    <el-form-item label="注册易账户">
                        <el-input v-model="dialogConfig.item.hk_account" placeholder="请输入注册易账户"></el-input>
                    </el-form-item>
                    <el-form-item label="注册易账户密码">
                        <el-input type='password' v-model="dialogConfig.item.hk_password"
                            placeholder="请输入注册易账户密码"></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">

                <el-button @click="dialogConfig.show = false">取 消</el-button>
                <el-button type="primary" @click="subminForm">确 定</el-button>
            </span>
        </el-dialog>

        <el-drawer title="公司详细资料" :visible.sync="drawer" size="80%" :wrapper-closable="false" :destroy-on-close="true">
            <company_detail :c_id="c_id" v-if="show_form == 1"></company_detail>
            <nd2 :c_id="c_id" v-if="show_form == 2"></nd2>
            <nd2b :c_id="c_id" v-if="show_form == 6"></nd2b>
            <nr1 v-if="show_form == 3" :c_id="c_id" @Cancel="handleCancel"></nr1>
            <nd4 v-if="show_form == 4" :c_id="c_id" @Cancel="handleCancel"></nd4>
            <nsc1 :c_id="c_id" v-if="show_form == 5"></nsc1>
        </el-drawer>
    </div>
</template>

<script>
import Table from "@/components/table";
import company_detail from "./company_detail_view.vue";
import nd2 from "./compoemts/audition/nd2.vue";
import nr1 from "./compoemts/audition/nr1.vue";
import nd4 from "./compoemts/audition/nd4.vue";
import nsc1 from "./compoemts/audition/nsc1.vue";
import nd2b from "./compoemts/audition/nd2b.vue";
import * as api from "@/api/api"
export default {
    components: {
        Table,
        company_detail,
        nd2, nr1, nd4, nsc1, nd2b
    },

    data() {
        return {

            search: "",
            staffName: "",
            totalRecord: 0,
            currentpage: 1,
            pagesize: 10,
            tableData: [],
            dialogConfig: {
                show: false,
                item: {}
            },
            applyStatus: 0,
            drawer: false,
            c_id: 0,
            show_form: 1,
            company_info: []
        }
    },
    watch: {
        $route(now, old) {
            // console.log(old)
            if (old.name != "companys") {
                if (this.staffName != "") {
                    this.get_data_connect()
                } else {
                    this.get_data()
                }

            }
        }

    },
    created() {
        // console.log(this.staffName)
        // console.log(this.$route.query.act)

        if (this.$route.query.apply == 'pre') {
            this.applyStatus = "pre"
        }
        if (this.$route.query.apply == 'submitted') {
            this.applyStatus = "submitted"
        }
        if (this.$route.query.apply == 'registered') {
            this.applyStatus = 'registered'
        }

        this.get_data()



    },

    methods: {

        open(scope) {
            this.dialogConfig = {
                show: true,
                item: { ...scope, hk_account: '', hk_password: '' }
            }
        },

        // 关联注册易账户
        async subminForm() {
            let data = {
                hk_account: this.dialogConfig.item.hk_account,
                hk_password: this.dialogConfig.item.hk_password,
                id: this.dialogConfig.item.id,
            }
            const res = await this.$server.companyManage.postHkAccount(data)
            if (res.code == 200) {
                this.$message.success('绑定成功')
            } else {
                this.$message.error(res.desc)
            }
        },



        get_data() {
            var params = {
                keywords: this.search,
                topage: this.currentpage,
                pagesize: this.pagesize,
                applyStatus: this.applyStatus
            }
            api.getRequest('/company/v2',
                params).then(resp => {
                    // console.log(resp)
                    this.tableData = resp.data.records
                    this.totalRecord = resp.data.total
                })
        },
        get_data_connect() {
            var params = {
                name: this.staffName,
                topage: this.currentpage,
                pagesize: this.pagesize,
                applyStatus: 4
            }
            api.getRequest('/company/connect',
                params).then(resp => {
                    // console.log(resp)
                    this.tableData = resp.data.records
                    this.totalRecord = resp.data.total
                })
        },
        handleEdit(index, row) {
            this.$router.push(`/companysDetail?id=` + row.id)
        },
        handleOpen(type, row) {

            this.show_form = type
            this.drawer = true
            this.c_id = row.id
            this.company_info = row
        },
        handleNew(val) {
            this.$router.push(`/companysDetail?id=0`)
        },

        handleDelete(index, row) {
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/company/' + row.id,
                    {}).then(resp => {
                        // console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }
                        let x = this.tableData.length
                        if (x == 1) {
                            let y = this.currentpage
                            this.currentpage = y - 1
                        }
                        this.get_data()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        paging(val) {
            // console.log(val)
            this.currentpage = val;
            this.get_data();
        },
        handleSizeChange(val) {
            this.pagesize = val
            this.get_data();
            // console.log(`每页 ${val} 条`);
        },
        empty_key() {
            if (this.search == "") {
                this.get_data()
            }

        },
        empty_key_2() {

            if (this.staffName == "") {
                this.get_data()
            }
        },
        handleCancel() {
            this.drawer = false
        },


    }
}
</script>

<style scoped>
.el-select {
    width: 100%;
}
</style>
