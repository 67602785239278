<template>
  <el-col :span="24">
    <div class="division-line">
      {{ title }}
    </div>
  </el-col>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    title: String
  }
}
</script>


<style lang="less" scoped>
.division-line {
  padding: 10px 15px;
  // border-bottom: 1px solid #EBEEF5;
  background: #EBEEF5;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
</style>
