<template>
    <div>

        <found ref="found" :is_edit="show_btn" :price_package="price_package" @update_price="update_price" />

        <div slot="footer" class="dialog-footer" style="text-align: right;margin-bottom: 50px;margin-right: 20px;">
            <el-button @click="handleCancel" v-if="show_btn">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="submitForm" v-if="show_btn">{{ $t('save') }}</el-button>

            <el-button type="warning" @click="audit_submit(0)" v-if="show_btn">{{ $t('subAud') }}</el-button>
            <!-- <el-button type="success" @click="audit_submit(3)" v-if="!show_btn">通过审核</el-button>
            <el-button type="warning" @click="audit_submit(1)" v-if="!show_btn">退回修改</el-button> -->
        </div>
    </div>
</template>
<script>
import Table from "@/components/table";
import * as api from "@/api/api"

import found from "../found/index.vue"

export default {
    components: {
        Table,

        found,


    },
    props: {
        c_id: Number
    },
    watch: {
        'c_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {
                    this.show_btn = true
                    this.get_data(newVal)
                }

            },
            deep: true
        },
    },
    data() {
        return {
            search: "",
            detail: {},
            editting: false,
            show_btn: true,
            edit_id: 0,
            price_package: {
                priceOrdinary: 1,
                pricePre: 1,
                currencyOrdinary: "HKD",
                currencyPre: "HKD",
            },
            company_detail: {},
            member: {
                "area": "",
                "code": "",
                "country": "",
                "email": "",
                "exCName": "",
                "exEName": "",
                "id": 1,
                "idNumber": "",
                "isHkComp": 0,
                "memberType": 0,
                "nameC": "",
                "nameE1": "",
                "nameE2": "",
                "otherCName": "",
                "otherEName": "",
                "passport": "",
                "staffId": 0,
                "staffType": 0
            },
            found1: {}

        }
    },

    created() {
        console.log(sessionStorage.getItem("realName"))
        if (this.c_id) {
            console.log(this.c_id)
            this.get_data(this.c_id)
        }

        if (this.$route.query.id) {
            if (this.$route.query.id > 0) {
                this.get_data(this.$route.query.id)
                this.editting = true
                this.edit_id = this.$route.query.id

            }
            if (this.$route.query.id == 0) {
                this.editting = false
            }
        }

    },

    methods: {

        open(scope) {
            this.dialogConfig = {
                show: true,
                item: { ...scope, hk_account: '', hk_password: '' }
            }
        },

        get_data(id) {

            // let id = this.$route.query.id

            var params = {}
            api.getRequest('/company/' + id,
                params).then(resp => {

                    this.detail = resp.data
                    let item = resp.data

                    this.$refs.basic.basiConfig.list.map(d => {
                        d.value = item[d.key]
                    })
                    this.$refs.basic.stockConfig.list.map(d => {
                        d.value = item[d.key]
                    })


                    this.company_detail = item
                    ////设置股价初始值
                    if (item.currencyOrdinary != ""
                        && item.currencyPre != ""
                        && item.priceOrdinary != ""
                        && item.pricePre != "") {
                        this.price_package = {
                            priceOrdinary: item.priceOrdinary,
                            pricePre: item.pricePre,
                            currencyOrdinary: item.currencyOrdinary,
                            currencyPre: item.currencyPre,
                        }
                    }


                    // console.log(item)
                    item.members.forEach(function (item) {
                        item.shares = api.return_jason(item.shares)
                    })
                    item.members1.forEach(function (item) {
                        item.shares = api.return_jason(item.shares)
                    })
                    // // 创办人及高级人员 -- 自然人
                    this.$refs.found.found1 = item.members
                    // // 创办人及高级人员法人 
                    this.$refs.found.found2 = item.members1

                    // 董事 -- 自然人
                    this.$refs.director.found1 = item.members3
                    // 董事 -- 法人 
                    this.$refs.director.found2 = item.members4


                    // 秘书 --  自然人
                    this.$refs.secretary.found1 = item.members5
                    // 秘书 --  法人
                    this.$refs.secretary.found2 = item.members6

                })
        },
        submitForm() {

            let id = this.$route.query.id
            const basic = { id: id }
            const price_package = {
                priceOrdinary: this.price_package.priceOrdinary,
                pricePre: this.price_package.pricePre,
                currencyOrdinary: this.price_package.currencyOrdinary,
                currencyPre: this.price_package.currencyPre,
            }
            this.$refs.basic.basiConfig.list.map(d => {
                basic[d.key] = d.value
            })
            this.$refs.basic.stockConfig.list.map(d => {
                basic[d.key] = d.value
            })
            // 创办人及高级人员 -- 自然人
            var found_member = this.$refs.found.found1
            // 创办人及高级人员法人 
            var legalPersonFounder = this.$refs.found.found2


            // 董事 -- 自然人
            var director_human = this.$refs.director.found1
            // 董事 -- 法人 
            var director_legal = this.$refs.director.found2

            // 秘书 --  自然人
            var secretary_human = this.$refs.secretary.found1

            // 秘书 --  法人
            var secretary_legal = this.$refs.secretary.found2
            let all_members = {}



            let data = {

                members: found_member.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 1,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType,
                    "shares": api.return_string(d.shares)
                })),
                members1: legalPersonFounder.map(d => ({
                    "area": d.area,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 2,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType,
                    "shares": api.return_string(d.shares)
                })),

                members3: director_human.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 3,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),
                members4: director_legal.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 4,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),

                members5: secretary_human.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 5,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),

                members6: secretary_legal.map(d => ({

                    "area": d.area,
                    "town": d.town,
                    "address": d.address,
                    "code": d.code,
                    "companyId": id,
                    "country": d.country,
                    "email": "",
                    "exCName": d.exCName,
                    "exEName": d.exEName,
                    "idNumber": d.idNumber,
                    "isHkComp": d.isHkComp,
                    "memberType": 6,
                    "nameC": d.nameC,
                    "nameE1": d.nameE1,
                    "nameE2": d.nameE2,
                    "otherCName": d.otherCName,
                    "otherEName": d.otherEName,
                    "passport": d.passport,
                    "staffId": d.staffId,
                    "staffType": d.staffType
                })),

            }

            // console.log(data)
            let obj = {

                ...basic,
                ...price_package,
                ...data

            }
            console.log(obj)
            if (this.editting == false) {
                // api.postRequest('/company', obj).then(resp => {
                //     // console.log(resp)
                //     if (resp.data == 1) {

                //         this.$message.success(resp.message);
                //         this.$router.replace('/companys?apply=pre')
                //     } else {
                //         this.$message.error(resp.message);
                //     }
                // })
            } if (this.editting == true) {
                obj.id = this.edit_id
                // api.putRequest('/company', obj).then(resp => {
                //     // console.log(resp)
                //     if (resp.data == 1) {
                //         this.$message.success(resp.message);
                //         this.$router.replace('/companys?apply=pre')
                //     } else {
                //         this.$message.error(resp.message);
                //     }
                // })

            }

        },
        update_price(item) {
            console.log(item)
            this.price_package = item

        },
        handleCancel(val) {
            this.$router.back()
        },


        audit_submit(status) {
            this.$confirm('提交审核公司资料?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.postRequest('/audit',
                    {
                        "auditBy": "",
                        "auditStatus": status,
                        "auditTime": "",
                        "auditUser": "",
                        "companyId": this.edit_id,
                        "companyName": this.company_detail.companyName,
                        "companyNameE": this.company_detail.companyNameE,
                        "formType": "NSC1",
                        "remark": "",
                        "submitBy": sessionStorage.getItem("adminKey"),
                        "submitUser": sessionStorage.getItem("realName"),
                    }).then(resp => {
                        // console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }

                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },




    }
}
</script>