<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>辭任董事</span>
      </div>
      <table class="table invoice-table">
        <thead>
          <tr>
            <th>名稱</th>
            <th>證件</th>
            <th>類型</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in director1">
            <td>
              <div><strong>{{ item.nameC }}</strong></div>
              <small>{{ item.nameE1 }}</small>
            </td>
            <td>{{ item.selPassport.passport }}{{ item.selIdNumber.idNumber }}</td>
            <td>(自然人)</td>
          </tr>
          <tr v-for="item, index in director2" :key="index">
            <td>
              <div><strong>{{ item.companyName }}</strong></div>
              <small>{{ item.companyNameE }}</small>
            </td>
            <td></td>
            <td>(法人)</td>
          </tr>


        </tbody>
      </table>
    </el-card>

    <el-card>
      <div slot="header" class="clearfix">
        <span>新委任董事</span>
      </div>
      <table class="table invoice-table">
        <thead>
          <tr>
            <th>名稱</th>
            <th>證件</th>
            <th>類型</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item, index in newDirector1" :key="index">
            <td>
              <div><strong>{{ item.nameC }}</strong></div>
              <small>{{ item.nameE1 }}</small>
            </td>
            <td>{{ item.selPassport.passport }}{{ item.selIdNumber.idNumber }}</td>
            <td>(自然人)</td>
          </tr>
          <tr v-for="item, index in newDirector2" :key="index">
            <td>
              <div><strong>{{ item.companyName }}</strong></div>
              <small>{{ item.companyNameE }}</small>
            </td>
            <td></td>
            <td>(法人)</td>
          </tr>


        </tbody>
      </table>
    </el-card>


    <el-card>
      <div slot="header" class="clearfix">
        <span>辭任秘書</span>
      </div>
      <table class="table invoice-table">
        <thead>
          <tr>
            <th>名稱</th>
            <th>證件</th>
            <th>類型</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item, index in secretary1" :key="index">
            <td>
              <div><strong>{{ item.nameC }}</strong></div>
              <small>{{ item.nameE1 }}</small>
            </td>
            <td>{{ item.selPassport.passport }}{{ item.selIdNumber.idNumber }}</td>
            <td>(自然人)</td>
          </tr>
          <tr v-for="item, index in secretary2" :key="index">
            <td>
              <div><strong>{{ item.companyName }}</strong></div>
              <small>{{ item.companyNameE }}</small>
            </td>
            <td></td>
            <td>(法人)</td>
          </tr>
        </tbody>
      </table>
    </el-card>

    <el-card>
      <div slot="header" class="clearfix">
        <span>新委任秘書</span>
      </div>
      <table class="table invoice-table">
        <thead>
          <tr>
            <th>名稱</th>
            <th>證件</th>
            <th>類型</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item, index in newSecretary1" :key="index">
            <td>
              <div><strong>{{ item.nameC }}</strong></div>
              <small>{{ item.nameE1 }}</small>
            </td>
            <td>{{ item.selPassport.passport }}{{ item.selIdNumber.idNumber }}</td>
            <td>(自然人)</td>
          </tr>
          <tr v-for="item, index in newSecretary2" :key="index">
            <td>
              <div><strong>{{ item.companyName }}</strong></div>
              <small>{{ item.companyNameE }}</small>
            </td>
            <td></td>
            <td>(法人)</td>
          </tr>
        </tbody>
      </table>
    </el-card>

    <!-- <div id="abc" v-for="item in director1">辞任董事:{{ item.nameC }}(自然人)</div>
    <div id="abc" v-for="item in director2">辞任董事:{{ item.companyName }}(法人)</div>
    <div id="abc" v-for="item in newDirector1">---新委任董事:{{ item.nameC }}(自然人)</div>
    <div id="abc" v-for="item in newDirector2">---新委任董事:{{ item.companyName }}(法人)</div>
    =====
    <div id="abc" v-for="item in secretary1">辞任秘书:{{ item.nameC }}(自然人)</div>
    <div id="abc" v-for="item in secretary2">辞任秘书:{{ item.companyName }}(法人)</div>
    <div id="abc" v-for="item in newSecretary1">---新委任秘书:{{ item.nameC }}(自然人)</div>
    <div id="abc" v-for="item in newSecretary2">---新委任秘书:{{ item.companyName }}(法人)</div> -->
  </div>
</template>
<script>
import * as api from "@/api/api";

export default {
  props: {
    c_id: Number,
    audit_id: Number
  }, watch: {
    'c_id': {
      immediate: true,
      handler(newVal) {
        if (newVal != undefined && newVal != "") {
          this.show_btn = true
          this.show_1 = true
          this.get_data(newVal)
          this.edit_id = newVal
        }

      },
      deep: true
    },
    'audit_id': {
      immediate: true,
      handler(newVal) {
        if (newVal != undefined && newVal != "") {

          this.show_btn = false
          this.show_1 = false
          this.get_data_form(newVal)
          this.show_btn = false

          // this.edit_id = newVal
        }

      },
      deep: true
    },
  },
  data() {
    return {
      search: "",
      detail: {},
      editting: false,
      show_btn: true,
      show_appoint: true,
      show_1: true,
      edit_id: 0,

      company_detail: {},

      found1: {},
      director1: null,
      director2: null,
      newDirector1: null,
      newDirector2: null,
      secretary1: null,
      secretary2: null,
      newSecretary1: null,
      newSecretary2: null,

      submitor: {},
      new_submitor: {},

    }
  },
  methods: {
    get_data_form(id) {


      var params = { id: id }
      api.getRequest('/nd2a',
        params).then(resp => {
          console.log(resp)


          // 董事 -- 自然人
          this.director1 = resp.data.members3
          console.log(JSON.stringify(this.director1))
          // 董事 -- 法人
          this.director2 = resp.data.members4
          //
          // // 董事 -- 自然人
          this.newDirector1 = resp.data.members3New
          // // 董事 -- 法人
          this.newDirector2 = resp.data.members4New


          //秘书辞任自然人
          this.secretary1 = resp.data.members5
          //秘书辞任法人
          this.secretary2 = resp.data.members6

          //秘书委任自然人
          this.newSecretary1 = resp.data.members5New
          //秘书委任法人
          this.newSecretary2 = resp.data.members6New



        })
    },
  }
}
</script>
<style scoped>

</style>
