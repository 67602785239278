<template>
  <div>
    <!-- 内容列表 -->
    <div class="ibox ">
      <div class="ibox-title">
        <el-row :gutter="10">

          <el-col :xs="4" :sm="6" :md="8" :lg="7" :xl="11">
            <el-input v-model="search" size="medium" :placeholder="$t('keyWord')" @keyup.enter.native="get_data"
              v-on:input="empty_key" style="width: 260px">

              <el-button slot="append" icon="el-icon-search" @click="get_data"></el-button>
            </el-input>


          </el-col>

        </el-row>
      </div>

      <div class="ibox-content">
        <el-table :data="tableData" style="width: 100%"
          :header-cell-style="{ color: '#666', fontSize: '14px', backgroundColor: '#f7f8f9', }" size="medium"
          :empty-text="$t('noRes')">
          <el-table-column label="id" prop="id" width="80px" sortable></el-table-column>
          <el-table-column :label="$t('company.companyName')" prop="companyName" sortable></el-table-column>
          <el-table-column :label="$t('company.companyNameE')" prop="companyNameE" sortable>
          </el-table-column>

          <el-table-column :label="$t('company.formType')" prop="formType" sortable></el-table-column>
          <el-table-column :label="$t('company.submitUser')" prop="submitUser" sortable></el-table-column>
          <el-table-column :label="$t('company.auditUser')" prop="auditUser" sortable></el-table-column>
          <el-table-column :label="$t('company.Status')" prop="auditStatus" sortable>
            <template v-slot="scope">
              <!-- 0-待审核  1-审核通过  2-审核不通过 3-政府审核不通过  4-政府审核通过  -->
              <span class="label label-success" v-if="scope.row.auditStatus === 0"> {{ $t('Pending') }}
              </span>
              <span class="label label-primary" v-if="scope.row.auditStatus === 1"> {{
                $t('approveedStatus')
              }}</span>
              <span class="label" v-if="scope.row.auditStatus === 2">{{ $t('notapproveedStatus') }}</span>
              <span class="label label-info" v-if="scope.row.auditStatus === 4">{{
                $t('approveed')
                }}</span>
              <span class="label label-danger" v-if="scope.row.auditStatus === 3">{{
                $t('Notapproveed')
                }}</span>

            </template>
          </el-table-column>
          <el-table-column :label="$t('company.remark')" prop="remark" sortable></el-table-column>
          <el-table-column :label="$t('company.create')" prop="crtDate" sortable></el-table-column>
          <el-table-column align="center" width="680px" :label="$t('action')">
            <template v-slot="scope">

              <el-button class="table-btn" size="small" @click="handleOpen(scope.$index, scope.row)">
                {{ $t('check') }}
              </el-button>
              <el-button class="table-btn" type="success" size="small" @click="audit_submit(1, scope.row)"
                v-if="scope.row.auditStatus == 0">
                {{ $t('apBtn') }}
              </el-button>
              <el-button class="table-btn" type="warning" size="small" @click="audit_submit(2, scope.row)"
                v-if="scope.row.auditStatus == 0">
                {{ $t('NapBtn') }}
              </el-button>
              <el-button class="table-btn" type="primary" size="small" @click="audit_submit(4, scope.row)"
                v-if="scope.row.auditStatus === 1 && scope.row.formType !== 'NNC1'">
                {{ $t('approveed') }}
              </el-button>
              <el-button class="table-btn" type="primary" size="small" @click="NNC1_confirm(4, scope.row)"
                v-if="scope.row.auditStatus === 1 && scope.row.formType == 'NNC1'">
                {{ $t('approveed') }}
              </el-button>

              <el-button class="table-btn" type="danger" size="small" @click="audit_submit(3, scope.row)"
                v-if="scope.row.auditStatus === 1">
                {{ $t('Notapproveed') }}
              </el-button>
              <el-button type="primary" size="small" @click="getPdf(scope.row)"> {{
                $t('expPdf')
                }}
              </el-button>
              <el-button type="info" size="small">{{ $t('expXml') }}</el-button>

            </template>
          </el-table-column>
        </el-table>
        <!-- <el-row class="line-up-row">
            <el-pagination @current-change="paging" :current-page="currentpage" :page-size="4"
                @size-change="handleSizeChange" layout="total,sizes, prev, pager, next" :total="totalRecord"
                :page-sizes="[10, 20, 50]">
            </el-pagination>
        </el-row> -->
        <paging :totalRecord="totalRecord" :currentpage="currentpage" @handleSizeChange="handleSizeChange"
          @paging="paging">
        </paging>
      </div>
    </div>
    <el-dialog :title="$t('audi.txt4')" :visible.sync="confirm_open" width="30%">
      <span>{{ $t("audi.txt1") }}</span>

      <el-input v-model="companyCode" :placeholder="$t('audi.txt2')"></el-input>
      <el-input v-model="certificate" :placeholder="$t('audi.txt3')"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm_open = false">{{ $t("cancel") }}</el-button>
        <el-button type="primary" @click="audit_submit_nnc1">{{ $t("confirm") }}</el-button>
      </span>
    </el-dialog>
    <el-drawer :title="show_title" :visible.sync="drawer" size="90%">
      <company_detail :c_id="c_id" v-if="form_type == 1"></company_detail>


      <nr1 v-if="form_type == 2" :form_id="form_id" :companyName="companyName"></nr1>
      <!--            <nd2 v-if="form_type == 3" :audit_id="form_id"></nd2>-->
      <nd2a_show v-if="form_type == 3" :audit_id="form_id"></nd2a_show>
      <nd4 v-if="form_type == 4" :form_id="form_id"></nd4>


      <el-card>
        <div slot="header" class="clearfix">
          <span>提交人</span>

        </div>
        <el-form ref="form" :model="form" label-width="150px">
          <el-form-item :label="$t('submitor.submitName')">
            <el-input v-model="form.submitName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('submitor.submitAddress')">
            <el-input v-model="form.submitAddress"></el-input>
          </el-form-item>
          <el-form-item :label="$t('submitor.submitPhone')">
            <el-input v-model="form.submitPhone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('submitor.submitFax')">
            <el-input v-model="form.submitFax"></el-input>
          </el-form-item>
          <el-form-item :label="$t('submitor.submitEmail')">
            <el-input v-model="form.submitEmail"></el-input>
          </el-form-item>
          <el-form-item :label="$t('submitor.fileNumber')">
            <el-input v-model="form.fileNumber"></el-input>
          </el-form-item>
          <el-form-item :label="$t('submitor.txt2')" prop="signDate">
            <el-date-picker type="date" :placeholder="$t('submitor.txt3')" v-model="form.signDate"
              value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-form>
      </el-card>
    </el-drawer>
  </div>
</template>
<script>
import Table from "@/components/table";
import company_detail from "./company_detail_view.vue";
import nr1 from "./compoemts/audition/nr1.vue";
import nd2 from "./compoemts/audition/nd2.vue";
import nd4 from "./compoemts/audition/nd4.vue";
import nd2a_show from "@/function/hk/compoemts/audition/nd2a_show.vue";
import paging from "@/components/paging"
import * as api from "@/api/api"
import submitor_select from "@/function/hk/compoemts/submitor/submitor_select.vue"

export default {
  components: {
    Table,
    company_detail,
    nr1, nd2, nd4,
    paging,
    submitor_select,
    nd2a_show
  },

  data() {
    return {

      search: "",
      staffName: "",
      totalRecord: 0,
      currentpage: 1,
      pagesize: 10,
      tableData: [],
      applyStatus: 0,
      drawer: false,
      c_id: 0,
      form_type: 1,
      show_title: "公司详细资料",
      down_path: "",
      confirm_open: false,
      companyCode: "",
      certificate: "",
      temp_status: "",
      temp_row: [],
      show_btn: false,
      submitor: {},
      form: {},
      companyName: ""
    }
  },
  watch: {
    $route(now, old) {
      // console.log(old)
      if (old.name != "companys") {
        if (this.staffName != "") {
          this.get_data_connect()
        } else {
          this.get_data()
        }

      }
    }

  },
  created() {
    this.get_data()

  },
  mounted() {
    // console.log(process.env.VUE_APP_PDF_URL); // 输出对应环境的API URL
    this.down_path = process.env.VUE_APP_PDF_URL
    console.log(this.down_path)
  },

  methods: {

    get_data() {
      var params = {
        keywords: this.search,
        topage: this.currentpage,
        pagesize: this.pagesize,
        applyStatus: this.applyStatus
      }
      api.getRequest('/audit',
        params).then(resp => {
          // console.log(resp)
          this.tableData = resp.data.records
          this.totalRecord = resp.data.total
        })
    },


    handleOpen(index, row) {

      this.drawer = true
      this.form = row.submitor
      this.companyName = row.companyName
      if (row.formType == "NNC1") {

        this.form_type = 1
        this.c_id = row.companyId


      }
      if (row.formType == "NR1") {
        this.show_title = "NR1表格详情"
        this.form_type = 2
        this.form_id = row.formId
      }
      if (row.formType == "ND2A") {
        this.show_title = "ND2A表格详情"
        this.form_type = 3
        this.form_id = row.formId
      }
      if (row.formType == "ND4") {
        console.log(row)
        this.show_title = "ND4表格详情"
        this.form_type = 4
        this.form_id = row.id
      }

    },
    NNC1_confirm(status, row) {
      this.confirm_open = true
      this.temp_row = row
      this.temp_status = status

    },
    audit_submit_nnc1() {
      let row = this.temp_row
      row.auditStatus = this.temp_status
      console.log(this.temp_status)
      row.auditUser = sessionStorage.getItem("realName")
      row.auditBy = sessionStorage.getItem("adminKey")
      if (row.formType == "NNC1") {
        row.companyCode = this.companyCode
        row.certificate = this.certificate
      }
      console.log(row)
      api.putRequest('/audit',
        row).then(resp => {
          console.log(resp)
          if (resp.data == 1) {
            this.$message.success(resp.message)
          } else {
            this.$message.error(resp.message)
          }

        })
    },
    audit_submit(status, row) {
      this.$confirm('确认改变审核状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {


        row.auditStatus = status
        row.auditUser = sessionStorage.getItem("realName")
        row.auditBy = sessionStorage.getItem("adminKey")
        if (row.formType == "NNC1") {
          row.companyCode = this.companyCode
          row.certificate = this.certificate
        }
        console.log(row)
        api.putRequest('/audit',
          row).then(resp => {
            console.log(resp)
            if (resp.data == 1) {
              this.$message.success(resp.message)
            } else {
              this.$message.error(resp.message)
            }

          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消提交'
        });
      });
    },

    paging(val) {
      // console.log(val)
      this.currentpage = val;
      this.get_data();
    },
    handleSizeChange(val) {
      this.pagesize = val
      this.get_data();
      // console.log(`每页 ${val} 条`);
    },
    empty_key() {
      if (this.search == "") {
        this.get_data()
      }

    },
    empty_key_2() {

      if (this.staffName == "") {
        this.get_data()
      }
    },
    /////下载pdf 文件
    async getPdf(row) {

      // this.search = params
      console.log(row)

      if (row.formType == "NR1") {
        window.location.href = this.down_path + "/NR1/" + row.formId + "/" + row.id
      }
      if (row.formType == "NNC1") {
        window.location.href = this.down_path + "/NNC1/" + row.companyId + "/" + row.id
      }
      if (row.formType == "ND2A") {
        window.location.href = this.down_path + "/ND2A/" + row.formId + "/" + row.id
      }


    },
    selectSubmit(val) {
      this.submitor = val
      this.submitor.submitType = 2
      console.log(JSON.stringify(this.submitor))

    },

  }
}
</script>
