import Vue from "vue";
import VueRouter from "vue-router";
import VueI18n from 'vue-i18n'



import login_page from "../views/login_page"
import empty_page from "../views/empty_page"
import menu_manage from "../function/system/menus"
import user_list from "../function/system/user_list"
import senior_staffs from "@/function/hk/senior_staffs"
import company_list from "@/function/hk/company_list"
import company_detail from "@/function/hk/company_detail"
import audtit_list from "@/function/hk/audit_list"
import submitor_list from "@/function/hk/submitor_list"
import my_company from "@/function/hk/my_company"
import task_list from "@/function/common/task_list.vue";
const originalPush = VueRouter.prototype.replace

VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      title: {
        en: 'Login',
        cn: '登录'
      },
      keepAlive: true
    },
    component: login_page,
  },
  {
    path: "/empty",
    name: "empty",
    meta: {
      title: '标准页'
    },
    component: empty_page,
    children: [






      {
        path: '/myCompany',
        name: 'my_company',
        component: my_company,
        meta: {
          title: {
            en: 'Company information',
            cn: '我的公司设置'
          },
          keepAlive: true
        }
      },
      {
        path: '/menus',
        name: 'menus',
        component: menu_manage,
        meta: {
          title: {
            en: 'Menu management',
            cn: '后台菜单管理'
          },
          keepAlive: false
        }
      },
      {
        path: '/userlist',
        name: 'userlist',
        component: user_list,
        meta: {
          title: {
            en: 'Admin management',
            cn: '管理员管理'
          },
          keepAlive: false
        }
      },
      {
        path: '/tasks',
        name: 'userlist',
        component: task_list,
        meta: {
          title: {
            en: 'Admin management',
            cn: '管理员管理'
          },
          keepAlive: false
        }
      },

      {
        path: '/seniorStaffs',
        name: 'seniorStaffs',
        component: senior_staffs,
        meta: {
          title: {
            en: 'Corporate/Individual',
            cn: '法人自然人管理'
          },
          keepAlive: false
        }
      },
      {
        path: '/companys',
        name: 'companys',
        component: company_list,
        meta: {
          title: {
            en: 'Application submitted for company incorporation',
            cn: '已提交公司'
          },
          keepAlive: true
        }
      },
      {
        path: '/registCompanys',
        name: 'companys',
        component: company_list,
        meta: {
          title: {
            en: 'Registered company / form prepared',
            cn: '已注册公司'
          },
          keepAlive: true
        }
      },
      {
        path: '/submitor',
        name: 'submitor',
        component: submitor_list,
        meta: {
          title: {
            en: 'Presentor’s Reference',
            cn: '提交人管理'
          },
          keepAlive: true
        }
      },


      {
        path: '/preCompanys',
        name: 'preCompanys',
        component: company_list,
        meta: {
          title: {
            en: 'Preparation for Company Formation',
            cn: '预提交公司'
          },
          keepAlive: true
        }
      },
      {
        path: '/audtit',
        name: 'audtit',
        component: audtit_list,
        meta: {
          title: {
            en: 'Form approval',
            cn: '表单审核'
          },
          keepAlive: true
        }
      },


      {
        path: '/companysDetail',
        name: 'companyDetail',
        component: company_detail,
        meta: {
          title: {
            en: 'Company Detail',
            cn: '公司详情'
          },
          keepAlive: false
        }
      },






    ]
  }
]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    const lang = localStorage.getItem("lang") || 'cn'
    VueI18n.locale = lang
    document.title = to.meta.title[VueI18n.locale];
  }
  next();
})



export default router
