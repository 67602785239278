<template>
    <div class="gray-bg-v">
        <div class="loginColumns animated fadeInDown">
            <div class="row">

                <div class="col-md-6">
                    <h2 class="font-bold">{{ company_name }}{{ $t('loginTxt') }}</h2>

                    <p>{{ $t('loginTxt1') }}
                    </p>
                    <!-- <p>—— micrbobee</p> -->
                    <!-- {{ $t('greeting') }} -->
                </div>
                <div class="col-md-6">
                    <div class="ibox-content">
                        <el-form :model="ruleForm" :rules="ruleValidate" ref="ruleForm">
                            <div class="form-group">
                                <el-form-item prop="username">
                                    <el-input v-model="ruleForm.username" :placeholder="$t('user')"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form-group">
                                <el-form-item prop="password">
                                    <el-input type="password" v-model="ruleForm.password" :placeholder="$t('pass')"
                                        @keyup.enter.native="submitForm('ruleForm')"></el-input>
                                </el-form-item>
                            </div>
                            <el-button class="btn btn-primary block full-width m-b" type="primary"
                                @click="submitForm('ruleForm')">{{ $t('loginBtn') }}</el-button>


                        </el-form>
                        <!-- <a href="#">
                            <small>忘记密码?</small>
                        </a> -->

                        <!-- <p class="text-muted text-center">
                            <small>还没有账号?</small>
                        </p>
                        <a class="btn btn-sm btn-white btn-block" href="register.html">创建一个帐户</a>

                        <p class="m-t">
                        </p> -->
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <small>Copyright Gain Rich Management Limited &copy; 2017-2022</small>
                </div>
                <div class="col-md-6 text-right">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as api from "../api/api"
export default {
    name: 'login_page',
    mounted() {
        document.getElementsByTagName('body')[0].className = 'gray-bg'
        console.log(localStorage.getItem("lang"))

    },
    created() {
        if (!localStorage.getItem("lang")) {
            localStorage.setItem("lang", "cn")
        }
        else {
            this.$i18n.locale = localStorage.getItem("lang")
        }
        this.get_data()
    },
    unmounted() {
        document.body.removeAttribute('class')
    },
    data() {
        return {
            ruleForm: {
                username: "",
                password: ""
            },
            rules: {
                username: [
                    { required: true, message: this.$t("login.tip1"), trigger: 'blur' },
                    { min: 3, max: 20, message: this.$t("login.tip2"), trigger: 'blur' }
                ],
                password: [
                    { required: true, message: this.$t("login.tip3"), trigger: 'blur' },
                    { min: 6, max: 20, message: this.$t("login.tip4"), trigger: 'blur' }
                ]
            },
            company_name: "德力管理有限公司",
            ruleValidate: {
                username: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (!value) {
                                return callback(new Error(this.$t("login.tip1")));
                            } else if (!/^\w{3,20}$/.test(value)) {
                                return callback(new Error(this.$t('login.tip2')));
                            } else {
                                callback();
                            }
                        }
                    }
                ],
                password: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (!value) {
                                return callback(new Error(this.$t("login.tip3")));
                            } else if (!/^\w{6,20}$/.test(value)) {
                                return callback(new Error(this.$t('login.tip4')));
                            } else {
                                callback();
                            }
                        }
                    }
                ]
            }

        }
    },
    watch: {
        "$i18n.locale"() {

            this.$refs['ruleForm'].fields.forEach(item => {
                if (item.validateState === 'error') {
                    this.$refs['ruleForm'].validateField(item.prop)
                }
            })
        }
    },
    methods: {
        get_data() {
            var params = {
                id: 1,
            }
            api.getRequest('/myCompany',
                params).then(resp => {
                    console.log(resp.data.companyName)
                    this.company_name = resp.data.companyName
                    sessionStorage.setItem("myCompany", this.company_name)

                })
        },
        click_menu(topage) {
            this.$router.replace(topage);
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.login_action();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        login_action() {

            var params = {
                username: this.ruleForm.username,
                password: this.$md5(this.ruleForm.password)
            }

            api.getRequest('/login',
                params).then(resp => {

                    if (resp) {
                        if (resp.data.isUser == 1 && resp.data.isLogged == 0) {
                            this.$message.error('密码错误');
                        }
                        if (resp.data.isUser == 0 && resp.data.isLogged == 0) {
                            this.$message.error('用户名不存在');
                        }
                        if (resp.data.isUser == 1 && resp.data.isLogged == 1) {
                            this.$message.success('登录成功');
                            sessionStorage.setItem("token", resp.data.token)
                            sessionStorage.setItem("adminKey", resp.data.adminKey)

                            sessionStorage.setItem("realName", resp.data.realName)
                            sessionStorage.setItem("adminType", resp.data.adminType)


                            this.$router.replace('/seniorStaffs')


                            this.resetForm("ruleForm")

                        }
                    }



                })
        },
        SendEail() {

            Email.send({
                Host: "mail.timexcorp.com",  //邮箱地址
                Username: "comsec@timexcorp.com",
                Password: "987654321#!Abc3444",//授权码
                To: "36618653@qq.com",//接收邮件的地址
                From: "comsec@timexcorp.com",//发送邮箱的地址
                Subject: "test",//邮件主题
                Body: "xxxxxxxxxxxxxxx"  //邮件内容
            }).then(
                console.log("ff")

            );
        },
    }
}
</script>
<style >
.el-input__inner {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 0px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
}

.el-input__inner:placeholder {
    color: #000;
}
</style>