<template>
    <el-card>
        <div slot="header" class="clearfix">
            <!-- <span>公司秘书成员</span> -->
            <span>{{ show_title }}</span>
        </div>

        <div class="header-btn" v-if="is_edit">
            <el-button type="primary" @click="open(1)">{{ $t("company.add1") }}</el-button>
            <el-button type="primary" @click="open(2)">{{ $t("company.add3") }}</el-button>
            <el-button type="primary" @click="add_default">默认秘书</el-button>
        </div>
        <div class="header-btn" v-if="show_appoint == true">
            <el-button type="primary" @click="open(1)">{{ $t("company.add1") }}</el-button>
            <el-button type="primary" @click="open(2)">{{ $t("company.add3") }}</el-button>
        </div>



        <!-- 自然人列表 -->
        <Table :title="foundTitleX" :table-data="found1">

            <template v-slot:type="scope">
                {{ scope.scope.staffType == 1 ? $t("company.nature") : $t("company.legal") }}
            </template>

            <template v-slot:nameE1="scope">
                {{ scope.scope.nameE1 || '' }} {{ scope.scope.nameE2 || '' }}
            </template>


            <template v-slot:edit="scope">
                <el-button type="danger" size="small" plain round @click="deleteRow(scope.scope)"
                    v-if="is_edit">删除</el-button>
                <el-button type="primary" size="small" plain round @click="unappoint_director(scope.scope)"
                    v-if="show_list_btn == true && scope.scope.appointStatus !== 2">{{
                        $t("stop-btn") }}</el-button>
                <span v-if="scope.scope.appointStatus == 2">已设置停任</span>
            </template>
        </Table>
        <!-- 法人列表 -->
        <Table :title="foundTitle1X" :table-data="found2">

            <template v-slot:type="scope">
                {{ scope.scope.staffType == 1 ? $t("company.nature") : $t("company.legal") }}
            </template>


            <template v-slot:edit="scope">
                <el-button type="danger" size="small" plain round @click="deleteRow(scope.scope)"
                    v-if="is_edit">删除</el-button>
                <el-button type="primary" size="small" plain round @click="unappoint_director(scope.scope)"
                    v-if="show_list_btn == true && scope.scope.appointStatus !== 2">停任</el-button>
                <span v-if="scope.scope.appointStatus == 2">已设置停任</span>
            </template>
        </Table>

        <el-dialog :title="dialog.type == 1 ? '添加自然人' : dialog.type == 3 ? '添加法人' : '编辑'" :visible.sync="dialog.show"
            width="900px" :close-on-click-modal="false" :destroy-on-close="true" append-to-body>

            <Search :type="dialog.type" @item="callBack" />

            <DialogOne ref="foundDialogOne" :package="new_price" v-show="dialog.type == 1" @onCancel="onCancel"
                :show_share="show_share" @onConfirm='onConfirm1' />

            <DialogTwo ref="foundDialogTwo" :package="new_price" v-show="dialog.type == 2" @onCancel="onCancel"
                :show_share="show_share" @onConfirm='onConfirm' />

        </el-dialog>


    </el-card>
</template>

<script>
import Table from "@/components/table/table";
import Search from '../search.vue'
import DialogOne from "./dialog-one.vue";
import DialogTwo from "./dialog-two.vue";

export default {
    components: {
        Table,
        Search,
        DialogOne,
        DialogTwo
    },
    props: {
        is_edit: Boolean,
        stack_price_ordinary: Number,
        stack_price_pre: Number,
        show_appoint: Boolean,
        title: Number,
        show_list_btn: Boolean

    },
    watch: {

        'show_list_btn': {
            immediate: true,
            handler(newVal) {
                console.log(newVal)
            },
            deep: true
        },

        'show_appoint': {
            immediate: true,
            handler(newVal) {
                this.show_appoint = newVal

            },
            deep: true
        },
        'title': {
            immediate: true,
            handler(newVal) {
                console.log(newVal)
                if (newVal == 1) {
                    this.show_title = "现有公司秘书"
                }
                if (newVal == 2) {
                    this.show_title = "新委公司秘书"
                }
                if (newVal == 0) {
                    this.show_title = "公司秘书成员"
                }

            },
            deep: true
        },


    },
    data() {
        return {
            price: 1,
            price2: 1,
            show_edit: true,
            dialog: {
                show: false,
                type: 1, // 1、添加 2、编辑
                item: {},
            },
            show_share: false,
            found1: [], // 自然人
            found2: [], // 法人
            found3: [], // 法人
            show_title: "现有公司秘书",
            director_appoint: [],///新委任秘书
            director_unappoint: [],///辞任秘书
            appoint_member5: [],
            appoint_member6: [],
            unappoint_member5: [],
            unappoint_member6: [],
            new_price: {},
            foundTitleX: [],
            foundTitle1X: [],
            ////自然人表格
            foundTitle: [
                {
                    id: 1,
                    prop: 'type',
                    label: '类型',
                    align: 'center',
                    template: true
                },
                {
                    id: 2,
                    prop: 'nameC',
                    label: '中文姓名',
                    align: 'center'
                },
                {
                    id: 3,
                    prop: 'nameE1',
                    label: '英文名',
                    align: 'center',
                    template: true
                },
                {
                    id: 4,
                    prop: 'exCName',
                    label: '前用中文名',
                    align: 'center',

                },
                {
                    id: 5,
                    prop: 'exEName',
                    label: '前用英文名',
                    align: 'center',

                },
                {
                    id: 6,
                    prop: 'otherCName',
                    label: '中文别名',
                    align: 'center',

                },
                {
                    id: 7,
                    prop: 'otherEName',
                    label: '英文别名',
                    align: 'center',

                },

                {
                    id: 8,
                    prop: 'region',
                    label: '地區',
                    align: 'center'
                },
                {
                    id: 9,
                    prop: 'state',
                    label: '區',
                    align: 'center'
                },
                {
                    id: 14,
                    prop: 'street',
                    label: '街道',
                    align: 'center'
                },
                {
                    id: 15,
                    prop: 'building',
                    label: '建築',
                    align: 'center'
                },
                {
                    id: 10,
                    prop: 'address',
                    label: '詳細地址',
                    align: 'center'
                },
                {
                    id: 11,
                    prop: 'selPassport.passport',
                    label: '护照号码',
                    align: 'center'
                },
                {
                    id: 12,
                    prop: 'selIdNumber.idNumber',
                    label: '身份证号码',
                    align: 'center'
                },

                {
                    id: 13,
                    prop: 'edit',
                    label: '操作',
                    align: 'center',
                    width: '100px',
                    template: true
                },

            ],
            foundTitleEn: [
                {
                    id: 1,
                    prop: 'type',
                    label: 'Type',
                    align: 'center',
                    template: true
                },
                {
                    id: 2,
                    prop: 'nameC',
                    label: 'Chinese Name',
                    align: 'center'
                },
                {
                    id: 3,
                    prop: 'nameE1',
                    label: 'Englist Name',
                    align: 'center',
                    template: true
                },
                {
                    id: 4,
                    prop: 'exCName',
                    label: 'Previous Names Chinese',
                    align: 'center',

                },
                {
                    id: 5,
                    prop: 'exEName',
                    label: 'Previous Names English',
                    align: 'center',

                },
                {
                    id: 6,
                    prop: 'otherCName',
                    label: 'Alias Chinese',
                    align: 'center',

                },
                {
                    id: 7,
                    prop: 'otherEName',
                    label: 'Alias English',
                    align: 'center',

                },

                {
                    id: 4,
                    prop: 'region',
                    label: '地區',
                    align: 'center'
                },
                {
                    id: 9,
                    prop: 'state',
                    label: '區',
                    align: 'center'
                },
                {
                    id: 14,
                    prop: 'street',
                    label: '街道',
                    align: 'center'
                },
                {
                    id: 15,
                    prop: 'building',
                    label: '建築',
                    align: 'center'
                },
                {
                    id: 10,
                    prop: 'address',
                    label: '详细地址',
                    align: 'center'
                },
                {
                    id: 11,
                    prop: 'passport',
                    label: 'Passport',
                    align: 'center'
                },
                {
                    id: 12,
                    prop: 'idNumber',
                    label: ' Identity Card Number',
                    align: 'center'
                },

                {
                    id: 13,
                    prop: 'edit',
                    label: 'Operation',
                    align: 'center',
                    width: '100px',
                    template: true
                },

            ],
            // 法人秘书列表
            foundTitle1: [
                {
                    id: 1,
                    prop: 'type',
                    label: '类型',
                    align: 'center',
                    template: true
                },
                {
                    id: 2,
                    prop: 'companyName',
                    label: this.$t('basic.txt1'),
                    align: 'center'
                },
                {
                    id: 3,
                    prop: 'companyNameE',
                    label: this.$t('basic.txt4'),
                    align: 'center',

                },

                {
                    id: 4,
                    prop: 'region',
                    label: '地區',
                    align: 'center'
                },
                {
                    id: 9,
                    prop: 'state',
                    label: '區',
                    align: 'center'
                },
                {
                    id: 14,
                    prop: 'street',
                    label: '街道',
                    align: 'center'
                },
                {
                    id: 15,
                    prop: 'building',
                    label: '建築',
                    align: 'center'
                },
                {
                    id: 10,
                    prop: 'address',
                    label: '详细地址',
                    align: 'center'
                },

                {
                    id: 13,
                    prop: 'edit',
                    label: '操作',
                    align: 'center',
                    width: '100px',
                    template: true
                },
            ],
            foundTitle1En: [
                {
                    id: 1,
                    prop: 'type',
                    label: 'Type',
                    align: 'center',
                    template: true
                },
                {
                    id: 2,
                    prop: 'nameC',
                    label: 'Chinese Name',
                    align: 'center'
                },
                {
                    id: 3,
                    prop: 'nameE1',
                    label: 'Englist Name',
                    align: 'center',

                },

                {
                    id: 4,
                    prop: 'area',
                    label: 'Area',
                    align: 'center'
                },
                {
                    id: 9,
                    prop: 'town',
                    label: 'Country/Town',
                    align: 'center'
                },
                {
                    id: 10,
                    prop: 'address',
                    label: 'Address',
                    align: 'center'
                },

                {
                    id: 13,
                    prop: 'edit',
                    label: 'Operation',
                    align: 'center',
                    width: '100px',
                    template: true
                },
            ],
        }
    },
    created() {
        if (localStorage.getItem("lang") == "en") {
            this.foundTitle1X = this.foundTitle1En
            this.foundTitleX = this.foundTitleEn
            this.show_title = "Company secretary"
        }
        if (localStorage.getItem("lang") == "cn") {
            this.foundTitle1X = this.foundTitle1
            this.foundTitleX = this.foundTitle
            this.show_title = "公司秘书成员"
        }

    },
    watch: {
        "$i18n.locale"() {

            if (localStorage.getItem("lang") == "en") {
                this.foundTitle1X = this.foundTitle1En
                this.foundTitleX = this.foundTitleEn
                this.show_title = "Company secretary"
            }
            if (localStorage.getItem("lang") == "cn") {
                this.foundTitle1X = this.foundTitle1
                this.foundTitleX = this.foundTitle
                this.show_title = "公司秘书成员"
            }


        }
    },
    methods: {

        currencyChange(type) {
            // console.log(type)
            let currency2 = this.stack_currency_ordinary
            if (type == "preference") {
                currency2 = this.stack_currency_pre
            }
            this.found1.forEach(function (item) {
                item.stack.forEach(function (i) {
                    if (i.class_s == type) {
                        i.currency = currency2
                    }
                })
            })
            this.found2.forEach(function (item) {
                item.stack.forEach(function (i) {
                    if (i.class_s == type) {
                        i.currency = currency2
                    }
                })
            })
            this.onBlur()

        },

        deleteRow(e) {
            let self = this

            let index = ''
            if (e.staffType == 1) {
                index = self.found1.findIndex(d => { return d.id == e.id })
            } else {
                index = self.found2.findIndex(d => { return d.id == e.id })
            }

            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                e.staffType == 1 ? self.found1.splice(index, 1) : self.found2.splice(index, 1);
            }).catch(() => {

            });

        },



        // 打开弹窗
        open(type, item = {}) {
            if (this.found1 == undefined) {
                this.found1 = []
            }
            if (this.found2 == undefined) {
                this.found2 = []
            }
            let total = this.found1.length + this.found2.length
            console.log(total)

            if (total >= 1) {
                this.$message({
                    showClose: true,
                    message: '只能选择一个秘书',
                    type: 'warning'
                });
            } else {
                this.dialog = {
                    show: true,
                    type: type + ""
                }
            }





        },

        onCancel() {
            this.dialog.show = false
        },
        onConfirm1(item) {
            console.log(item)
            item.appointStatus = 1
            if (this.found1 == undefined) {
                this.found1 = []
            }

            let after = JSON.stringify(item);
            let new_one = true

            let change = JSON.parse(after);
            if (item.staffType == "1") {
                this.appoint_member5.push(change)
            }
            console.log("新委任秘书" + this.director_appoint)
            this.found1.forEach(function (item) {

                if (item.staffId == change.id) {
                    new_one = false
                }

            })

            if (new_one == true) {
                this.found1.push(change)
            }
            else {
                this.$message({
                    showClose: true,
                    message: '已经存在这个成员,不能重复添加',
                    type: 'warning'
                });
            }
            this.onCancel()
        },


        ///添加默認秘書公司
        add_default() {
            if (this.found2.length + this.found1.length >= 1) {
                this.$message.error("只能添加一個秘書")
            } else {
                // this.found2.push({ "area": "香港", "code": "1234", "town": "九龍", "email": "", "nameC": "德力管理有限公司", "nameE1": "Gain Rich Management Limited", "nameE2": "", "address": "觀塘駿業街 43-45 號創匯廣場7樓06室", "exCName": "", "exEName": "", "staffId": 0, "isHkComp": 1, "companyId": "0", "staffType": 2, "memberType": 6, "otherCName": "", "otherEName": "" })
                this.found2.push({ "region": "Hong Kong", "street": "駿業街", "state": "觀塘", "building": "號創匯廣場", "address": "7樓06室", "companyName": "德力管理有限公司", "companyNameE": "Gain Rich Management Limited", "companyAddress": " 43-45 號創匯廣場7樓06室", "staffId": 0, "staffType": 2 })
            }
        },
        onConfirm(item) {
            console.log(item)
            if (this.found2 == undefined) {
                this.found2 = []
            }
            item.appointStatus = 1

            let after = JSON.stringify(item);
            let change = JSON.parse(after);
            if (item.staffType == "2") {
                var member6 = {
                    "companyAddress": item.companyAddress,
                    "companyName": item.companyName,
                    "companyNameE": item.companyNameE,
                    "staffId": item.staffId,
                    "staffType": item.staffType,
                    "certificate": item.certificate
                }
                console.log("选择公司:" + member6)
                this.appoint_member6.push(member6)
            }
            let new_one = true
            this.found2.forEach(function (item) {
                if (item.staffId == change.id) {
                    new_one = false
                }

            })
            if (new_one == true) {
                this.found2.push(change)
            }
            else {
                this.$message({
                    showClose: true,
                    message: '已经存在这个成员,不能重复添加',
                    type: 'warning'
                });
            }
            this.onCancel()

        },

        callBack(val) {
            console.log(val)

        },
        /////停任秘书
        unappoint_director(row) {
            console.log(row)
            row.appointStatus = 2

            if (row.staffType == "1") {
                this.unappoint_member5.push(row)
            }
            if (row.staffType == "2") {
                var member6 = {
                    "companyAddress": row.companyAddress,
                    "companyName": row.companyName,
                    "companyNameE": row.companyNameE,
                    "staffId": row.staffId,
                    "staffType": row.staffType,
                }
                this.unappoint_member6.push(member6)
            }
            console.log(this.unappoint_member5)


        },

    },



}
</script>

<style>
.header-btn {
    padding-bottom: 10px;
}

.el-card {
    margin-bottom: 20px;
}

.el-input,
.el-select,
.el-autocomplete {
    width: 100%;
}

.box {
    padding-bottom: 20px;
}

.form-title {
    font-size: 16px;
    padding-bottom: 10px;
}

.must {
    color: red;
}

.el-input,
.el-select {
    width: 100%;
}

.question {
    color: #409eff;
    cursor: pointer;
}


.box {
    padding-right: 20px;
}

.form-title {
    padding-right: 10px;
    padding-bottom: 0px;
}
</style>
