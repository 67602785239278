<template>
  <el-card>
    <div slot="header" class="clearfix">
      <span>{{ $t("company.title1") }} </span>
    </div>

    <div class="header-btn" v-if="is_edit">
      <el-button type="primary" @click="open(1)">{{ $t("company.add1") }}</el-button>
      <el-button type="primary" @click="open(2)">{{ $t("company.add3") }}</el-button>
    </div>
    <el-row>
      <el-col :span="10" style="font-size: 16px;" v-if="is_edit">
        {{ $t("company.stock-txt") }}
        <el-select v-model="currencyOrdinary" @change="currencyChange('ordinary')"
          :placeholder="$t('company.stock-txt4')" size="small" style="width: 120px;">
          <el-option label="HKD" value="HKD"></el-option>
          <el-option label="CNY" value="CNY"></el-option>
          <el-option label="USD" value="USD"></el-option>
        </el-select>
        <el-input v-model="priceOrdinary" :placeholder="$t('company.stock-txt4')" style="width: 100px;" size="small" />
        <el-button type="success" @click="onBlur" size="small"> {{ $t("company.stock-txt1") }}</el-button>
      </el-col>
      <el-col :span="10" style="font-size: 16px;" v-if="!is_edit"> {{ $t("company.stock-txt") }}{{ currencyOrdinary }}
        {{ $t("company.stock-txt2") }} {{ priceOrdinary
        }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="10" style="font-size: 16px;" v-if="is_edit">
        {{ $t("company.stock-txt3") }}
        <el-select v-model="currencyPre" @change="currencyChange('preference')" :placeholder="$t('company.stock-txt4')"
          size="small" style="width: 120px;">
          <el-option label="HKD" value="HKD"></el-option>
          <el-option label="CNY" value="CNY"></el-option>
          <el-option label="USD" value="USD"></el-option>
        </el-select>
        <el-input v-model="price2" :placeholder="$t('company.stock-txt5')" style="width: 100px;" size="small" />
        <el-button type="success" @click="onBlur" size="small"> {{ $t("company.stock-txt1") }}</el-button>
      </el-col>
      <el-col :span="10" style="font-size: 16px;" v-if="!is_edit"> {{ $t("company.stock-txt3") }}{{ currencyPre }} {{
        $t("company.stock-txt2") }}
        {{ price2
        }}</el-col>
    </el-row>




    <!-- 自然人列表 -->
    <Table :title="foundTitleX" :table-data="found1">

      <template v-slot:type="scope">

        {{ scope.scope.staffType == 1 ? $t("company.nature") : $t("company.legal") }}
      </template>

      <template v-slot:nameE1="scope">
        {{ scope.scope.nameE1 || '' }} {{ scope.scope.nameE2 || '' }}
      </template>
      <template v-slot:amount2="scope">
        <!-- {{ scope.scope.stack }} -->
        <!-- 修改自然人持股数量 -->
        <el-input v-model="item.amount1" v-for="item in scope.scope.shares" :placeholder="$t('inputTip')"
          v-if="is_edit"></el-input>
        <el-tag v-for="item in scope.scope.shares" type="success" :key="item.index" style="margin-left: 10px;"
          size="medium" effect="dark">
          {{ item.class_s == 'ordinary' ? $t("company.stock1") : $t("company.stock2") }}
          {{ item.amount1 }}</el-tag>

      </template>
      <template v-slot:amount3="scope">
        <!-- 修改自然人已經認購持股数量 -->
        <div v-for="item in scope.scope.shares">
          <el-input v-model="item.amount2" v-for="item in scope.scope.shares" :placeholder="$t('inputTip')"
            v-if="is_edit" @blur="updateAmount(item)">
          </el-input>
        </div>

        <el-tag v-for="item in scope.scope.shares" type="success" :key="item.index" style="margin-left: 10px;"
          size="medium" effect="dark">

          {{ item.class_s == 'ordinary' ? $t("company.stock1") : $t("company.stock2") }}
          {{ item.amount3 }}(已支付{{ item.amount2 }}股)</el-tag>

      </template>
      <template v-slot:edit="scope" v-if="is_edit">
        <el-button type="danger" size="small" plain round @click="deleteRow(scope.scope)">{{ $t("del-btn")
          }}</el-button>

      </template>
    </Table>
    <!-- 法人列表 -->
    <Table :title="foundTitle1X" :table-data="found2">

      <template v-slot:type="scope">
        {{ scope.scope.staffType == 1 ? $t("company.nature") : $t("company.legal") }}
      </template>

      <template v-slot:amount2="scope">
        <!-- 修改法人人持股数量 -->
        <el-input v-model="item.amount1" v-for="item in scope.scope.shares" :placeholder="$t('inputTip')"
          :key="item.id"></el-input>
        <el-tag v-for="item in scope.scope.shares" type="success" :key="item.index" style="margin-left: 10px;"
          size="medium" effect="dark">
          {{ item.class_s == 'ordinary' ? $t("company.stock1") : $t("company.stock2") }}
          {{ item.amount1 }}</el-tag>
        <!-- {{ scope.scope.stack !== [] ? scope.scope.stack[0].amount2 : 0 }} -->

      </template>
      <template v-slot:amount3="scope">
        <!-- 修改法人已經認購持股数量 -->
        <el-input v-model="item.amount2" v-for="item in scope.scope.shares" :placeholder="$t('inputTip')" v-if="is_edit"
          @blur="updateAmount(item)"></el-input>
        <el-tag v-for="item in scope.scope.shares" type="success" :key="item.index" style="margin-left: 10px;"
          size="medium" effect="dark">
          {{ item.class_s == 'ordinary' ? $t("company.stock1") : $t("company.stock2") }}
          {{ item.amount3 }}(已支付{{ item.amount2 }}股)</el-tag>
        <!-- {{ scope.scope.stack[0].amount2 !== null ? scope.scope.stack[0]. : 0 }} -->
      </template>
      <template v-slot:edit="scope" v-if="is_edit">
        <el-button type="danger" size="small" plain round @click="deleteRow(scope.scope)">{{ $t("del-btn")
          }}</el-button>
        <!-- <el-button type="primary" size="small" plain round @click="open(2, scope.scope)">编辑</el-button> -->
      </template>
    </Table>

    <el-dialog :title="dialog.type == 1 ? $t('staff.add2') : $t('staff.add')" :visible.sync="dialog.show"  width="1000px" :close-on-click-modal="false" :destroy-on-close="true" append-to-body>

      <Search :type="openType" />
      <!-- 自然人 -->
      <DialogOne ref="foundDialogOne" :package="new_price" v-show="openType == 1" @onCancel="onCancel"
        :show_share="show_share" @onConfirm='onConfirm1' />
      <!-- 法人 -->
      <DialogTwo ref="foundDialogTwo" :package="new_price" v-show="openType == 2" @onCancel="onCancel"
        :show_share="show_share" @onConfirm='onConfirm' />

    </el-dialog>


  </el-card>
</template>

<script>
import Table from "@/components/table/table";
import Search from '../search.vue'
import DialogOne from "./dialog-one.vue";
import DialogTwo from "./dialog-two.vue";

export default {
  created() {
    if (localStorage.getItem("lang") == "en") {
      this.foundTitle1X = this.foundTitle1En
      this.foundTitleX = this.foundTitleEn
    }
    if (localStorage.getItem("lang") == "cn") {
      this.foundTitle1X = this.foundTitle1
      this.foundTitleX = this.foundTitle
    }
  },
  components: {
    Table,
    Search,
    DialogOne,
    DialogTwo
  },
  props: {
    is_edit: Boolean,
    stack_price_ordinary: Number,
    stack_price_pre: Number,
    price_package: Object,


    // found1: Object
  },
  watch: {



  },
  data() {
    return {
      priceOrdinary: 1,
      price2: 1,
      show_edit: true,
      openType: 1,///打開類型
      dialog: {
        show: false,
        type: 1, // 1、添加 2、编辑
        item: {},
      },
      show_share: true,
      found1: [], // 自然人
      found2: [], // 法人
      found3: [], // 法人

      currency1: 'ordinary',
      currency2: 'HKD',
      currencyOrdinary: 'HKD',
      currencyPre: 'HKD',

      new_price: {},
      ////自然人表格
      foundTitle: [
        {
          id: 1,
          prop: 'type',
          label: '类型',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'nameC',
          label: '中文姓名',
          align: 'center'
        },
        {
          id: 3,
          prop: 'nameE1',
          label: '英文名',
          align: 'center',
          template: true
        },

        {
          id: 4,
          prop: 'region',
          label: '地區',
          align: 'center'
        },
        {
          id: 9,
          prop: 'state',
          label: '區',
          align: 'center'
        },
        {
          id: 14,
          prop: 'street',
          label: '街道',
          align: 'center'
        },
        {
          id: 15,
          prop: 'building',
          label: '建築',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: '详细地址',
          align: 'center'
        },
        {
          id: 11,
          prop: 'amount2',
          label: '創辦成員認購的股份总数',
          align: 'center',
          template: true
        },
        {
          id: 12,
          prop: 'amount3',
          label: '建議發行的股份的將要繳付或視為已繳付的總款額',
          align: 'center',
          width: '150px',
          template: true
        },
        {
          id: 13,
          prop: 'edit',
          label: '操作',
          align: 'center',
          width: '100px',
          template: true
        },

      ],
      foundTitleX: [],

      foundTitle1X: [],
      // 法人表格
      foundTitle1: [
        {
          id: 1,
          prop: 'type',
          label: '类型',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'companyName',
          label: this.$t('basic.txt1'),
          align: 'center'
        },
        {
          id: 3,
          prop: 'companyNameE',
          label: this.$t('basic.txt4'),
          align: 'center',

        },

        {
          id: 4,
          prop: 'region',
          label: '地區',
          align: 'center'
        },
        {
          id: 9,
          prop: 'state',
          label: '鄉村/市鎮',
          align: 'center'
        },
        {
          id: 14,
          prop: 'street',
          label: '街道',
          align: 'center'
        },
        {
          id: 15,
          prop: 'building',
          label: '建築',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: '详细地址',
          align: 'center'
        },
        {
          id: 11,
          prop: 'amount2',
          label: '創辦成員認購的股份总数',
          align: 'center',
          template: true
        },
        {
          id: 12,
          prop: 'amount3',
          label: '建議發行的股份的將要繳付或視為已繳付的總款額',
          align: 'center',
          template: true
        },
        {
          id: 13,
          prop: 'edit',
          label: '操作',
          align: 'center',
          width: '100px',
          template: true
        },
      ],
      foundTitleEn: [
        {
          id: 1,
          prop: 'type',
          label: 'Type',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'nameC',
          label: 'Chinese Name',
          align: 'center'
        },
        {
          id: 3,
          prop: 'nameE1',
          label: 'Englist Name',
          align: 'center',

        },

        {
          id: 4,
          prop: 'area',
          label: 'Area',
          align: 'center'
        },
        {
          id: 9,
          prop: 'town',
          label: 'Country/Town',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: 'Address',
          align: 'center'
        },
        {
          id: 11,
          prop: 'amount2',
          label: 'The total number of shares subscribed by the founding members',
          align: 'center',
          template: true
        },
        {
          id: 12,
          prop: 'amount3',
          label: 'The total amount to be paid or deemed to have been paid for the shares proposed to be issued',
          align: 'center',
          template: true
        },
        {
          id: 13,
          prop: 'edit',
          label: 'Operation',
          align: 'center',
          width: '100px',
          template: true
        },
      ],
      foundTitle1En: [
        {
          id: 1,
          prop: 'type',
          label: 'Type',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'nameC',
          label: 'Chinese Name',
          align: 'center'
        },
        {
          id: 3,
          prop: 'nameE1',
          label: 'Englist Name',
          align: 'center',

        },

        {
          id: 4,
          prop: 'area',
          label: 'Area',
          align: 'center'
        },
        {
          id: 9,
          prop: 'town',
          label: 'Country/Town',
          align: 'center'
        },
        {
          id: 10,
          prop: 'address',
          label: 'Address',
          align: 'center'
        },
        {
          id: 11,
          prop: 'amount2',
          label: 'The total number of shares subscribed by the founding members',
          align: 'center',
          template: true
        },
        {
          id: 12,
          prop: 'amount3',
          label: 'The total amount to be paid or deemed to have been paid for the shares proposed to be issued',
          align: 'center',
          template: true
        },
        {
          id: 13,
          prop: 'edit',
          label: 'Operation',
          align: 'center',
          width: '100px',
          template: true
        },
      ],

    }
  },
  watch: {
    "$i18n.locale"() {

      if (localStorage.getItem("lang") == "en") {
        this.foundTitle1X = this.foundTitle1En
        this.foundTitleX = this.foundTitleEn
      }
      if (localStorage.getItem("lang") == "cn") {
        this.foundTitle1X = this.foundTitle1
        this.foundTitleX = this.foundTitle
      }


    }
  },
  methods: {

    currencyChange(type) {
      // console.log(type)
      let currency2 = this.currencyOrdinary
      if (type == "preference") {
        currency2 = this.currencyPre
      }
      this.found1.forEach(function (item) {
        item.shares.forEach(function (i) {
          if (i.class_s == type) {
            i.currency = currency2
          }
        })
      })
      this.found2.forEach(function (item) {
        item.shares.forEach(function (i) {
          if (i.class_s == type) {
            i.currency = currency2
          }
        })
      })
      console.log(this.found1)
      this.onBlur()

    },

    deleteRow(e) {
      let self = this

      let index = ''
      if (e.staffType == 1) {
        index = self.found1.findIndex(d => { return d.id == e.id })
      } else {
        index = self.found2.findIndex(d => { return d.id == e.id })
      }

      this.$confirm('是否' + this.$t("del-btn") + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        e.staffType == 1 ? self.found1.splice(index, 1) : self.found2.splice(index, 1);
      }).catch(() => {

      });

    },



    // 打开弹窗
    open(type) {
      console.log("up" + type)

      this.openType = type + ""
      this.dialog.show = true

      console.log("this.currency1" + this.dialog)
      this.$nextTick(() => {

        if (type == 1) {
          // this.$refs.foundDialogOne.currency1 = this.currency1
          // this.$refs.foundDialogOne.currency2 = this.currency2
        } else {
          // this.$refs.foundDialogTwo.currency1 = this.currency1
          // this.$refs.foundDialogTwo.currency2 = this.currency2
        }

      })


    },

    onCancel() {
      this.dialog.show = false
    },
    onConfirm1(item) {
      if (this.found1 == undefined) {
        this.found1 = []
      }

      let after = JSON.stringify(item);
      let new_one = true

      let change = JSON.parse(after);

      this.found1.forEach(function (item) {

        if (item.staffId == change.id) {
          new_one = false
        }

      })

      if (new_one == true) {
        this.found1.push(change)
      }
      else {
        this.$message({
          showClose: true,
          message: '已经存在这个成员,不能重复添加',
          type: 'warning'
        });
      }
      this.onCancel()
    },

    onConfirm(item) {
      if (this.found2 == undefined) {
        this.found2 = []
      }

      let after = JSON.stringify(item);
      console.log(after)
      let change = JSON.parse(after);
      let new_one = true
      this.found2.forEach(function (item) {
        if (item.staffId == change.id) {
          new_one = false
        }

      })
      if (new_one == true) {
        this.found2.push(change)
      }
      else {
        this.$message({
          showClose: true,
          message: '已经存在这个成员,不能重复添加',
          type: 'warning'
        });
      }
      this.onCancel()

    },

    callBack(val) {
      console.log(val)

    },
    updateAmount(item) {
      if (item.amount2 > item.amount1) {
        this.$message.info("已支付的股數不能大於總認購股數!")
      }
      else {
        item.amount3 = item.amount2 * this.price
      }

    },
    // 单价數量調整後計算
    onBlur() {


      var priceOrdinary = Number(this.priceOrdinary)

      this.price2 = Number(this.price2)
      let new_stack_price_pre = this.price2

      console.log("before" + JSON.stringify(this.found1))
      this.found1.forEach(function (item) {
        if (item.shares != null && item.shares != {}) {
          item.shares.forEach(function (i) {
            if (i.class_s == 'ordinary') {
              i.amount3 = i.amount2 * priceOrdinary
              i.set_price = priceOrdinary

            } else {
              console.log(i)
              i.amount3 = i.amount2 * new_stack_price_pre
              i.set_price = new_stack_price_pre
            }
          })
        }
      })

      this.found2.forEach(function (item) {
        if (item.shares != null && item.shares != {}) {
          item.shares.forEach(function (i) {
            if (i.class_s == 'ordinary') {
              i.amount3 = i.amount2 * priceOrdinary
              i.set_price = priceOrdinary

            } else {
              console.log(i)
              // i.amount3 = i.amount3 / i.set_price * new_stack_price_pre
              i.amount3 = i.amount2 * new_stack_price_pre
              i.set_price = new_stack_price_pre
            }
          })
        }
      })

      this.new_price = {
        priceOrdinary: this.priceOrdinary,
        pricePre: this.pricePre,
        currencyOrdinary: this.currencyOrdinary,
        currencyPre: this.currencyPre
      }
      this.$emit('update_price', this.new_price)
      this.$message.success('股份单价设定成功');
    },
    // onBlur() {


    //   let stack_price_ordinary = this.new_price.priceOrdinary
    //   let stack_price_pre = this.new_price.pricePre
    //   this.price = Number(this.price)

    //   this.price2 = Number(this.price2)
    //   let new_stack_price_ordinary = this.price
    //   console.log(new_stack_price_ordinary)
    //   console.log(stack_price_ordinary)
    //   let new_stack_price_pre = this.price2
    //   this.found1.forEach(function (item) {
    //     if (item.shares != null && item.shares != {}) {
    //       item.shares.forEach(function (i) {
    //         if (i.class_s == 'ordinary') {
    //           i.set_price = new_stack_price_ordinary
    //           i.amount3 = i.amount3 / stack_price_ordinary * new_stack_price_ordinary
    //         } else {
    //           i.set_price = new_stack_price_pre
    //           i.amount3 = i.amount3 / stack_price_pre * new_stack_price_pre
    //         }
    //       })
    //     }


    //   })
    //   this.found2.forEach(function (item) {
    //     if (item.shares != null && item.shares != {}) {
    //       item.shares.forEach(function (i) {

    //         if (i.class_s == 'ordinary') {
    //           i.set_price = new_stack_price_ordinary
    //           i.amount3 = i.amount3 / stack_price_ordinary * new_stack_price_ordinary
    //         } else {
    //           i.set_price = new_stack_price_pre
    //           i.amount3 = i.amount3 / stack_price_pre * new_stack_price_pre
    //         }
    //       })
    //     }
    //   })
    //   let new_price = {
    //     priceOrdinary: this.price,
    //     pricePre: this.price2,
    //     currencyOrdinary: this.currencyOrdinary,
    //     currencyPre: this.currencyPre
    //   }
    //   this.new_price = new_price

    //   this.$emit('update_price', new_price)
    //   this.$message.success('股份单价设定成功');

    // }
  },


  computed: {
    amount1_set(item) {
      if (item != [])
        return item[0].amount1
      else
        return 0
    }
    // found() {
    //   console.log("自然" + this.found1)
    //   console.log("法人" + this.found2)
    //   let arr = this.found1.concat(this.found2);
    //   console.log(111111, arr)
    //   // if (arr.length) {
    //   //   this.currency1 = arr[0].stack[0].class_s
    //   //   this.currency2 = arr[0].stack[0].currency
    //   // }

    //   return arr
    // }
  }
}
</script>

<style >
.header-btn {
  padding-bottom: 10px;
}

.el-card {
  margin-bottom: 20px;
}

.el-input,
.el-select,
.el-autocomplete {
  width: 100%;
}

.box {
  padding-bottom: 20px;
}

.form-title {
  font-size: 16px;
  padding-bottom: 10px;
}

.must {
  color: red;
}

.el-input,
.el-select {
  width: 100%;
}

.question {
  color: #409eff;
  cursor: pointer;
}


.box {
  padding-right: 20px;
}

.form-title {
  padding-right: 10px;
  padding-bottom: 0px;
}
</style>
