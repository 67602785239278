<template>
    <div>
        <!-- 用户列表 -->
        <div class="ibox ">
            <div class="ibox-title">
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">
                        <el-radio-group v-model="currt_index" @change="get_data">
                            <el-radio-button label="2">法人</el-radio-button>
                            <el-radio-button label="1">自然人</el-radio-button>

                        </el-radio-group>
                    </el-col>
                    <el-col :xs="4" :sm="6" :md="8" :lg="7" :xl="9"> <el-button-group>
                            <!-- 添加法人 -->
                            <el-button type="primary" icon="el-icon-edit" @click="handleNew(2)">{{ $t('staff.add')
                            }}</el-button>
                            <!-- 添加自然人 -->
                            <el-button type="primary" icon="el-icon-edit" @click="handleNew(1)">{{ $t('staff.add2')
                            }}</el-button>
                        </el-button-group></el-col></el-row>

            </div>
            <div class="ibox-content">
                <!-- 自人人列表 -->
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ color: '#666', fontSize: '14px', backgroundColor: '#f7f8f9', }" size="medium"
                    v-if="currt_index == 1" :empty-text="$t('noRes')">
                    <el-table-column label="ID" prop="id" sortable> </el-table-column>
                    <el-table-column :label="$t('staff.nameE1')" prop="nameE1" sortable> </el-table-column>
                    <el-table-column :label="$t('staff.nameE2')" prop="nameE2" sortable> </el-table-column>
                    <el-table-column :label="$t('staff.nameC')" prop="nameC" sortable> </el-table-column>
                    <el-table-column :label="$t('staff.staffType')" prop="staffType" sortable>
                        <template v-slot="scope">
                            <span class="label label-success" v-if="scope.row.staffType === 1">{{ $t('staff.staff')
                            }}</span>
                            <span class="label label-danger" v-if="scope.row.staffType === 2">{{ $t('staff.staff1')
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="right">
                        <template v-slot:header="" >
                            <el-input v-model="search" size="small" :placeholder="$t('keyWord')"
                                @keyup.enter.native="get_data" v-on:input="empty_key">
                                <el-button slot="append" icon="el-icon-search" @click="get_data"></el-button>
                            </el-input>
                        </template>
                        <template v-slot="scope">
                            <el-button icon="el-icon-edit" class="table-btn" size="small" @click="handleEdit(1, scope.row)">
                                {{ $t('edit') }}</el-button>
                            <el-button icon="el-icon-delete" class="table-btn" size="small" type="danger"
                                @click="handleDelete(scope.$index, scope.row)"> {{ $t('del') }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 公司法人列表 -->
                <el-table :data="tableData" style="width: 100%" v-if="currt_index == 2"
                    :header-cell-style="{ color: '#666', fontSize: '14px', backgroundColor: '#f7f8f9', }" size="medium"
                    :empty-text="$t('noRes')">
                    <el-table-column label="ID" prop="id" sortable> </el-table-column>
                    <el-table-column :label="$t('basic.txt4')" prop="companyNameE" sortable> </el-table-column>
                    <el-table-column :label="$t('basic.txt1')" prop="companyName" sortable> </el-table-column>
                    <el-table-column :label="$t('staff.staffType')" prop="staffType" sortable>
                        <!-- 设置y法人标识 -->
                        <template v-slot="scope">
                            <span class="label label-danger">{{ $t('staff.staff1')
                            }}</span>

                        </template>
                    </el-table-column>
                    <el-table-column align="right">
                        <template v-slot:header="" >
                            <el-input v-model="search" size="small" :placeholder="$t('keyWord')"
                                @keyup.enter.native="get_data" v-on:input="empty_key">
                                <el-button slot="append" icon="el-icon-search" @click="get_data"></el-button>
                            </el-input>
                        </template>
                        <template v-slot="scope">
                            <el-button icon="el-icon-edit" class="table-btn" size="small" @click="handleEdit(2, scope.row)">
                                {{ $t('edit') }}</el-button>
                            <el-button icon="el-icon-delete" class="table-btn" size="small" type="danger"
                                @click="handleDelete(scope.$index, scope.row)"> {{ $t('del') }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <paging :totalRecord="totalRecord" :currentpage="currentpage" @handleSizeChange="handleSizeChange"
                    @paging="paging"></paging>
            </div>

        </div>

        <!-- 用户表单 -->
        <el-dialog :title="$t('staff.title')" :visible.sync="dialogFormVisible" :destroy-on-close="true" width="1400px"
            @close="handleCancel" :close-on-click-modal="false">
            <staff_detail @saved="handleCancel" :detail="detail" :editting="editting" :type="staffType"></staff_detail>
        </el-dialog>
    </div>
</template>
<script>
import * as api from "../../api/api"
import { newIndividual } from "@/api/individual"
import { newCorporate } from "@/api/corporate"
import staff_detail from "@/components/hk/staff_detail.vue";
import paging from "@/components/paging"
export default {
    name: "staff_list",
    components: { staff_detail, paging },
    data() {
        return {
            currt_index: 2,
            tableData: [],
            search: "",
            dialogFormVisible: false,
            editting: false,
            editId: 0,
            totalRecord: 0,
            currentpage: 1,
            pagesize: '10',
            form_editing: {},
            formLabelWidth: "120px",
            detail: {},
            staffType: 2
        };
    },
    created: async function () {
        this.get_data();
        // document.getElementsByClassName("el-pagination__total").val = "total";
    },
    methods: {
        get_data() {
            console.log(this.currt_index)
            if (this.currt_index == 1) {
                var params = {
                    keywords: this.search,
                    topage: this.currentpage,
                    pagesize: this.pagesize,
                    type: 1
                }
                api.getRequest('/seniorStaffs',
                    params).then(resp => {
                        console.log(resp)
                        this.tableData = resp.data.records
                        this.totalRecord = resp.data.total

                    })
            } else {
                var params = {
                    keywords: this.search,
                    topage: this.currentpage,
                    pagesize: this.pagesize,
                    applyStatus: "datas"
                }
                api.getRequest('/company/v2',
                    params).then(resp => {
                        // console.log(resp)
                        this.tableData = resp.data.records
                        this.totalRecord = resp.data.total
                    })
            }

        },
        handleEdit(staffType, row) {
            this.staffType = staffType ///打开窗口显示法人或者自然人的内容
            if (staffType == 1) {
                this.form_editing = JSON.stringify(row);
                this.dialogFormVisible = true;
                this.editting = true;
                this.detail = JSON.parse(this.form_editing);
            }
            if (staffType == 2) {
                this.form_editing = JSON.stringify(row);
                this.detail = JSON.parse(this.form_editing);
                this.detail.staffType = 2
                this.detail.companyName = this.detail.companyName[0]
                this.detail.companyNameE = this.detail.companyNameE[0]
                this.detail.companyEmail = this.detail.companyEmail[0]

                this.dialogFormVisible = true;
                this.editting = true;
            }


        },
        handleNew(val) {

            this.editting = false;
            this.staffType = val

          this.dialogFormVisible = true;
            if (val == 1) {
                var individual = newIndividual(0)
                this.detail = individual
            }
            if (val == 2) {
              console.log(this.staffType)
                this.detail = newCorporate(0)
                //  {
                //     staffType: 2,
                //     "addition": "",
                //     "agency": 0,
                //     "applyStatus": 11,
                //     "busModel": 2,
                //     "certificate": "",
                //     "companyAddress": [],
                //     "companyEmail": "",
                //     "companyName": "",
                //     "companyNameE": "",
                //     "companyType": 1,
                //     "controller": [],
                //     files1: [],
                //     files2: [],
                //     files3: [],
                //     "infoType": 0,
                //     "isDelete": 0,
                //     "keeping": 0,
                //     "members1": [],
                //     "members2": [],
                //     "members3": [],
                //     "members4": [],
                //     "members5": [],
                //     "members6": [],
                //     "ordinary": "",
                //     "preference": "",
                //     "priceOrdinary": "1",
                //     "pricePre": "1",
                //     "remark": ""

                // }
            }


        },
        handleCancel(val) {

            this.dialogFormVisible = false;
            this.detail = newIndividual(0)
            if (val == true) {
                this.get_data()
            }

        },
        handleDelete(index, row) {
            this.$confirm(this.$t('confirmDel'), this.$t('hint'), {
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/seniorStaffs/' + row.id,
                    {}).then(resp => {
                        console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }
                        let x = this.tableData.length
                        if (x == 1) {
                            let y = this.currentpage
                            this.currentpage = y - 1
                        }
                        this.get_data()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$t('cancelTxt')
                });
            });
        },
        paging(val) {
            console.log(val)
            this.currentpage = val;
            this.get_data();
        },
        handleSizeChange(val) {
            this.pagesize = val
            this.get_data();
            console.log(`每页 ${val} 条`);
        },
        empty_key() {
            if (this.search == "") {
                this.get_data()
            }
        },

    }
}
</script>
