<template>
    <div>
        <!-- 内容列表 -->
        <div class="ibox ">
            <div class="ibox-title">
                <el-row :gutter="10">
                    <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="4">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-edit" @click="handleOpen">{{ $t('submitor.add')
                                }}</el-button>
                        </el-button-group>
                    </el-col>
                    <el-col :xs="4" :sm="6" :md="8" :lg="7" :xl="11">
                        <el-input v-model="search" size="medium" :placeholder="$t('keyWord')"
                            @keyup.enter.native="get_data" v-on:input="empty_key" style="width: 260px">

                            <el-button slot="append" icon="el-icon-search" @click="get_data"></el-button>
                        </el-input>


                    </el-col>

                </el-row>
            </div>

            <div class="ibox-content">
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ color: '#666', fontSize: '14px', backgroundColor: '#f7f8f9', }" size="medium"
                    :empty-text="$t('noRes')">
                    <el-table-column label="id" prop="id" width="80px" sortable> </el-table-column>
                    <el-table-column :label="$t('submitor.submitName')" prop="submitName" sortable> </el-table-column>
                    <el-table-column :label="$t('submitor.submitAddress')" prop="submitAddress" sortable>
                    </el-table-column>
                    <el-table-column :label="$t('submitor.submitPhone')" prop="submitPhone" sortable> </el-table-column>
                    <el-table-column :label="$t('submitor.submitFax')" prop="submitFax" sortable> </el-table-column>
                    <el-table-column :label="$t('submitor.submitEmail')" prop="submitEmail" sortable> </el-table-column>
                    <el-table-column :label="$t('submitor.fileNumber')" prop="fileNumber" sortable> </el-table-column>
                    <el-table-column :label="$t('submitor.remark')" prop="remark" sortable> </el-table-column>
                    <!-- <el-table-column label="创建日期" prop="crtDate" sortable> </el-table-column> -->
                    <el-table-column align="center" width="180px" :label="$t('action')">
                        <template v-slot="scope">
                            <el-button class="table-btn" size="small" @click=" handleEdit(scope.$index, scope.row)">
                                {{ $t('edit') }}</el-button>
                            <el-button icon="el-icon-delete" class="table-btn" size="small" type="danger"
                                @click="handleDelete(scope.$index, scope.row)">{{ $t('del') }}</el-button>

                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-row class="line-up-row">
                    {{ $t('pagination.total') }} {{ totalRecord }} {{ $t('pagination.items') }}
                    <el-select v-model="pagesize" style="width: 120px" @change="handleSizeChange" size="small">
                        <el-option :label="10 + $t('pagination.pageSize')" value="10"></el-option>
                        <el-option :label="20 + $t('pagination.pageSize')" value="20"></el-option>
                        <el-option :label="50 + $t('pagination.pageSize')" value="50"></el-option>

                    </el-select>
                    <el-pagination @current-change="paging" :current-page="currentpage" :page-size="4"
                        :total-text="$t('pagination.total')" @size-change="handleSizeChange" layout=" prev, pager, next"
                        :total="totalRecord" :prev-text="$t('pagination.prev')" :next-text="$t('pagination.next')"
                        :page-sizes="[10, 20, 50]">
                    </el-pagination>
                </el-row> -->
                <paging :totalRecord="totalRecord" :currentpage="currentpage" @handleSizeChange="handleSizeChange"
                    @paging="paging">
                </paging>
            </div>
        </div>
        <el-dialog title="提交人详细资料" :visible.sync="drawer">
            <el-card>
                <el-form :model="submitor" size="big" :rules="rules" ref="form" label-position="left">

                    <!-- <el-form-item label="签署姓名" :label-width="formLabelWidth" prop="signName">
                        <el-input v-model="submitor.signName" autocomplete="off"></el-input>
                    </el-form-item> -->
                    <el-form-item :label="$t('submitor.submitName')" :label-width="formLabelWidth" prop="submitName">
                        <el-input v-model="submitor.submitName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('submitor.submitNameE')" :label-width="formLabelWidth" prop="submitName">
                        <el-input v-model="submitor.submitNameE" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('submitor.submitAddress')" :label-width="formLabelWidth"
                        prop="submitAddress">
                        <el-input v-model="submitor.submitAddress" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('submitor.submitPhone')" :label-width="formLabelWidth" prop="submitPhone">
                        <el-input v-model="submitor.submitPhone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('submitor.submitFax')" :label-width="formLabelWidth" prop="submitFax">
                        <el-input v-model="submitor.submitFax" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('submitor.submitEmail')" :label-width="formLabelWidth" prop="submitEmail">
                        <el-input v-model="submitor.submitEmail" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('submitor.fileNumber')" :label-width="formLabelWidth" prop="fileNumber">
                        <el-input v-model="submitor.fileNumber" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('submitor.remark')" :label-width="formLabelWidth" prop="remark">
                        <el-input v-model="submitor.remark" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>

            </el-card>
            <div slot="footer" class="dialog-footer" style="text-align: right;margin-bottom: 20px;margin-right: 20px;">
                <el-button @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="submitForm('form')">保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import Table from "@/components/table";
import FormTabel from '@/components/form/form.vue'
import company_detail from "./company_detail.vue";
import paging from "@/components/paging"
import * as api from "@/api/api"
export default {
    components: {
        Table,
        company_detail,
        FormTabel,
        paging
    },

    data() {
        return {

            search: "",

            totalRecord: 0,
            currentpage: 1,
            pagesize: '10',
            tableData: [],
            drawer: false,
            editting: false,
            formLabelWidth: "140px",
            show_btn: true,
            submitor: {
                "id": "",
                "submitName": "",
                "submitNameE": "",
                "submitAddress": "",
                "submitPhone": "",
                "submitFax": "",
                "submitEmail": "",
                "fileNumber": "",
                "remark": "",
                "groupId": 0,
                "formId": 0,
                "submitType": 1,
            },
            rules: {

                submitName: [
                    { required: true, message: '必填项目', trigger: 'blur' },
                ],
                submitNameE: [
                    { required: true, message: '必填项目', trigger: 'blur' },
                ],
                submitAddress: [
                    { required: true, message: '必填项目', trigger: 'blur' },
                ],
                submitPhone: [
                    { required: true, message: '必填项目', trigger: 'blur' },
                ],
                submitFax: [
                    { required: true, message: '必填项目', trigger: 'blur' },
                ],
                submitEmail: [
                    { required: true, message: '必填项目', trigger: 'blur' },
                ],

            }
        }
    },
    watch: {
        $route(now, old) {
            // console.log(old)
            if (old.name != "companys") {
                if (this.staffName != "") {
                    this.get_data_connect()
                } else {
                    this.get_data()
                }

            }
        }

    },
    created() {
        this.get_data()
    },

    methods: {

        get_data() {
            var params = {
                keywords: this.search,
                topage: this.currentpage,
                pagesize: this.pagesize,
            }
            api.getRequest('/submitor',
                params).then(resp => {
                    // console.log(resp)
                    this.tableData = resp.data.records
                    this.totalRecord = resp.data.total
                })
        },


        handleOpen(index, row) {
            this.drawer = true

        },


        paging(val) {
            // console.log(val)
            this.currentpage = val;
            this.get_data();
        },
        handleSizeChange(val) {
            this.pagesize = val
            this.get_data();
            // console.log(`每页 ${val} 条`);
        },
        handleCancel() {
            this.drawer = false
            this.$refs['form'].resetFields();
        },
        empty_key() {
            if (this.search == "") {
                this.get_data()
            }

        },
        handleEdit(index, row) {
            console.log(index, row);
            this.form_editing = JSON.stringify(row);
            this.submitor = JSON.parse(this.form_editing);
            this.drawer = true;
            this.editting = true;
            this.editId = index;

        },
        handleDelete(index, row) {
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/submitor/' + row.id,
                    {}).then(resp => {
                        // console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }
                        let x = this.tableData.length
                        if (x == 1) {
                            let y = this.currentpage
                            this.currentpage = y - 1
                        }
                        this.get_data()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        submitForm(formName) {
            console.log(this.$refs[formName])
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.editting == false) {
                        api.postRequest('/submitor',
                            this.submitor).then(resp => {
                                console.log(resp)
                                if (resp.data == 1) {
                                    this.$message.success(resp.message)
                                    this.handleCancel()
                                    this.get_data()
                                }
                                else {
                                    this.$message.error(resp.message)
                                }

                            })
                    }
                    if (this.editting == true) {
                        api.putRequest('/submitor', this.submitor).then(resp => {
                            if (resp.data == 1) {
                                this.$message.success(resp.message);
                                this.handleCancel();
                                this.get_data()
                            } else {
                                this.$message.error(resp.message);
                            }
                        })
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

    }
}
</script>