<template>
    <div>
        <basic ref="basic"></basic>
        <found ref="found" :is_edit="show_btn" @update_price="update_price" />
        <director ref="director" :is_edit="show_btn" />
        <secretary ref="secretary" :is_edit="show_btn" />
        <controller ref="controller" :is_edit="show_btn"></controller>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-bottom: 50px;margin-right: 20px;">
            <el-button @click="handleCancel" v-if="show_btn">{{ $t("cancel") }}</el-button>
            <el-button type="primary" @click="submitForm" v-if="show_btn">{{ $t("save") }}</el-button>

            <el-button type="warning" @click="openConfirm" v-if="show_btn">{{ $t("subAud") }}</el-button>
            <!-- <el-button type="success" @click="audit_submit(3)" v-if="!show_btn">通过审核</el-button>
            <el-button type="warning" @click="audit_submit(1)" v-if="!show_btn">退回修改</el-button> -->
        </div>


        <el-dialog :title="$t('basic.txt18')" :visible.sync="showOpen">
            <!-- 审核表单 -->
            <el-form :rules="rules" ref="auditForm" :model="auti_company">
                <el-form-item :label="$t('basic.txt19')" prop="select_company">
                    <el-select v-model="auti_company.select_company" :label="$t('basic.txt20')">
                        <el-option :label="item" :value="item" v-for="item in company_detail.companyName "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt21')" prop="select_companyE" required>
                    <el-select v-model="auti_company.select_companyE" :label="$t('basic.txt20')" required>
                        <el-option :label="item" :value="item" v-for="item in company_detail.companyNameE "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt22')" v-if="company_detail.agency == 2" prop="select_companyAddress"
                    required>
                    <el-select v-model="auti_company.select_companyAddress" :label="$t('basic.txt20')" required>
                        <el-option :label="item.region + item.state + item.street + item.building + item.address"
                            :value="item" v-for="item in company_detail.companyAddress "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt23')" prop="select_companyEmail" required>
                    <el-select v-model="auti_company.select_companyEmail" :label="$t('basic.txt20')" required>
                        <el-option :label="item" :value="item" v-for="item in company_detail.companyEmail "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt231')" prop="select_companyPhone" required>
                    <el-select v-model="auti_company.select_companyPhone" :label="$t('basic.txt20')" required>
                        <el-option :label="item" :value="item" v-for="item in company_detail.companyPhone "></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('basic.txt231')" prop="select_companyPhone" required>
                    <submitor_select @selectSubmit="selectSubmit" ref="submitor" :submitor="submitor"
                        :show_btn="show_btn">
                    </submitor_select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showOpen = false">取 消</el-button>
                <el-button type="primary" @click="checkForm('auditForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Table from "@/components/table";
import * as api from "@/api/api"
import basic from "./compoemts/basic.vue"
import found from "./compoemts/found/index.vue"
import director from "./compoemts/found/director.vue";
import secretary from "./compoemts/found/secretary.vue"
import controller from "./compoemts/found/controllers.vue"
import submitor_select from "@/function/hk/compoemts/submitor/submitor_select.vue"
export default {
    components: {
        Table,
        basic,
        found,
        director,
        secretary,
        controller,
        submitor_select
    },
    props: {
        c_id: Number
    },
    watch: {
        'c_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {

                    this.show_btn = false
                    this.get_data(newVal)

                }

            },
            deep: true
        },
    },
    data() {
        return {
            search: "",
            detail: {},
            editting: false,
            show_btn: true,
            edit_id: 0,
            price_package: {
                priceOrdinary: 1,
                pricePre: 1,
                currencyOrdinary: "HKD",
                currencyPre: "HKD",
            },
            company_detail: {},
            auti_company: {
                select_company: "",
                select_companyE: "",
                select_companyAddress: "",
                select_companyEmail: "",
                select_companyPhone: ""
            },
            member: {
                "area": "",
                "code": "",
                "country": "",
                "email": "",
                "exCName": "",
                "exEName": "",
                "id": 1,
                "idNumber": "",
                "isHkComp": 0,
                "memberType": 0,
                "nameC": "",
                "nameE1": "",
                "nameE2": "",
                "otherCName": "",
                "otherEName": "",
                "passport": "",
                "staffId": 0,
                "staffType": 0
            },
            found1: {},
            showOpen: false,
            select_company: null,
            select_companyE: null,
            select_companyEmail: null,
            select_companyAddress: null,
            rules: {
                // select_company: [
                //     { required: true, message: '請選擇公司中文名稱', trigger: 'change' },
                // ],
                select_companyE: [
                    { required: true, message: '請選擇公司英文名稱', trigger: 'change' },
                ],
                select_companyAddress: [
                    { required: true, message: '請選擇公司地址', trigger: 'change' },
                ],
                select_companyEmail: [
                    { required: true, message: '請選擇公司郵箱', trigger: 'change' },
                ],
                select_companyPhone: [
                    { required: true, message: '請選擇公司聯繫電話', trigger: 'change' },
                ]
            },
            submitor: {},

        }
    },

    created() {
        console.log(sessionStorage.getItem("realName"))
        if (this.c_id) {

            this.get_data(this.c_id)
        }

        if (this.$route.query.id) {
            if (this.$route.query.id > 0) {
                this.get_data(this.$route.query.id)
                this.editting = true
                this.edit_id = this.$route.query.id

            }
            if (this.$route.query.id == 0) {

                this.editting = false
                this.show_btn = true

            }
        }


    },

    methods: {

        open(scope) {
            this.dialogConfig = {
                show: true,
                item: { ...scope, hk_account: '', hk_password: '' }
            }
        },

        get_data(id) {

            // let id = this.$route.query.id

            var params = {}
            api.getRequest('/company/v2/' + id,
                params).then(resp => {


                    this.detail = resp.data
                    let item = resp.data
                    this.$refs.basic.basiConfig1 = this.detail


                    // // 创办人及高级人员 -- 自然人
                    this.$refs.found.found1 = item.members1
                    // // 创办人及高级人员法人
                    this.$refs.found.found2 = item.members2

                    // 董事 -- 自然人
                    this.$refs.director.found1 = item.members3
                    // 董事 -- 法人
                    this.$refs.director.found2 = item.members4


                    // 秘书 --  自然人
                    this.$refs.secretary.found1 = item.members5
                    // 秘书 --  法人
                    this.$refs.secretary.found2 = item.members6


                    ///实际控制人
                    if (item.controller != null) {
                        this.$refs.controller.controller = item.controller
                    }



                    console.log(item)
                    this.company_detail = item
                    if (item.priceOrdinary != "") {
                        this.$refs.found.price = item.priceOrdinary
                    }
                    if (item.currencyOrdinary != "") {
                        this.$refs.found.currencyOrdinary = item.currencyOrdinary
                    }
                    if (item.pricePre != "") {
                        this.$refs.found.price2 = item.pricePre
                    }
                    if (item.currencyPre != "") {
                        this.$refs.found.currencyPre = item.currencyPre
                    }



                })
        },
        submitForm() {

            let id = this.$route.query.id
            console.log(this.$refs.basic.basiConfig1)
            var company_detail = this.$refs.basic.basiConfig1

            // 创办人及高级人员 -- 自然人
            var found_member = this.$refs.found.found1
            // 创办人及高级人员法人
            var legalPersonFounder = this.$refs.found.found2


            // 董事 -- 自然人
            var director_human = this.$refs.director.found1
            // 董事 -- 法人
            var director_legal = this.$refs.director.found2

            // 秘书 --  自然人
            var secretary_human = this.$refs.secretary.found1

            // 秘书 --  法人
            var secretary_legal = this.$refs.secretary.found2

            let data = {
                members1: found_member,
                members2: legalPersonFounder,
                members3: director_human,
                members4: director_legal,
                members5: secretary_human,
                members6: secretary_legal,
            }

            var canSubmit = true
            data.members1.forEach(human => {
                data.members3.forEach(item => {
                    console.log(item.idNumber)
                    if (human.staffId == item.staffId) {
                        if (item.idNumber != undefined && human.idNumber != item.idNumber) {

                            canSubmit = false
                        }
                        if (human.passport != item.passport) {
                            canSubmit = false
                        }
                        if (human.address != item.address) {
                            canSubmit = false
                        }

                    }
                })
            })
            data.members3.forEach(human => {
                data.members5.forEach(item => {
                    console.log(item.idNumber)
                    if (human.staffId == item.staffId) {

                        if (item.idNumber != undefined && human.idNumber != item.idNumber) {
                            canSubmit = false
                        }
                        if (human.passport != item.passport) {
                            canSubmit = false
                        }
                        if (human.address != item.address) {
                            canSubmit = false
                        }

                    }
                })
            })

            console.log(data.members3.length + "--" + data.members4.length)
            var same_secretary = false
            if ((data.members3.length + data.members4.length) == 1) {
                data.members3.forEach(human => {
                    data.members5.forEach(item => {
                        if (human.staffId == item.staffId) {
                            same_secretary = true
                        }
                    })
                })

            }

            if (same_secretary == true) {
                this.$message.error("董事只有一个人，秘书不可以是同一个人");
            }
            else {
                console.log(canSubmit + "--" + same_secretary)
                if (canSubmit == true) {
                    company_detail.members1 = data.members1
                    company_detail.members2 = data.members2
                    company_detail.members3 = data.members3
                    company_detail.members4 = data.members4
                    company_detail.members5 = data.members5
                    company_detail.members6 = data.members6
                    company_detail.controller = this.$refs.controller.controller
                    company_detail.priceOrdinary = this.price_package.priceOrdinary
                    company_detail.pricePre = this.price_package.pricePre
                    company_detail.currencyOrdinary = this.price_package.currencyOrdinary
                    company_detail.currencyPre = this.price_package.currencyPre

                    console.log(company_detail)
                    if (this.editting == false) {
                        api.postRequest('/company/v2', company_detail).then(resp => {

                            if (resp.data == 1) {

                                this.$message.success(resp.message);
                                this.$router.replace('/companys?apply=pre')
                            } else {
                                this.$message.error(resp.message);
                            }
                        })
                    } if (this.editting == true) {
                        company_detail.id = this.edit_id
                        api.putRequest('/company/v2', company_detail).then(resp => {

                            if (resp.data == 1) {
                                this.$message.success(resp.message);
                                this.$router.replace('/companys?apply=pre')
                            } else {
                                this.$message.error(resp.message);
                            }
                        })

                    }
                }
                else {
                    this.$message.error("請統一同一個成員的身份證或者地址信息");
                }
            }




        },
        update_price(item) {
            console.log(item)
            this.price_package = item

        },
        handleCancel(val) {
            this.$router.back()
        },


        openConfirm() {
            this.showOpen = true
        },

        checkForm(formName) {
            console.log(this.auti_company)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.audit_submit(0)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 审核提交
        audit_submit(status) {

            this.company_detail.companyName = []
            this.company_detail.companyName.push(this.auti_company.select_company)
            this.company_detail.companyNameE = []
            this.company_detail.companyNameE.push(this.auti_company.select_companyE)
            this.company_detail.companyEmail = []
            this.company_detail.companyEmail.push(this.auti_company.select_companyEmail)
            this.company_detail.companyAddress = []
            this.company_detail.companyAddress.push(this.auti_company.select_companyAddress)

            console.log(this.company_detail)

            this.$confirm('提交审核公司资料?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.putRequest('/company/v2', this.company_detail).then(resp => {
                    if (resp.data == 1) {
                        var audit = {
                            "auditBy": "",
                            "auditStatus": status,
                            "auditTime": "",
                            "auditUser": "",
                            "companyId": this.edit_id,
                            "companyName": this.auti_company.select_company,
                            "companyNameE": this.auti_company.select_companyE,
                            "formType": "NNC1",
                            "remark": "",
                            "submitor": this.submitor,
                            "submitBy": sessionStorage.getItem("adminKey"),
                            "submitUser": sessionStorage.getItem("realName"),
                        }
                        console.log(audit)
                        api.postRequest('/audit',
                            audit).then(resp => {
                                if (resp.data == 1) {
                                    this.$message.success(resp.message)
                                    this.showOpen = false
                                }
                                else {
                                    this.$message.error(resp.message)
                                }

                            })

                    } else {
                        this.$message.error(resp.message);
                    }
                })


            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        selectSubmit(val) {


            this.submitor = val
            this.submitor.submitType = 2
            console.log(JSON.stringify(this.submitor))

        },




    }
}
</script>
