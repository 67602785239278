<template>
    <el-card>
        <div slot="header" class="clearfix">
            <span>{{ $t("company.title3") }}</span>
        </div>

        <div class="header-btn" v-if="is_edit">
            <el-button type="primary" @click="open(1)">{{ $t("controller.txt7") }}</el-button>

        </div>

        <span style="text-align: right;font-weight: 800;width: 100%;"></span>

        <!-- 实际控制人自然人列表 -->
        <Table :title="foundTitleX" :table-data="controller">


            <template v-slot:address="scope" >
                {{ scope.scope.address.region + scope.scope.address.state + scope.scope.address.street +
                    scope.scope.address.building +
                    scope.scope.address.address }}
            </template>




            <template v-slot:class="scope" >
                {{ scope.scope.class == 1 ? $t('controller.txt1') : $t('controller.txt2') }}
            </template>


            <template v-slot:edit="scope" >
                <el-button type="danger" size="small" plain round @click="deleteRow(scope.scope)" v-if="is_edit">{{
                    $t("del-btn") }}</el-button>

            </template>
        </Table>


        <el-dialog :title="dialog.type == 1 ? $t('company.add1') : dialog.type == 3 ? $t('company.add2') : $t('edit')"
                   :visible.sync="dialog.show" append-to-body width="1200px" :close-on-click-modal="false"
            :destroy-on-close="true">

            <Search :type="dialog.type" />

            <DialogOne ref="foundDialogOne" :package="new_price" v-show="dialog.type == 1" @onCancel="onCancel"
                :show_controller="true" :show_share="false" @onConfirm='onConfirm1' />



        </el-dialog>


    </el-card>
</template>

<script>
import Table from "@/components/table/table";
import Search from '../search.vue'

import DialogOne from "./dialog-one.vue";
import DialogTwo from "./dialog-two.vue";

export default {
    components: {
        Table,
        Search,
        DialogOne,
        DialogTwo,

    },
    props: {
        is_edit: Boolean,
        stack_price_ordinary: Number,
        stack_price_pre: Number,
        show_appoint: Boolean,
        title: Number,
        show_list_btn: Boolean

    },
    watch: {


        'show_appoint': {
            immediate: true,
            handler(newVal) {
                this.show_appoint = newVal

            },
            deep: true
        },
        'show_list_btn': {
            immediate: true,
            handler(newVal) {
                console.log(newVal)
            },
            deep: true
        },
        'title': {
            immediate: true,
            handler(newVal) {
                console.log(newVal)
                if (newVal == 1) {
                    this.show_title = "现有董事"
                }
                if (newVal == 2) {
                    this.show_title = "新委任董事"
                }
                if (newVal == 0) {
                    this.show_title = this.$("company.title2")//"董事成员"
                }

            },
            deep: true
        },



    },
    data() {
        return {
            price: 1,
            price2: 1,
            show_edit: true,
            show_title: "",
            dialog: {
                show: false,
                type: 1, // 1、添加 2、编辑
                item: {},
            },
            show_share: false,
            found1: [], // 自然人
            controller: [],///实际控制人
            found2: [], // 法人
            found3: [], // 法人
            director_appoint: [],
            director_unappoint: [],



            new_price: {},
            ////自然人表格
            foundTitle: [
                {
                    id: 1,
                    prop: 'date1',
                    label: '委任日期',
                    align: 'center',

                },
                {
                    id: 2,
                    prop: 'date2',
                    label: '辭任日期',
                    align: 'center'
                },
                {
                    id: 3,
                    prop: 'nameC',
                    label: '控制人名稱',
                    align: 'center',

                },

                {
                    id: 4,
                    prop: 'address',
                    label: '住址',
                    align: 'center',
                    template: true
                },
                {
                    id: 9,
                    prop: 'passport',
                    label: '護照簽發國家及號碼',
                    align: 'center',

                },
                {
                    id: 15,
                    prop: 'idNumber',
                    label: '香港身份證號碼',
                    align: 'center',

                },
                {
                    id: 10,
                    prop: 'class',
                    label: '持股類別',
                    align: 'center',
                    template: true
                },
                {
                    id: 11,
                    prop: 'shares',
                    label: '最終持股/股份百份比',
                    align: 'center'
                },
                {
                    id: 12,
                    prop: 'companyName',
                    label: '投資公司名稱',
                    align: 'center',
                },

                {
                    id: 13,
                    prop: 'remark',
                    label: '備註',
                    align: 'center',
                },
                {
                    id: 14,
                    prop: 'edit',
                    label: '操作',
                    align: 'center',
                    width: '100px',
                    template: true
                },


            ],


        }
    },
    watch: {
        "$i18n.locale"() {

            if (localStorage.getItem("lang") == "en") {
                this.foundTitle1X = this.foundTitle1En
                this.foundTitleX = this.foundTitleEn
                this.show_title = "Board members"
            }
            if (localStorage.getItem("lang") == "cn") {
                this.foundTitle1X = this.foundTitle1
                this.foundTitleX = this.foundTitle
                this.show_title = "董事成员"
            }


        }
    },
    created() {
        if (localStorage.getItem("lang") == "en") {
            this.foundTitle1X = this.foundTitle1En
            this.foundTitleX = this.foundTitleEn
            this.show_title = "Board members"
        }
        if (localStorage.getItem("lang") == "cn") {
            this.foundTitle1X = this.foundTitle1
            this.foundTitleX = this.foundTitle
            this.show_title = "董事成员"
        }
    },
    methods: {



        deleteRow(e) {
            console.log(e)
            let self = this
            let index = ''
            index = self.controller.findIndex(d => { return d.staffId == e.staffId })
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                self.controller.splice(index, 1)

            }).catch(() => {

            });

        },



        // 打开弹窗
        open(type, item = {}) {

            this.dialog = {
                show: true,
                type: type + ""
            }





        },

        onCancel() {
            this.dialog.show = false
        },
        onConfirm1(item) {
            console.log(item)
            var companyName = ""
            if (item.controll.company) {
                companyName = item.controll.company.companyName[0]
            }

            var companyNameE = ""
            if (item.controll.company) {
                companyNameE = item.controll.company.companyNameE[0]
            }

            var companyId = ""
            if (item.controll.company) {
                companyId = item.controll.company.id
            }

            var idNumber = ""
            var passport = ""
            if (item.selIdNumber) {
                idNumber = item.selIdNumber.idNumber
            }
            if (item.selPassport) {
                passport = item.selPassport.passport
            }
            var controll = {
                date1: item.controll.date1,
                date2: item.controll.date2,
                nameC: item.nameC,
                passport: passport,
                idNumber: idNumber,
                address: item.selAddress,
                class: item.controll.class,
                shares: item.controll.shares,
                remark: item.controll.remark,
                staffId: item.staffId,
                companyName: companyName,
                companyNameE: companyNameE,
                companyId: companyId

            }
            console.log(controll)
            this.controller.push(controll)
            this.onCancel()
        },



    },



}
</script>

<style >
.header-btn {
    padding-bottom: 10px;
}

.el-card {
    margin-bottom: 20px;
}

.el-input,
.el-select,
.el-autocomplete {
    width: 100%;
}

.box {
    padding-bottom: 20px;
}

.form-title {
    font-size: 16px;
    padding-bottom: 10px;
}

.must {
    color: red;
}

.el-input,
.el-select {
    width: 100%;
}

.question {
    color: #409eff;
    cursor: pointer;
}


.box {
    padding-right: 20px;
}

.form-title {
    padding-right: 10px;
    padding-bottom: 0px;
}
</style>
