<template>
  <div id="wrapper">
    <nav class="navbar-default navbar-static-side" role="navigation" width="leftWidth">
      <div class="sidebar-collapse">
        <ul class="nav metismenu" id="side-menu">
          <li class="nav-header">
            <div class="dropdown profile-element">
              <img alt="image" class="rounded-circle" src="img/profile_small.jpg">
              <a data-toggle="dropdown" class="dropdown-toggle" href="javascript:void(0)">
                <span class="block m-t-xs font-bold">{{ realName }}</span>
                <span class="text-muted text-xs block"></span>
              </a>
              <!-- <ul class="dropdown-menu animated fadeInRight m-t-xs">
                  <li>
                    <a class="dropdown-item" href="profile.html">个人信息</a>
                  </li>
                  <li><a class="dropdown-item" href="contacts.html">好友</a></li>
                  <li><a class="dropdown-item" href="mailbox.html">信箱</a></li>
                  <li class="dropdown-divider"></li>
                  <li><a class="dropdown-item" href="login.html">登出</a></li>
                </ul> -->
            </div>
            <div class="logo-element">MC+</div>
          </li>

          <el-menu :default-active="onRoutes" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
            :collapse="isCollapse" background-color="#2f4050" text-color="#fff" width="leftWidth" router
            active-text-color="#ffffff">
            <template v-for="(obj, index) in menu_data">
              <el-menu-item v-if="!obj.sub" :key="index" :index="obj.link" @click="goto_page(obj.link)">
                <i :class="obj.icon"></i>
                <span slot="title">{{ obj.title }}</span>
              </el-menu-item>
              <el-submenu v-if="obj.sub" :key="index" :index="obj.link">
                <template v-slot:title>
                  <i :class="obj.icon"></i>
                  <span>{{ obj.title }}</span>
                </template>
                <el-menu-item v-for="(sub, index) in obj.sub" :key="index" :index="sub.link"
                  @click="goto_page(sub.link)">
                  {{ sub.title }}</el-menu-item>
              </el-submenu>
            </template>
          </el-menu>

        </ul>

      </div>
    </nav>
    <div id="page-wrapper" class="gray-bg">
      <div class="row border-bottom">
        <nav class="navbar navbar-static-top  " role="navigation" style="margin-bottom: 0">
          <div class="navbar-header">
            <a class="navbar-minimalize minimalize-styl-2 btn btn-primary " @click="doToggle"
              href="javascript:void(0)"><i class="fa fa-bars"></i> </a>
            <form role="search" class="navbar-form-custom" action="search_results.html">
              <!-- <div class="form-group">
                  <input type="text" placeholder="请输入搜索内容" class="form-control" name="top-search" id="top-search">
                </div> -->
            </form>
          </div>
          <ul class="nav navbar-top-links navbar-right">
            <li>
              <span class="m-r-sm text-muted welcome-message">{{ company_name }}</span>
            </li>
            <!-- <li class="dropdown">
                <a class="dropdown-toggle count-info" data-toggle="dropdown" href="#">
                  <i class="fa fa-envelope"></i> <span class="label label-warning">16</span>
                </a>
                <ul class="dropdown-menu dropdown-messages">
                  <li>
                    <div class="dropdown-messages-box">
                      <a class="dropdown-item float-left" href="profile.html">
                        <img alt="image" class="rounded-circle" src="img/a7.jpg">
                      </a>
                      <div class="media-body">
                        <small class="float-right">46 小时前</small>
                        <strong>小明</strong> 评论了 <strong>小红</strong>. <br>
                        <small class="text-muted">2017.10.06 7:58</small>
                      </div>
                    </div>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li>
                    <div class="dropdown-messages-box">
                      <a class="dropdown-item float-left" href="profile.html">
                        <img alt="image" class="rounded-circle" src="img/a4.jpg">
                      </a>
                      <div class="media-body ">
                        <small class="float-right text-navy">5 小时前</small>
                        <strong>小红</strong> 打电话给了 <strong>小黑</strong>. <br>
                        <small class="text-muted">2017.10.06 7:58</small>
                      </div>
                    </div>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li>
                    <div class="dropdown-messages-box">
                      <a class="dropdown-item float-left" href="profile.html">
                        <img alt="image" class="rounded-circle" src="img/profile.jpg">
                      </a>
                      <div class="media-body ">
                        <small class="float-right">23 小时前</small>
                        <strong>小黑</strong> 点赞了 <strong>小红</strong>. <br>
                        <small class="text-muted">2017.10.06 7:58</small>
                      </div>
                    </div>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li>
                    <div class="text-center link-block">
                      <a href="mailbox.html" class="dropdown-item">
                        <i class="fa fa-envelope"></i> <strong>阅读所有消息</strong>
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a class="dropdown-toggle count-info" data-toggle="dropdown" href="#">
                  <i class="fa fa-bell"></i> <span class="label label-primary">8</span>
                </a>
                <ul class="dropdown-menu dropdown-alerts">
                  <li>
                    <a href="mailbox.html" class="dropdown-item">
                      <div>
                        <i class="fa fa-envelope fa-fw"></i> 你有16条消息
                        <span class="float-right text-muted small">4 分钟前</span>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li>
                    <a href="profile.html" class="dropdown-item">
                      <div>
                        <i class="fa fa-twitter fa-fw"></i> 3 个新的关注者
                        <span class="float-right text-muted small">12 分钟前</span>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li>
                    <a href="grid_options.html" class="dropdown-item">
                      <div>
                        <i class="fa fa-upload fa-fw"></i> 重启服务器
                        <span class="float-right text-muted small">4 分钟前</span>
                      </div>
                    </a>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li>
                    <div class="text-center link-block">
                      <a href="notifications.html" class="dropdown-item">
                        <strong>查看所有信息</strong>
                        <i class="fa fa-angle-right"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </li> -->


            <li>
              <a href="javascript:void(0)" @click="open_lan()">
                <i class="fa fa-font"></i> {{ $t('setlang') }}
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" @click="log_out()">
                <i class="fa fa-sign-out"></i> {{ $t('logout') }}
              </a>
            </li>
          </ul>

        </nav>
      </div>
      <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-sm-4">
          <h2>{{ mytitle }}</h2>
          <!-- <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/empty">主页</a>
              </li>
              <li class="breadcrumb-item active">
                <strong>面包屑</strong>
              </li>
            </ol> -->
        </div>
        <div class="col-sm-8">
          <div class="title-action">
            <el-button size="small" icon="el-icon-arrow-left" @click="goBack" round>{{ $t('goback') }}</el-button>
          </div>
        </div>
      </div>
      <div class="wrapper wrapper-content">
        <keep-alive>
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      </div>
      <Footer></Footer>
    </div>
    <el-dialog :title="$t('setlang')" :visible.sync="dialogFormVisible" :wrapper-closable="false"
      :close-on-click-modal="false">

      <el-form>

        <el-form-item :label="$t('setlang')" :label-width="formLabelWidth">
          <el-select v-model="sellan" @change="setLang">
            <el-option label="繁体" value="cn"></el-option>
            <el-option label="English" value="en"></el-option>

          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as api from "../api/api"
import Footer from "../components/foot_gap.vue"

// import side_menu from "../../public/js/inspinia"

export default {

  components: {
    Footer
  },
  data() {
    return {
      menu_data: [],
      default_menu: 1,
      isCollapse: false,
      leftWidth: '220px',
      myIcon: 'el-icon-s-fold',
      mytitle: '',
      realName: "",
      company_name: sessionStorage.getItem("myCompany"),
      selectLan: '1',
      sellan: "cn",
      formLabelWidth: '160px',
      dialogFormVisible: false,
    }
  },
  mounted() {
    this.check_status()
    this.realName = sessionStorage.getItem("realName")
    if (localStorage.getItem("lang")) {
      this.sellan = localStorage.getItem("lang")
      this.setLang(this.sellan)
    }
    this.get_data()
  },
  created: async function () {

  },
  computed: {
    onRoutes() {

      // 当前激活菜单的 index
      var lang = localStorage.getItem("lang")
      let index = this.$route.path;
      let title = this.$route.meta.title[lang]

      // console.log(title);
      // 改变浏览器title
      document.title = title
      this.mytitle = title
      return index
    },
  },
  methods: {

    check_status() {
      var token = sessionStorage.getItem("token")
      // console.log(token)
      if (token == "") {
        this.$message.warning('登录超时,请重新登录');
        this.$router.replace('/')
      }
    },
    open_lan() {
      this.dialogFormVisible = true
    },
    setLang(val) {
      console.log(val)
      localStorage.setItem("lang", this.sellan)
      this.sellan = val
      this.get_data()
      let title = this.$route.meta.title[val]
      // console.log(title);
      // 改变浏览器title
      document.title = title
      this.mytitle = title
      this.$i18n.locale = val// this.$i18n.locale === 'zh-cn' ? 'en' : 'zh-cn';
      if (val == 'en') {
        this.leftWidth = "300px"
      }
      else {
        this.leftWidth = "220px"
      }
    },
    get_data() {
      this.menu_data = [];
      // console.log(this.menu_data)
      var lang = localStorage.getItem("lang")
      console.log(lang)
      var params = {
        adminType: sessionStorage.getItem("adminType")
      }

      api.getRequest('/menuDatas',
        params).then(resp => {
          // console.log(resp.data)
          let newdata = resp.data
          let newNodes = []
          for (var i = 0; i < newdata.length; i++) {
            if (newdata[i].level == 0) {
              var title = newdata[i].title
              if (lang === "cn") {
                title = newdata[i].titleTc
              }
              if (lang === "en") {
                title = newdata[i].titleEn
              }

              var newRecord = {
                title: title,
                link: newdata[i].link,
                icon: newdata[i].icon,
                fatherId: newdata[i].fatherId,
                id: newdata[i].id + "",

              }
              newNodes.push(newRecord)
            }
          }

          for (var i = 0; i < newdata.length; i++) {
            if (newdata[i].level == 1) {
              for (var j = 0; j < newNodes.length; j++) {
                if (newNodes[j].id == newdata[i].fatherId) {
                  var subtitle = newdata[i].title
                  if (lang === "cn") {
                    subtitle = newdata[i].titleTc
                  }
                  if (lang === "en") {
                    subtitle = newdata[i].titleEn
                  }
                  if (newNodes[j].sub) {

                    newNodes[j].sub.push({
                      title: subtitle,// newdata[i].title,
                      link: newdata[i].link,
                      icon: newdata[i].icon
                    })
                  } else {
                    newNodes[j].sub = []
                    newNodes[j].sub.push({
                      title: subtitle,// newdata[i].title,
                      link: newdata[i].link,
                      icon: newdata[i].icon
                    })
                  }
                }
              }
            }
          }
          // console.log(newNodes)
          this.menu_data = newNodes;

        })

    },
    goto_page(value) {
      this.$router.replace(value);
    },
    log_out() {
      sessionStorage.setItem("token", "")
      this.menu_data = []
      this.$router.replace('/')
      this.$message.success('退出登录')

    },
    doToggle() {
      this.isCollapse = !this.isCollapse;
      if (this.myIcon == 'el-icon-s-fold') {
        this.myIcon = 'el-icon-s-unfold'
        document.getElementsByTagName('body')[0].className = 'pace-done  mini-navbar'
      } else {
        this.myIcon = 'el-icon-s-fold'
        document.getElementsByTagName('body')[0].className = 'pace-done'
      }
    },
    handleOpen(key, keyPath) {
      this.default_menu = key
      // console.log(key, keyPath);

    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    goBack() {
      this.$router.back()
    }
  }

};
</script>

<style scoped>
body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #2f4050;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
  min-height: 400px;
  border-right: #000000;
  border-left: 2px;
  border-color: #293846;

}

.el-menu--collapse {
  width: 70px;
}

.el-menu-item.is-active {
  background-color: #293846 !important;
}

.el-menu-item {
  white-space: normal;
  word-break: break-all;
}

.el-submenu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 200px;
  border-left: 4px solid #19aa8d;
  font-size: 14px;
}

.el-menu {
  border-right: 0px;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: #FFF;
}
</style>