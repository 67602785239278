export class individual {
    constructor(id) {
        this.id = id
        this.staffType = 1
        this.files1 = []
        this.files2 = []
        this.files3 = []
        this.address = []
        this.passports = []
        this.idNumbers = []
        this.nameC = ""
        this.nameE1 = ""
        this.nameE2 = ""
        this.otherCName = ""
        this.otherEName = ""
        this.exCName = ""
        this.exEName = ""
        this.crtBy = sessionStorage.getItem("token")
        this.udateBy = ""
    }

}

export function newIndividual(id) {
    return new individual(id)
}

export class address {
    constructor(region, state, street, building, address) {
        this.region = region
        this.state = state
        this.street = street
        this.building = building
        this.address = address
    }

}

export function newAddress() {
    return new address("", "", "", "", "")
}

