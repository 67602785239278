<template>
    <div>

        <director ref="director_new" :is_edit="false" :show_appoint="show_1" :title="2" :show_list_btn="false" />
        <director ref="director_old" :is_edit="false" :show_appoint="false" :title="1" :show_list_btn="true" />
        <secretary ref="secretary_new" :is_edit="false" :title="2" :show_list_btn="false" :show_appoint="show_1" />
        <secretary ref="secretary_old" :is_edit="false" :title="1" :show_list_btn="true" :show_appoint="false" />
        <submitor_select @selectSubmit="selectSubmit" :submitor="submitor" :show_btn="show_btn" ref="submitor"
            v-show="show_btn">
        </submitor_select>
        <div slot="footer" class="dialog-footer" style="text-align: right;margin-bottom: 50px;margin-right: 20px;">
            <el-button type="warning" @click="audit_submit(0)" v-if="show_btn">{{ $t('subAud') }}</el-button>
        </div>
    </div>
</template>
<script>

import submitor_select from "../submitor/submitor_select.vue"
import * as api from "@/api/api"
import director from "@/function/hk/compoemts/found/director.vue";
import secretary from "@/function/hk/compoemts/found/secretary.vue"
export default {
    components: {

        director,
        secretary,
        submitor_select

    },
    props: {
        c_id: Number,
        audit_id: Number
    },
    watch: {
        'c_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {
                    this.show_btn = true
                    this.show_1 = true
                    this.get_data(newVal)
                    this.edit_id = newVal


                }

            },
            deep: true
        },
        'audit_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {

                    this.show_btn = false
                    this.show_1 = false
                    this.get_data_form(newVal)
                    this.show_btn = false

                    // this.edit_id = newVal
                }

            },
            deep: true
        },
    },
    data() {
        return {
            search: "",
            detail: {},
            editting: false,
            show_btn: true,
            show_appoint: true,
            show_1: true,
            edit_id: 0,
            price_package: {
                priceOrdinary: 1,
                pricePre: 1,
                currencyOrdinary: "HKD",
                currencyPre: "HKD",
            },
            company_detail: {},
            member: {
                "area": "",
                "code": "",
                "country": "",
                "email": "",
                "exCName": "",
                "exEName": "",
                "id": 1,
                "idNumber": "",
                "isHkComp": 0,
                "memberType": 0,
                "nameC": "",
                "nameE1": "",
                "nameE2": "",
                "otherCName": "",
                "otherEName": "",
                "passport": "",
                "staffId": 0,
                "staffType": 0
            },
            form: {
                "companyName": "",
                "companyNameE": "",
                "companyAddr1": "",
                "submitBy": "",
                "submitUser": "",
                "companyAddr2": "",
                "companyAddr3": "",
                "companyCode": "",
                "companyEmail": "",
                "companyName": "",
                "companyNameE": "",
                "companyCode": "",
                "newEmail": '',
                "signName": '',
                "submitName": '',
                "submitAddress": '',
                submitPhone: '',
                submitFax: '',
                submitEmail: '',
                fileNumber: '',


            },
            found1: {},
            submitor: {},
            new_submitor: {},

        }
    },

    created() {



    },

    methods: {



        get_data(id) {



            var params = {}
            api.getRequest('/company/v2/' + id,
                params).then(resp => {

                    let item = resp.data
                    this.company_detail = resp.data
                    var director = []
                    this.company_detail.members4.forEach(function (items) {
                        director.push(items)
                    })
                    this.company_detail.members3.forEach(function (items) {
                        director.push(items)
                    })
                    console.log(director)
                    this.$refs.submitor.directors = director
                    this.$refs.submitor.show_signName = false

                    this.show_btn = true
                    // 董事 -- 自然人
                    this.$refs.director_old.found1 = item.members3
                    // 董事 -- 法人 
                    this.$refs.director_old.found2 = item.members4


                    // 秘书 --  自然人
                    this.$refs.secretary_old.found1 = item.members5
                    // 秘书 --  法人
                    this.$refs.secretary_old.found2 = item.members6

                })
        },


        get_data_form(id) {


            var params = { id: id }
            api.getRequest('/nd2a',
                params).then(resp => {
                    console.log(resp)


                    // 董事 -- 自然人

                    this.$refs.director_old.found1 = resp.data.members3
                    // 董事 -- 法人 
                    this.$refs.director_old.found2 = resp.data.members4

                    // 董事 -- 自然人
                    this.$refs.director_new.found1 = resp.data.members3New
                    // 董事 -- 法人 
                    this.$refs.director_new.found2 = resp.data.members4New

                    // 董事 -- 自然人

                    this.$refs.secretary_old.found1 = resp.data.members5
                    // 董事 -- 法人 
                    this.$refs.secretary_old.found2 = resp.data.members6

                    // 董事 -- 自然人
                    this.$refs.secretary_new.found1 = resp.data.members5New
                    // 董事 -- 法人 
                    this.$refs.secretary_new.found2 = resp.data.members6New


                })
        },


        handleCancel(val) {
            this.$router.back()
        },
        selectSubmit(val) {
            console.log(val)
            this.new_submitor = val
            this.new_submitor.submitType = 3
        },

        audit_submit(status) {
            this.$confirm('提交审核公司资料?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {



                var HkHkNd2a = {
                    companyId: this.edit_id,
                    members3: this.$refs.director_old.unappoint_member3,
                    members3New: this.$refs.director_new.appoint_member3,
                    members4: this.$refs.director_old.unappoint_member4,
                    members4New: this.$refs.director_new.appoint_member4,
                    members5: this.$refs.secretary_old.unappoint_member5,
                    members5New: this.$refs.secretary_new.appoint_member5,
                    members6: this.$refs.secretary_old.unappoint_member6,
                    members6New: this.$refs.secretary_new.appoint_member6
                }
                console.log("提交ND2A" + JSON.stringify(HkHkNd2a))

                api.postRequest('/nd2a',
                    HkHkNd2a).then(resp => {
                        console.log(resp)
                        if (resp.data !== -1) {

                            var audit = {
                                "auditBy": "",
                                "auditStatus": status,
                                "auditTime": "",
                                "formId": resp.data,
                                "auditUser": "",
                                "companyId": this.edit_id,
                                "companyName": this.company_detail.companyName[0],
                                "companyNameE": this.company_detail.companyNameE[0],
                                "formType": "ND2A",
                                "remark": "",
                                "submitBy": sessionStorage.getItem("adminKey"),
                                "submitUser": sessionStorage.getItem("realName"),
                                submitor: this.new_submitor
                            }
                            api.postRequest('/audit',
                                audit).then(resp => {
                                    if (resp.data == 1) {
                                        this.$message.success(resp.message)


                                    }
                                    else {
                                        this.$message.error(resp.message)
                                    }

                                })
                        }
                        else {
                            this.$message.error(resp.message)
                        }

                    })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },




    }
}
</script>