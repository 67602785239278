<template>
  <el-select v-model="value" placeholder="请选择" @change="changeVal" style="width: 100%;" :disabled="cantEdit">
    <el-option v-for="item in options" :key="item.id" :label="item.realName" :value="item.id.toString()">
    </el-option>

  </el-select>
</template>

<style scoped>

</style>
<script>
import * as api from "@/api/api"
export default {
  created: async function () {
    this.get_data();
  },
  props: {
    'select_admin': {
      type: String,
      Default: 0
    },
    'cantEdit':{
      type: Boolean,
      Default: false
    }
  },
  watch: {
    'select_admin': {
      immediate: true,
      handler(newVal) {
        this.value = newVal+""
      },
      deep: true
    },

  },
  data() {
    return {
      options: [],
      value:0,
    }
  },
  methods: {
    get_data() {
      var params = {
        keywords: "",
        pagesize:1000,
      }
      api.getRequest('/admins',
          params).then(resp => {
        console.log(resp)
        this.options = resp.data.records

      })
    },
    changeVal() {
      this.$emit('selectAdmin', this.value);
    },

  }
}
</script>