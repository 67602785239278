<template>
  <div class="pagination space-between align-center">
    <span class="right">共{{ pagination.total_pieces }}条记录，{{ pagination.current_page }}/{{ pagination.total_page
    }}页</span>
    <!-- <el-pagination
      :total="pagination.total_pieces"
      :current-page="pagination.current_page"
      :page-size="page_pieces"
      background
      layout="prev, pager, next"
      @current-change="selectPage"
    >
    </el-pagination> -->

    <el-pagination @size-change="handleSizeChange" @current-change="selectPage"
      v-model:current-page="pagination.current_page" :page-sizes="[10, 50, 100, 200, 500, 1000]" :page-size="page_pieces"
      layout="sizes, prev, pager, next" :total="pagination.total_pieces" :background='true'>
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: null
      //   current_page: 1, //当前页
      //   page_pieces: 0, //当前页条数
      //   total_page: 0, //总页数
      //   total_pieces: 0 //总数
    }
  },
  data() {
    return {
    };
  },

  methods: {
    selectPage(e) {
      console.log("第几页" + e)
      this.$emit("selectPage", { page: e, limit: this.page_pieces });
    },

    // 每页大小
    handleSizeChange(val) {
      this.$store.commit('user/setLimit', val);
      this.$emit("selectPage", { page: 1, limit: val });
    }
  },

  computed: {
    page_pieces() {
      return this.$store.state.user.limit
    }
  }
};
</script>
<style  scoped>
.pagination {
  padding: 20px 20px 20px 0;

  .right {
    font-size: 14px;
    color: #333;
  }
}
</style>
