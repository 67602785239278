<template>
    <div>
        <el-card class="box-card">
            <el-form ref="form" :model="form" label-width="150px">
                <el-form-item label="公司名称">
                    {{ companyName }}
                </el-form-item>
                <el-form-item label="新注册办事处地址">
                    <el-input v-model="companyAddress.region" style="width: 200px;"
                        :placeholder="$t('basic.txt26')"></el-input>
                    <el-input v-model="companyAddress.state" style="width: 200px;"
                        :placeholder="$t('basic.txt27')"></el-input>
                    <el-input v-model="companyAddress.street" style="width: 200px;"
                        :placeholder="$t('basic.txt28')"></el-input>
                    <el-input v-model="companyAddress.building" style="width: 200px;"
                        :placeholder="$t('basic.txt29')"></el-input>
                    <el-input v-model="companyAddress.address" style="width: 200px;"
                        :placeholder="$t('basic.txt30')"></el-input>
                </el-form-item>
                <el-form-item label="生效日期" prop="date1">
                    <el-date-picker type="date" placeholder="选择日期" v-model="date1" style="width: 100%;"
                        value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item label="新电邮地址">
                    <el-input v-model="companyEmail"></el-input>
                </el-form-item>
                <el-form-item label="生效日期" prop="date2">
                    <el-date-picker type="date" placeholder="选择日期" v-model="date2" style="width: 100%;"
                        value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="签署姓名">
                    <span v-if="show_signName">{{ this.select_name }}</span>
                    <el-radio-group v-model="select_name" v-if="!show_signName">
                        <el-radio v-for="(v, i) in directors" :key="i" :label="v.nameC"></el-radio><br>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="">
                    <submitor_select @selectSubmit="selectSubmit" ref="submitor" :submitor="submitor" v-show="show_btn"
                        :show_btn="show_btn">
                    </submitor_select>
                </el-form-item>

                <el-form-item v-if="show_btn == true">
                    <el-button type="primary" @click="audit_submit">{{ $t('subAud') }}</el-button>
                    <el-button @click="handleCancel">{{ $t('cancel') }}</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import * as api from "@/api/api"
import submitor_select from "../submitor/submitor_select.vue"
export default {
    props: {
        form_id: Number,
        c_id: Number,
        companyName: String,
        companyNameE: String
    },
    components: {
        submitor_select
    },
    watch: {

        'form_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {
                    console.log(newVal)
                    this.get_data_form(newVal)
                    this.show_btn = false
                }


            },
            deep: true
        },
        'c_id': {
            immediate: true,
            handler(newVal) {
                if (newVal != undefined && newVal != "") {

                    this.get_data(newVal)



                }

            },
            deep: true
        },
    },
    data() {
        return {
            date1: '',
            date2: '',
            date3: "",
            form: {},
            submitor: {},
            show_btn: true,
            show_signName: false,
            companyAddress: {
                region: "",
                state: "",
                street: "",
                building: "",
                address: ""
            },
            companyEmail: ""
        }
    },
    created() {
        // this.form = this.company_info

    },
    methods: {
        get_data_form(id) {

            // let id = this.$route.query.id

            var params = { id: id }
            api.getRequest('/nr1',
                params).then(resp => {
                    console.log(resp.data)
                    this.companyAddress = resp.data.companyAddress[0]
                    this.companyEmail = resp.data.companyEmail
                    this.date1 = resp.data.date1
                    this.date2 = resp.data.date2


                })
        },


        ///获取公司资料
        get_data(id) {



            var params = {}
            api.getRequest('/company/v2/' + id,
                params).then(resp => {
                    this.form = resp.data
                    // this.companyAddress = resp.data.companyAddress[0]
                    this.show_btn = true
                })
        },

        audit_submit() {
            var newCompanyAddress = []
            newCompanyAddress.push(this.companyAddress)
            var hkNr1 = {
                companyAddress: newCompanyAddress,
                companyEmail: this.companyEmail,
                companyCode: this.form.companyCode,
                date1: this.date1,
                date2: this.date2,
                companyId: this.form.id,
                auditStatus: 0,
                submitBy: sessionStorage.getItem("adminKey"),
                submitUser: sessionStorage.getItem("realName"),
            }
            console.log("nr1表单" + JSON.stringify(hkNr1))

            api.postRequest('/nr1',
                hkNr1).then(resp => {
                    console.log(resp)
                    if (resp.data !== -1) {

                        var audit = {
                            "auditBy": "",
                            "auditStatus": status,
                            "auditTime": "",
                            "formId": resp.data,
                            "auditUser": "",
                            "companyId": this.form.id,
                            "companyName": this.form.companyName[0],
                            "companyNameE": this.form.companyNameE[0],
                            "formType": "NR1",
                            "remark": "",
                            "submitor": this.submitor,
                            "submitBy": sessionStorage.getItem("adminKey"),
                            "submitUser": sessionStorage.getItem("realName"),
                        }
                        api.postRequest('/audit',
                            audit).then(resp => {
                                if (resp.data == 1) {
                                    this.$message.success(resp.message)
                                    console.log("nr1表单" + hkNr1)

                                }
                                else {
                                    this.$message.error(resp.message)
                                }

                            })
                    }
                    else {
                        this.$message.error(resp.message)
                    }

                })


        },
        selectSubmit(val) {
            this.submitor = val
            this.submitor.submitType = 2
        },

        handleCancel() {
            this.$refs['form'].resetFields();
            this.$emit('Cancel', false)
        },
    }
}
</script>