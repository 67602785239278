<template>
    <div>

        <el-table :data="pics">
            <el-table-column prop="area" :label="$t('fileTitle1')">
                <template v-slot="scope">

                    <a :href="serverUrl + scope.row.fileUrl" target="_blank"><el-image :src="serverUrl + scope.row.fileUrl"
                            style="width: 100px;" v-if="is_pic_file(scope.row.fileType)">

                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image><br>{{ scope.row.fileName }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="idNumber" :label="$t('fileTitle2')">
                <template v-slot="scope">
                    <el-date-picker v-model="scope.row.expirationTime" value-format="yyyy-MM-dd" type="date"
                        :placeholder="$t('fileTitle4')" v-if="scope.row.approve == 0">
                    </el-date-picker>
                    <el-tag type="danger" v-if="scope.row.approve == 1">附件到期日期: {{ scope.row.expirationTime }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('action')">
                <template v-slot="scope">
                    <el-button size="small" type="danger" @click="onDel(scope.$index, scope.row)"
                        v-if="scope.row.approve == 0">{{
                            $t('del') }}</el-button>
                    <el-button class="table-btn" type="success" size="small" @click="audit_submit(1, scope.row)"
                        v-if="scope.row.approve == 0">
                        {{ $t('apBtn') }}</el-button>
                    <el-tag v-if="scope.row.approve == 1">已批核</el-tag>
                </template>
            </el-table-column></el-table>

        <div>

            <upFile @upFileName="showpicVal" :showpicEdit="showpicEdit" :total="10" :fileType="3" :showList="false">
            </upFile>
        </div>
    </div>
</template>
<script>
import upFile from "@/components/up_file_2.vue";
export default {
    props: {
        'picUrl_get': {
            type: Array,
            Default: []
        },

    },
    watch: {
        'picUrl_get': {
            immediate: true,
            handler(newVal) {
                console.log("here" + newVal)
                if (newVal != undefined) {
                    this.pics = newVal
                }

            },
            deep: true
        },

    },
    data() {
        return {
            re_upload: true,
            editting: false,
            picUrl: "",
            showpicEdit: [],
            serverUrl: this.$root.serverUrl,
            formLabelWidth: "120px",
            showPic: false,
            pics: [],
            isPic: false


        }
    },
    components: { upFile },
    methods: {
        reUpload() {
            this.re_upload = true
        },
        showpicVal(data) {
            this.pics.push(data)
            console.log(this.pics)
            this.$emit('upFileName', this.pics);
        },
        is_pic_file(data) {
            if (data.toLowerCase() == "png" || data.toLowerCase() == "jpg" || data.toLowerCase() == "jpeg" || data.toLowerCase() == "gif") {
                return true
            }
            else {
                return false
            }
        },
        onDel(index, item) {
            console.log(index)

            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {


                let olddata = this.pics;
                olddata.splice(index, 1);
                this.pics = olddata;

            }).catch(() => { });
        },
        audit_submit(set, row) {
            row.approve = set
            row.approveBy = sessionStorage.getItem("realName")
            console.log(row)
        }
    }
}
</script>