<template>
    <div>
        <el-card>

            <FormTabel :config="type == 2 ? basiConfig : naturalConfig" />

            <div class="table">

                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span><span class="must">*</span>{{ $t('staff.addMan') }}:</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="onAdd">{{ $t('staff.addAdd')
                            }}</el-button>
                    </div>

                    <el-table :data="detail.companyAddress" :empty-text="$t('noRes')">
                        <el-table-column prop="region" :label="$t('basic.txt26')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.region" :placeholder="$t('basic.txt26')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="state" :label="$t('basic.txt271')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.state" :placeholder="$t('basic.txt27')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="street" :label="$t('basic.txt28')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.street" :placeholder="$t('basic.txt28')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="building" :label="$t('basic.txt29')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.building" :placeholder="$t('basic.txt29')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" :label="$t('basic.txt301')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.address" :placeholder="$t('basic.txt30')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('action')">
                            <template v-slot="scope">
                                <el-button size="mini" type="danger" @click="onDel(scope.$index, scope.row)">{{
                                    $t('del')
                                }}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>


            </div>
            <div class="table" v-if="type == 1">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span><span class="must">*</span>{{ $t('staff.idMan') }}:</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="onAddIdCard">{{
                            $t('staff.addId') }}</el-button>
                    </div>
                    <el-table :data="detail.idNumbers" :empty-text="$t('noRes')">


                        <el-table-column prop="area" :label="$t('staff.idSection')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.area" autocomplete="off"
                                    :placeholder="$t('staff.selCountry')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="idNumber" :label="$t('staff.idNum')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.idNumber" autocomplete="off"
                                    :placeholder="$t('staff.idNum')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('action')">
                            <template v-slot="scope">
                                <el-button size="mini" type="danger" @click="onDelId(scope.$index, scope.row)">{{
                                    $t('del')
                                }}</el-button>
                            </template>
                        </el-table-column></el-table>
                </el-card>
            </div>

            <div class="table" v-if="type == 1">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span><span class="must">*</span>{{ $t('staff.passPort') }}:</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="onAddIdPass">{{
                            $t('staff.addPass') }}</el-button>
                    </div>
                    <el-table :data="detail.passports" :empty-text="$t('noRes')">

                        <el-table-column prop="country" :label="$t('staff.idSection')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.country" autocomplete="off"
                                    :placeholder="$t('staff.selCountry')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="passport" :label="$t('staff.pasNum')">
                            <template v-slot="scope">
                                <el-input v-model="scope.row.passport" autocomplete="off"
                                    :placeholder="$t('staff.pasNum')"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('action')">
                            <template v-slot="scope">
                                <el-button size="mini" type="danger" @click="onDelPassport(scope.$index, scope.row)">{{
                                    $t('del') }}</el-button>
                            </template>
                        </el-table-column></el-table>

                </el-card>
            </div>

            <found ref="found" :is_edit="true" v-show="type == 2" @update_price="update_price" />
            <director ref="director" :is_edit="true" v-show="type == 2" />
            <secretary ref="secretary" :is_edit="true" v-show="type == 2" />
            <controller ref="controller" :is_edit="true" v-show="type == 2"></controller>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>{{ $t('staff.idCopy') }}:</span>

                </div>
                <single_file :picUrl_get="detail.files1" @upFileName="showpicVal"></single_file>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>{{ $t('staff.addProve') }}:</span>

                </div>
                <single_file :picUrl_get="detail.files2" @upFileName="showpicVal1"></single_file>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>{{ $t('staff.foundProve') }}:</span>

                </div>
                <single_file :picUrl_get="detail.files3" @upFileName="showpicVal2"></single_file>
            </el-card>
        </el-card>
        <div slot="footer" class="dialog-footer" style="text-align: right;">
            <el-button @click="handleCancel"> {{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="submitForm" v-if="type == 1">{{ $t('save') }}</el-button>
            <el-button type="primary" @click="addCompany" v-if="type == 2">{{ $t('save') }}</el-button>
        </div>
    </div>
</template>
<script>
import * as api from "../../api/api"
import FormTabel from '@/components/form/form.vue'
import single_file from "@/components/single_file.vue";
import found from "@/function/hk/compoemts/found/index.vue"
import director from "@/function/hk/compoemts/found/director.vue";
import secretary from "@/function/hk/compoemts/found/secretary.vue"
import controller from "@/function/hk/compoemts/found/controllers.vue"
import { newAddress } from "@/api/individual"
import { humanMember, legalMember } from "@/api/corporate"
export default {
    created() {

        console.log("000" + this.detail)
        console.log(this.type)

    },
    mounted() {


    },
    components: {
        FormTabel,
        found, director, secretary, controller, single_file

    },
    props: {
        'editting': {
            type: Boolean,
            Default: false
        },
        'detail': {
            type: Object,
            Default: {}
        },
        'type': {
            type: Number,
            default: 1
        }
    },
    watch: {
        'detail': {
            immediate: true,
            handler(newVal) {
                // console.log("wathc" + JSON.stringify(newVal))
                if (newVal.id != 0) {
                    this.edit_id = newVal.id

                    if (this.type == 1) {
                        this.naturalConfig.list.map(d => {
                            d.value = this.detail[d.key]
                        })
                        this.address = newVal.address
                    }
                    if (this.type == 2) {
                        this.basiConfig.list.map(d => {
                            d.value = this.detail[d.key]
                        })

                        this.$nextTick(() => {

                            this.$refs.found.found1 = this.detail.members1
                            this.$refs.found.found2 = this.detail.members2
                            this.$refs.director.found2 = this.detail.members4
                            this.$refs.director.found1 = this.detail.members3
                            this.$refs.secretary.found1 = this.detail.members5
                            this.$refs.secretary.found2 = this.detail.members6
                            this.$refs.controller.controller = this.detail.controller

                            this.$refs.found.priceOrdinary = this.detail.priceOrdinary
                        })

                    }
                }

            },
            deep: true
        },

    },
    data() {
        return {
            pics: [],
            pics1: [],
            pics2: [],
            files1: [],
            files2: [],
            files3: [],
            edit_id: "",
            price_package: {
                priceOrdinary: 1,
                pricePre: 1,
                currencyOrdinary: "HKD",
                currencyPre: "HKD",
            },
            legalConfig: {

                title: '法人',
                labelWidth: '120px',
                fatherId: 0,
                list: [
                    {
                        title: this.$t('staff.nameC'),//中文名',
                        key: 'nameC',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: this.$t('staff.nameE2'),
                        key: 'nameE1',
                        required: true,
                        type: 'input',
                        value: '',
                    },

                    {
                        title: this.$t('staff.isHkCom'),
                        key: 'isHkComp',
                        type: 'select',
                        value: 1,
                        options: [
                            { title: this.$t('yes'), id: 1 },
                            { title: this.$t('no'), id: 0 },
                        ]
                    },
                    {
                        title: this.$t('company.companyCode'),
                        key: 'code',
                        type: 'input',
                        value: '',
                    },
                ]
            },
            basiConfig: {
                title: '基本信息',
                labelWidth: '120px',
                list: [
                    {
                        title: '公司中文名',
                        key: 'companyName',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: '公司英文名',
                        key: 'companyNameE',
                        type: 'input',
                        required: true,
                        value: ''
                    },

                    {
                        title: '業務性質',
                        key: 'busModel',
                        type: 'select',
                        value: [],
                        required: true,
                        options: [
                            { title: '擁有不動產', id: 1 },
                            { title: '持有私人公司股份', id: 2 },
                            { title: '持有金融商品等相關投資', id: 3 },
                            { title: '擁有船舶/飛機 - 請於下方填寫其註冊地點) (持有金融商品等相關投資', id: 4 },
                            { title: '貿易 - 請於下方填寫其交易之產品', id: 5 },
                            { title: '顧問服務', id: 6 },
                            { title: '其他', id: 7 },

                        ]
                    },

                    {
                        title: '公司类别',
                        key: 'companyType',
                        type: 'select',
                        value: '',
                        required: true,
                        options: [
                            { title: '私人', id: 1 },
                            { title: '公众', id: 2 },
                        ]
                    },

                    {
                        title: '企业邮箱',
                        key: 'companyEmail',
                        type: 'input',
                        value: ''
                    },

                    {
                        title: '公司编号',
                        key: 'companyCode',
                        type: 'input',
                        required: false,
                        value: ''
                    },
                    {
                        title: '商业登记证',
                        key: 'certificate',
                        type: 'input',
                        required: false,
                        value: ''
                    },

                    {
                        title: '备注(内部使用)',
                        key: 'remark',
                        type: 'textarea',
                        required: false,
                        value: ''
                    },

                ]

            },
            naturalConfig: {
                title: '自然人',
                labelWidth: '120px',
                list: [
                    {
                        title: this.$t('staff.nameC'),
                        key: 'nameC',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: this.$t('staff.exCName'),//'曾用中文名',
                        key: 'exCName',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: this.$t('staff.otherCName'), //'中文别名',
                        key: 'otherCName',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: this.$t('staff.nameE1'),
                        key: 'nameE1',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: this.$t('staff.nameE2'),
                        key: 'nameE2',
                        type: 'input',
                        value: '',
                    },

                    {
                        title: this.$t('staff.exEName'), //'曾用英文名',
                        key: 'exEName',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: this.$t('staff.otherEName'), // '英文别名',
                        key: 'otherEName',
                        type: 'input',
                        value: '',
                    },
                    {
                        title: '邮箱',
                        key: 'email',
                        type: 'input',
                        value: ''
                    },

                ]
            },
            options: [
                { title: '中国', id: '中国' },
                { title: '香港', id: '香港' },
                { title: '澳门', id: '澳门' },
                { title: '台湾', id: '台湾' },
                { title: '其他', id: '其他' },
            ],
            address: [],
            id_cards: [],
            passports: [],
            emails: [],
            // detail: {},
            title: [
                {
                    id: 4,
                    prop: 'region',
                    label: this.$t('basic.txt26'),//'国家地區',
                    align: 'center',
                    template: true
                },
                {
                    id: 5,
                    prop: 'state',
                    label: this.$t('basic.txt271'),//'"區／市／省／州／郵遞區號等"
                    align: 'center',
                    template: true
                },
                {
                    id: 6,
                    prop: 'street',
                    label: this.$t('basic.txt28'),// "街道／屋苑／地段／村等"
                    align: 'center',
                    template: true
                },
                {
                    id: 7,
                    prop: 'building',
                    label: this.$t('basic.txt29'),//'大廈',
                    align: 'center',
                    template: true
                },


                {
                    id: 3,
                    prop: 'address',
                    label: this.$t('basic.txt301'), //'详细地址',
                    align: 'center',
                    template: true
                },
                {
                    id: 12,
                    prop: 'edit',
                    label: this.$t('action'),//'操作',
                    align: 'center',
                    width: '120px',
                    template: true
                }
            ],
        }
    },
    methods: {
        onAdd() {
            let obj = newAddress()
            console.log(obj)
            // eslint-disable-next-line vue/no-mutating-props
            this.detail.companyAddress.push(obj)

        },
        onDel(index) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // eslint-disable-next-line vue/no-mutating-props
                this.detail.companyAddress.splice(index, 1);
            }).catch(() => { });
        },

        onDelId(index) {

            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // eslint-disable-next-line vue/no-mutating-props
                this.detail.idNumbers.splice(index, 1);

            }).catch(() => { });
        },


        onDelPassport(index, item) {

            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // eslint-disable-next-line vue/no-mutating-props
                this.detail.passports.splice(index, 1);
            }).catch(() => { });
        },

        onAddIdCard() {
            let obj = { fatherId: this.fatherId }

            // eslint-disable-next-line vue/no-mutating-props
            this.detail.idNumbers.push(obj)


        },
        onAddIdPass() {
            let obj = { fatherId: this.fatherId }

            // eslint-disable-next-line vue/no-mutating-props
            this.detail.passports.push(obj)
            console.log(this.passports)

        },
        handleCancel(val) {

            this.$emit("saved", false)

        },
        submitForm() {

            let obj = {
                ...this.detail,
                // address: this.address,
                // idNumbers: this.id_cards,
                // passports: this.passports
            }

            if (this.type == 2) {
                this.legalConfig.list.map(d => {
                    obj[d.key] = d.value
                })
            } else {
                this.naturalConfig.list.map(d => {
                    obj[d.key] = d.value
                })
            }

            console.log(obj)


            // obj.files1 = this.files1
            // obj.files2 = this.files2
            // obj.files3 = this.files3
            console.log("submit" + obj)
            if (this.editting == false) {
                api.postRequest('/seniorStaffs', obj).then(resp => {
                    console.log(resp)
                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.$emit("saved", true)
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            } if (this.editting == true) {
                api.putRequest('/seniorStaffs', obj).then(resp => {
                    console.log(resp)
                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.$emit("saved", true)
                    } else {
                        this.$message.error(resp.message);
                    }
                })

            }



        },
        showpicVal(data) {
            console.log("uppic" + data)
            this.files1 = data
            console.log(this.files1)
        },
        showpicVal1(data) {
            console.log("uppic" + data)
            this.pics1 = data
        },
        showpicVal2(data) {
            console.log("uppic" + data)
            this.pics2 = data
        },
        update_price(item) {
            console.log(item)
            this.price_package = item

        },
        // 添加法人
        addCompany() {
            console.log(this.detail.address)
            var company_detail = {}
            this.basiConfig.list.map(d => {
                company_detail[d.key] = d.value
            })
            var companyName = []
            companyName.push(company_detail.companyName)
            company_detail.companyName = companyName

            var companyNameE = []
            companyNameE.push(company_detail.companyNameE)
            company_detail.companyNameE = companyNameE

            var companyEmail = []
            companyEmail.push(company_detail.companyEmail)
            company_detail.companyEmail = companyEmail

            var found_member = []
            // 创办人及高级人员 -- 自然人
            if (this.$refs.found.found1 != undefined) {
                found_member = this.$refs.found.found1
            }

            // 创办人及高级人员法人
            var legalPersonFounder = []
            if (this.$refs.found.found2 != undefined) {
                legalPersonFounder = this.$refs.found.found2
            }



            // 董事 -- 自然人
            var director_human = []
            if (this.$refs.director.found1 != undefined) {
                director_human = this.$refs.director.found1
            }

            // 董事 -- 法人
            var director_legal = []
            if (this.$refs.director.found2 != undefined) {
                director_legal = this.$refs.director.found2
            }

            // 秘书 --  自然人
            var secretary_human = []
            if (this.$refs.secretary.found1 != undefined) {
                secretary_human = this.$refs.secretary.found1
            }

            // 秘书 --  法人
            var secretary_legal = []
            if (this.$refs.secretary.found2 != undefined) {
                secretary_legal = this.$refs.secretary.found2
            }

            let data = {
                members1: humanMember(found_member),
                members2: legalMember(legalPersonFounder),
                members3: humanMember(director_human),
                members4: legalMember(director_legal),
                members5: humanMember(secretary_human),
                members6: legalMember(secretary_legal),
            }


            company_detail.applyStatus = 11  ///标记11 是公司资料库的公司

            company_detail.members1 = data.members1
            company_detail.members2 = data.members2
            company_detail.members3 = data.members3
            company_detail.members4 = data.members4
            company_detail.members5 = data.members5
            company_detail.members6 = data.members6
            company_detail.companyAddress = this.detail.companyAddress
            company_detail.controller = this.$refs.controller.controller
            company_detail.priceOrdinary = this.price_package.priceOrdinary
            company_detail.pricePre = this.price_package.pricePre
            company_detail.currencyOrdinary = this.price_package.currencyOrdinary
            company_detail.currencyPre = this.price_package.currencyPre
            company_detail.files1 = this.files1
            company_detail.files2 = this.files2
            company_detail.files3 = this.files3
            console.log(company_detail)
            if (this.editting == false) {
                api.postRequest('/company/v2', company_detail).then(resp => {
                    // console.log(resp)
                    if (resp.data == 1) {

                        this.$message.success(resp.message);
                        this.$emit("saved", true)
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            } if (this.editting == true) {
                company_detail.id = this.edit_id
                api.putRequest('/company/v2', company_detail).then(resp => {
                    // console.log(resp)
                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.$emit("saved", true)
                    } else {
                        this.$message.error(resp.message);
                    }
                })

            }
        }

    }

}
</script>
