<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>{{ $t("company.basic") }}</span>
      </div>

      <el-form ref="form" :model="basiConfig1" label-position="top" size="small">
        <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item :label="$t('basic.txt1')">
              {{ basiConfig1.companyName[0] }}

            </el-form-item>

            <el-form-item :label="$t('basic.txt4')">
              {{ basiConfig1.companyNameE[0] }}
            </el-form-item>

            <el-form-item :label="$t('basic.txt5')">
              <el-select v-model="basiConfig1.busModel" :placeholder="$t('company.basic-t1')" style="width: 300px;"
                disabled>
                <el-option v-for="(v, idx) in busModel" :key="idx" :label="v.title" :value="v.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('basic.txt7')">
              <el-select v-model="basiConfig1.companyType" :placeholder="$t('company.basic-t1')" style="width: 300px;"
                disabled>
                <el-option v-for="(v) in companyType" :label="v.title" :value="v.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('basic.txt6')">
              <el-input v-model="basiConfig1.addition" style="width: 300px;"></el-input>
            </el-form-item>
            <el-form-item :label="$t('basic.txt15')">
              <el-input v-model="basiConfig1.ordinary" style="width: 400px;" type="textarea" :rows="4"></el-input>
            </el-form-item>
            <el-form-item :label="$t('basic.txt14')">
              <el-input v-model="basiConfig1.preference" style="width: 400px;" type="textarea" :rows="4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item :label="$t('basic.txt8')">
              {{ basiConfig1.companyEmail[0] }}

            </el-form-item>

            <el-form-item :label="$t('basic.txt11')">
              <!-- 公司地址 -->
              <el-select v-model="basiConfig1.agency" :placeholder="$t('company.basic-t1')" style="width: 300px;" disabled
                @change="show_rs">
                <el-option v-for="(v) in agency" :label="v.title" :value="v.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('basic.txt16')">
              {{ basiConfig1.companyAddress }}

            </el-form-item>

            <el-form-item :label="$t('basic.txt12')">
              <el-select v-model="basiConfig1.keeping" :placeholder="$t('company.basic-t1')" style="width: 300px;"
                disabled>
                <el-option v-for="(v) in keeping" :label="v.title" :value="v.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('basic.txt13')">
              <el-input v-model="basiConfig1.remark" style="width: 400px;" type="textarea" :rows="4"></el-input>
            </el-form-item>
            <el-form-item :label="$t('basic.txt24')"><el-input v-model="basiConfig1.companyCode"
                style="width: 400px;"></el-input></el-form-item>
            <el-form-item :label="$t('basic.txt25')"><el-input v-model="basiConfig1.certificate"
                style="width: 400px;"></el-input></el-form-item>

          </el-col></el-row>
      </el-form>


    </el-card>

  </div>
</template>

<script>
import FormTabel from '@/components/form/form.vue'
export default {
  components: {
    FormTabel
  },

  data() {
    return {
      // 基本信息
      basiConfigX: {},
      addConfigX: {},
      addressConfigX: {},
      codeConfigX: {},
      stockConfigX: {},
      companyName: "",
      companyNameE: "",
      companyEmail: "",
      companyAddress: {
        add1: "",
        add2: "",
        add3: ""
      },
      basiConfig1: {
        "id": "",
        "companyName": [],
        "companyNameE": [],
        "companyAddress": [],
        "busModel": "",
        "addition": "",
        "agency": 1,
        "keeping": 1,
        "companyType": 1,
        "companyCode": "",
        "certificate": "",
        "companyEmail": [],
        "isDelete": 0,
        "infoType": 0,
        "applyStatus": 0,
        "remark": "",
        "preference": "",
        "ordinary": "",
        "currencyOrdinary": "",
        "currencyPre": "",
        "priceOrdinary": "",
        "pricePre": "",
        "members1": [],
        "members2": [],
        "members3": [],
        "members4": [],
        "members5": [],
        "members6": []
      },
      busModel: [
        { title: '擁有不動產', id: 1 },
        { title: '持有私人公司股份', id: 2 },
        { title: '持有金融商品等相關投資', id: 3 },
        { title: '擁有船舶/飛機 - 請於下方填寫其註冊地點) (持有金融商品等相關投資', id: 4 },
        { title: '貿易 - 請於下方填寫其交易之產品', id: 5 },
        { title: '顧問服務', id: 6 },
        { title: '其他', id: 7 },

      ],
      companyType: [
        { title: '私人', id: 1 },
        { title: '公众', id: 2 },
      ],
      agency: [
        { title: '採用德力管理有限公司提供的地址', id: 1 },
        { title: '其他地址', id: 2 },
      ],
      keeping: [
        { title: '註冊辦事處', id: 1 },
        { title: '其他地方', id: 2 },
      ],
      section: [
        { title: '香港', id: '香港' },
      ],
      basiConfig: {
        title: '基本信息',
        labelWidth: '120px',
        list: [
          {
            title: '公司中文名（選擇一）',
            key: 'companyName',
            type: 'input',
            value: '',
          },
          {
            title: '公司英文名（Option 1）',
            key: 'companyNameE',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: '公司中文名（選擇二）',
            key: 'companyName1',
            type: 'input',
            value: '',
          },
          {
            title: '公司英文名（Option 2）',
            key: 'companyNameE1',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: '業務性質',
            key: 'busModel',
            type: 'select',
            value: [],
            required: true,
            options: [
              { title: '擁有不動產', id: 1 },
              { title: '持有私人公司股份', id: 2 },
              { title: '持有金融商品等相關投資', id: 3 },
              { title: '擁有船舶/飛機 - 請於下方填寫其註冊地點) (持有金融商品等相關投資', id: 4 },
              { title: '貿易 - 請於下方填寫其交易之產品', id: 5 },
              { title: '顧問服務', id: 6 },
              { title: '其他', id: 7 },

            ]
          },
          {
            title: '請提供進一步的業務資料, 如產品/服務及銷售國家…等',
            key: 'addition',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: '公司类别',
            key: 'companyType',
            type: 'select',
            value: '',
            required: true,
            options: [
              { title: '私人', id: 1 },
              { title: '公众', id: 2 },
            ]
          },

          {
            title: '企业邮箱',
            key: 'companyEmail',
            type: 'input',
            value: ''
          },


          {
            title: '备注(内部使用)',
            key: 'remark',
            type: 'textarea',
            required: false,
            value: ''
          },

        ]

      },
      basiConfigEn: {
        title: '基本信息',
        labelWidth: '120px',
        list: [
          {
            title: 'Company Chinese name (Option 1)',
            key: 'companyName',
            type: 'input',
            value: '',
          },
          {
            title: 'Company Englist name (Option 1)',
            key: 'companyNameE',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: 'Company Chinese name (Option 2)',
            key: 'companyName1',
            type: 'input',
            value: '',
          },
          {
            title: 'Company Englist name (Option 2)',
            key: 'companyNameE1',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: 'Business Nature',
            key: 'busModel',
            type: 'select',
            value: [],
            required: true,
            options: [
              { title: 'Immovable property', id: 1 },
              { title: 'Holding shares in private companies', id: 2 },
              { title: 'Holding financial commodities and other related investments', id: 3 },
              { title: 'Ownership of ships/aircraft - please fill in their registered location below) (Holding related investments such as financial commodities', id: 4 },
              { title: 'Trade - Please fill in the products they trade below', id: 5 },
              { title: 'Counseling', id: 6 },
              { title: 'Other', id: 7 },

            ]
          },
          {
            title: 'Please provide further business information such as products/services and countries of sale... Etc.',
            key: 'addition',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: 'Company Type',
            key: 'companyType',
            type: 'select',
            value: '',
            required: true,
            options: [
              { title: 'private', id: 1 },
              { title: 'public', id: 2 },
            ]
          },

          {
            title: 'company Email',
            key: 'companyEmail',
            type: 'input',
            value: ''
          },


          {
            title: 'Remark',
            key: 'remark',
            type: 'textarea',
            required: false,
            value: ''
          },

        ]

      },
      addConfig: {
        title: '基本信息',
        labelWidth: '120px',
        list: [

          {
            title: '註冊辦事處',
            key: 'agency',
            type: 'select',
            value: '',
            required: true,
            options: [
              { title: '採用德力管理有限公司提供的地址', id: 1 },
              { title: '其他地址', id: 2 },
            ]
          },
          {
            title: '公司記錄的存放',
            key: 'keeping',
            type: 'select',
            value: '',
            required: true,
            options: [
              { title: '註冊辦事處', id: 1 },
              { title: '其他地方', id: 2 },
            ]
          },



        ]

      },

      addConfigEn: {
        title: '基本信息',
        labelWidth: '120px',
        list: [

          {
            title: 'Registered office',
            key: 'agency',
            type: 'select',
            value: '',
            required: true,
            options: [
              { title: 'Use the address provided by Deli Management Limited', id: 1 },
              { title: 'Other address', id: 2 },
            ]
          },
          {
            title: 'Keeping of company records',
            key: 'keeping',
            type: 'select',
            value: '',
            required: true,
            options: [
              { title: 'Registered office', id: 1 },
              { title: 'Somewhere else', id: 2 },
            ]
          },



        ]

      },

      addressConfig: {
        title: '基本信息',
        labelWidth: '120px',
        list: [

          {
            title: '地區',
            key: 'companyAddr1',
            type: 'select',
            value: '香港',
            options: [
              { title: '香港', id: '香港' },
            ]
          },
          {
            title: '鄉村/市鎮',
            key: 'companyAddr2',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: '详细地址',
            key: 'companyAddr3',
            type: 'input',
            required: true,
            value: ''
          },



        ]

      },
      addressConfigEn: {
        title: '基本信息',
        labelWidth: '120px',
        list: [

          {
            title: 'Area',
            key: 'companyAddr1',
            type: 'select',
            value: 'HongKong',
            options: [
              { title: 'HongKong', id: 'HongKong' },
            ]
          },
          {
            title: 'Country/Town',
            key: 'companyAddr2',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: 'Address',
            key: 'companyAddr3',
            type: 'input',
            required: true,
            value: ''
          },



        ]

      },
      codeConfig: {
        title: '基本信息',
        labelWidth: '120px',
        list: [

          {
            title: '公司编号',
            key: 'companyCode',
            type: 'input',
            required: false,
            value: ''
          },
          {
            title: '商业登记证',
            key: 'certificate',
            type: 'input',
            required: false,
            value: ''
          },



        ]

      },
      codeConfigEn: {
        title: '基本信息',
        labelWidth: '120px',
        list: [

          {
            title: 'Company Code',
            key: 'companyCode',
            type: 'input',
            required: false,
            value: ''
          },
          {
            title: 'Certificate',
            key: 'certificate',
            type: 'input',
            required: false,
            value: ''
          },



        ]

      },
      stockConfig: {
        title: '股权情况',
        labelWidth: '120px',
        list: [
          {
            title: '优先股附帶的權利的詳情',
            key: 'preference',
            type: 'textarea',
            span: 12,
            value: ''
          },
          {
            title: '普通股附帶的權利的詳情',
            key: 'ordinary',
            type: 'textarea',
            span: 12,
            value: ''
          },
        ]
      },
      stockConfigEn: {
        title: '股权情况',
        labelWidth: '120px',
        list: [
          {
            title: 'Details of the rights attached to the preferred stock',
            key: 'preference',
            type: 'textarea',
            span: 12,
            value: ''
          },
          {
            title: 'Details of rights attached to common shares',
            key: 'ordinary',
            type: 'textarea',
            span: 12,
            value: ''
          },
        ]
      },
      showAdd: false,
      showNum: false,

    }
  },
  created() {

    if (localStorage.getItem("lang") == "en") {
      this.basiConfigX = this.basiConfigEn
      this.addressConfigX = this.addressConfigEn
      this.addConfigX = this.addConfigEn
      this.codeConfigX = this.codeConfigEn
      this.stockConfigX = this.stockConfigEn
    }
    if (localStorage.getItem("lang") == "cn") {
      this.basiConfigX = this.basiConfig
      this.addressConfigX = this.addressConfig
      this.addConfigX = this.addConfig
      this.codeConfigX = this.codeConfig
      this.stockConfigX = this.stockConfig
    }

  },
  mounted() {


  },
  watch: {
    "$i18n.locale"() {
      console.log("basic" + localStorage.getItem("lang"))

      if (localStorage.getItem("lang") == "en") {
        this.basiConfigX = this.basiConfigEn
        this.addressConfigX = this.addressConfigEn
        this.addConfigX = this.addConfigEn
        this.codeConfigX = this.codeConfigEn
        this.stockConfigX = this.stockConfigEn
      }
      if (localStorage.getItem("lang") == "cn") {
        this.basiConfigX = this.basiConfig
        this.addressConfigX = this.addressConfig
        this.addConfigX = this.addConfig
        this.codeConfigX = this.codeConfig
        this.stockConfigX = this.stockConfig
      }


    }
  },
  methods: {
    show_rs(val) {
      console.log(val)
      if (val == 1) {
        this.showAdd = false
      }
      else {
        this.showAdd = true
      }
    },
    add_companyName(item) {
      if (item.length > 2) {
        this.basiConfig1.companyName.push(item)
      }
      else {
        this.$message.error(this.$t("basic.txt3"))
      }

    },
    add_companyNameE(item) {
      if (item.length > 2) {
        this.basiConfig1.companyNameE.push(item)
      }
      else {
        this.$message.error(this.$t("basic.txt3"))
      }

    },
    add_companyEmail(item) {
      if (item.length > 2) {
        this.basiConfig1.companyEmail.push(item)
      }
      else {
        this.$message.error(this.$t("basic.txt3"))
      }

    },
    add_companyAddress(item) {

      if (item.add2.length > 4 && item.add3.length > 4) {
        this.basiConfig1.companyAddress.push(item)
        this.companyAddress = {}
      }
      else {
        this.$message.error(this.$t("basic.txt3"))
      }

    },
    delete_companyName(index) {
      this.basiConfig1.companyName.splice(index, 1)
    },
    delete_companyNameE(index) {
      this.basiConfig1.companyNameE.splice(index, 1)
    },
    delete_companyEmail(index) {
      this.basiConfig1.companyEmail.splice(index, 1)
    },
    delete_companyAddress(index) {
      this.basiConfig1.companyAddress.splice(index, 1)
    }
  }


}
</script>

<style  scoped>
.header-btn {
  padding-bottom: 10px;
}

.el-card {
  margin-bottom: 20px;
}
</style>
