<template>
  <div>
    <el-table ref="multipleTable" :data="tableData" border :header-cell-style="style" :cell-style="titleStyle"
      :empty-text="$t('noRes')" @cell-click="getDetail" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="50" v-if="selection"></el-table-column>
      <template v-for="item in title">


        <el-table-column v-if="item.template" :key="item.id" :label="item.label" :width="item.width" :align="item.align"
          :fixed="item.fixed">
          <template v-slot="scope">
            <slot :scope="scope.row" :name="item.prop"></slot>
          </template>
        </el-table-column>

        <el-table-column v-else :key="item.id" :prop="item.prop" :label="item.label" :width="item.width"
          :align="item.align" :sortable="item.sortable" :fixed="item.fixed">
        </el-table-column>

      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: Array,
      default: null
    },
    tableData: {
      type: Array,
      default: null
    },
    control: {
      type: Array,
      default: null
    },
    //是否开启选择
    selection: {
      type: Boolean,
      default: false
    },
    //是否开启表格点击
    isRowClick: Boolean,
  },
  data() {
    return {
      style: { background: "rgb(245, 247, 250)", color: "#000", fontWeight: "bold" },
      titleStyle: {}
    };
  },
  mounted() {
    this.$emit('getList', this.tableCondition); // 获取列表
  },
  methods: {

    // 选中
    handleSelectionChange(val) {

      this.$emit("chosenChange", val);
    },

    //全部选中或取消
    toggleSelection(e) {
      if (e) {
        this.tableData.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    //获取详情
    getDetail(row) {
      if (!this.isRowClick) {
        return false;
      }
      //console.log(row);
      this.$emit("getDetail", row);
    }
  },
}
</script>
