<template>
    <el-card>
        <div slot="header" class="clearfix">
            <span>提交人</span>
        </div>
        <el-form ref="form" :model="form" label-width="150px">
            <el-form-item label="" v-if="show_btn">
              <el-select v-model="value"   :placeholder="$t('submitor.txt1')"
                          @change="show_rs" :loading="loading">
                <el-option v-for="(item,index) in options" :key="index" :label="item.submitName" :value="index">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('submitor.sign')" v-if="show_signName">
                <span>{{ select_name }}</span>
            </el-form-item>
            <el-form-item :label="$t('submitor.sign')" v-if="!show_signName">
                <el-radio-group v-model="select_name">
                    <el-radio v-for="(v, i) in directors" :key="i" :label="v.nameC"
                        @change="select_diretor"></el-radio><br>
                </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('submitor.submitName')">
                <el-input v-model="form.submitName"></el-input>
            </el-form-item>
            <el-form-item :label="$t('submitor.submitNameE')">
                <el-input v-model="form.submitNameE"></el-input>
            </el-form-item>
            <el-form-item :label="$t('submitor.submitAddress')">
                <el-input v-model="form.submitAddress"></el-input>
            </el-form-item>
            <el-form-item :label="$t('submitor.submitPhone')">
                <el-input v-model="form.submitPhone"></el-input>
            </el-form-item>
            <el-form-item :label="$t('submitor.submitFax')">
                <el-input v-model="form.submitFax"></el-input>
            </el-form-item>
            <el-form-item :label="$t('submitor.submitEmail')">
                <el-input v-model="form.submitEmail"></el-input>
            </el-form-item>
            <el-form-item :label="$t('submitor.fileNumber')">
                <el-input v-model="form.fileNumber"></el-input>
            </el-form-item>
            <el-form-item :label="$t('submitor.txt2')" prop="signDate">
                <el-date-picker type="date" :placeholder="$t('submitor.txt3')" v-model="form.signDate"
                    value-format="yyyy-MM-dd" @change="select_diretor" style="width: 100%;"></el-date-picker>
            </el-form-item></el-form>
    </el-card>
</template>
<script>
import * as api from "@/api/api"
export default {
    props: {
        show_btn: Boolean,
        submitor: Object
    },
    watch: {
        'submitor': {
            immediate: true,
            handler(newVal) {
                console.log("提交人组件内部数据" + newVal)
                this.form = newVal
                this.show_signName = true
                this.select_name = this.form.signName
            },
            deep: true
        },

    },
    data() {
        return {
            options: [],
            value: [],
            list: [],
            loading: false,
            states: [],
            directors: [],
            dialogFormVisible: false,
            formLabelWidth: "120px",
            show_signName: true,
            select_name: "",
            form: {}
            //        form: {

            //     "id": "",
            //     "submitName": '',
            //     "submitAddress": '',
            //     submitPhone: '',
            //     submitFax: '',
            //     submitEmail: '',
            //     fileNumber: '',
            //     signDate: "",
            //     signName: ""


            // }
        }
    },
  created() {
      this.get_data()
  },
  mounted() {
        this.form = {

            "id": "",
            "submitName": '',
            "submitAddress": '',
            submitPhone: '',
            submitFax: '',
            submitEmail: '',
            fileNumber: '',
            signDate: "",
            signName: ""


        }

    },
    methods: {
        remoteMethod(query) {
            if (query !== '') {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.get_data(query)
                    this.options = this.list.filter(item => {
                        console.log(item)
                        return item.label.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;

                    });


                }, 200);

            } else {
                this.options = [];
            }
        },
        show_rs(val) {
          console.log(val)

            let obj = {
                ...this.options[val]
            }

            this.form = obj
            var submitor = {
                id: 0,
                signName: this.select_name,
                "submitName": this.form.submitName,
                "submitNameE": this.form.submitNameE,
                "submitAddress": this.form.submitAddress,
                "submitPhone": this.form.submitPhone,
                "submitFax": this.form.submitFax,
                "submitEmail": this.form.submitEmail,
                "fileNumber": this.form.fileNumber,
                "submitType": 2,
                "signDate": this.form.signDate
            }

            this.$emit('selectSubmit', submitor);

        },
        select_diretor() {
            this.form.signName = this.select_name
            this.form.id = 0
            console.log(this.form)
            this.$emit('selectSubmit', this.form);
        },
        handleNew() {
            this.editting = false;
            this.editId = 0;
            this.dialogFormVisible = true;
        },
        get_data() {
            var params = {
                keywords: "",
                topage: 1,
                pagesize: 100
            }
            api.getRequest('/submitor',
                params).then(resp => {

                    this.options = resp.data.records
                })
        },

        handleCancel() {
            this.dialogFormVisible = false;
            this.form = this.form = {};
            this.$refs['form'].resetFields();
        },



    },

}
</script>
