<template>
  <div class="table-box">
    <el-card>

      <slot name='header' />

      <div class="btns-box">
        <slot name="btns" />
      </div>

      <el-table ref="multipleTable" :data="tableData" border :header-cell-style="style" :cell-style="titleStyle"
        @cell-click="getDetail" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" v-if="selection"></el-table-column>

        <template v-for="item in title">


          <el-table-column v-if="item.template" :key="item.id" :label="item.label" :width="item.width"
            :align="item.align">
            <template v-slot="scope">
              <slot :scope="scope.row" :name="item.prop"></slot>
            </template>
          </el-table-column>

          <el-table-column v-else :prop="item.prop" :label="item.label" :width="item.width" :align="item.align"
            :sortable="item.sortable">
          </el-table-column>

        </template>
      </el-table>

      <pagination :pagination='pagination' @selectPage='selectPage' />
    </el-card>
  </div>
</template>

<script>
import pagination from '@/components/pagination'

export default {
  components: {
    pagination
  },
  props: {
    title: {
      type: Array,
      default: null
    },
    tableData: {
      type: Array,
      default: null
    },
    control: {
      type: Array,
      default: null
    },
    //是否开启选择
    selection: {
      type: Boolean,
      default: false
    },
    //是否开启表格点击
    isRowClick: Boolean,

    // 分页器
    pagination: {
      type: Object,
      default: () => ({
        current_page: 1, //当前页
        page_pieces: 0, //当前页条数
        total_page: 0, //总页数
        total_pieces: 0 //总数
      })
    }
  },
  data() {
    return {
      style: { background: "rgb(245, 247, 250)", color: "#000", fontWeight: "bold" },
      titleStyle: {}
    };
  },
  mounted() {
    this.$emit('getList', this.tableCondition); // 获取列表
  },
  methods: {
    // 分页
    selectPage(obj) {
      console.log("点击分页" + obj)

      let searchObj = Object.assign(this.tableCondition || {}, obj)
      console.log(obj, searchObj)
      this.$store.commit('user/setTableCondition', searchObj) //保存条件
      this.$emit('getList', searchObj)
    },

    // 选中
    handleSelectionChange(val) {
      // console.log("yyyy" + val);
      this.$emit("chosenChange", val);
    },

    //全部选中或取消
    toggleSelection(e) {
      if (e) {
        this.tableData.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    //获取详情
    getDetail(row) {
      if (!this.isRowClick) {
        return false;
      }
      //console.log(row);
      this.$emit("getDetail", row);
    }
  },

  computed: {
    tableCondition() {//筛选条件
      return this.$store.state.user.tableCondition
    }
  }
};
</script>

<style  scoped>
.table-box {
  border: 1px solid #ebeef5;
  border-bottom: none;
  margin-top: 20px;
}

.btns-box {
  padding-bottom: 15px;
}
</style>
