import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/element-#1AB394/index.css';
import router from './router'
////数据
import axios from 'axios'

axios.defaults.baseURL =  process.env.VUE_APP_API
Vue.prototype.$axios = axios

import md5 from 'md5';
Vue.prototype.$md5 = md5;


Vue.use(VueI18n)
Vue.use(ElementUI, {
  zhLocale: {
    el: {
      pagination: {

        pagesize: 's/p',

        total: `x {total} y`,

        goto: '前往第',

        pageClassifier: '页'

      },
    }
  }
})
Vue.config.productionTip = false
const i18n = new VueI18n({
  locale: 'cn', // 语言包
  messages: {
    'cn': require('./lang/cn.json'), // 中文简体
    'en': require('./lang/en.json') // 英文
  }
});
import App from './App.vue'
// import { options } from 'less';

Vue.config.productionTip = false

new Vue({
  i18n, router,
  render: h => h(App),
  data: function () {
    return {

      serverUrl: "https://api.gainrich.biz/upload_file/",
      upFileUrl: "https://api.gainrich.biz/api/upload",
      companyType: [{
            code: "001",
            intro: "農作物及動物養殖、狩獵及相關服務活動",
            introEn: "Crop and animal production, hunting and related service activities"
          },
          {
            code: "002",
            intro: "林業活動",
            introEn: "Forestry activities"
          },
          {
            code: "003",
            intro: "捕魚及水產養殖",
            introEn: "Fishing and aquaculture"
          },
          {
            code: "005",
            intro: "煤和褐煤的採掘",
            introEn: "Mining of coal and lignite"
          },
          {
            code: "005",
            intro: "煤和褐煤的採掘",
            introEn: "Mining of coal and lignite"
          },
        ],
        companyType1: [{
            "code": "A",
            "intro": "農業、林業及漁業",
            "introEn": "Agriculture, forestry and fishing"
          },
          {
            "code": "001",
            "intro": "農作物及動物養殖、狩獵及相關服務活動",
            "introEn": "Crop and animal production, hunting and related service activities"
          },
          {
            "code": "002",
            "intro": "林業活動",
            "introEn": "Forestry activities"
          },
          {
            "code": "003",
            "intro": "捕魚及水產養殖",
            "introEn": "Fishing and aquaculture"
          },
          {
            "code": "B",
            "intro": "採礦及採石",
            "introEn": "Mining and quarrying"
          },
          {
            "code": "005",
            "intro": "煤和褐煤的採掘",
            "introEn": "Mining of coal and lignite"
          },
          {
            "code": "006",
            "intro": "原油及天然氣的開採",
            "introEn": "Extraction of crude petroleum and natural gas"
          },
          {
            "code": "007",
            "intro": "金屬礦的採掘",
            "introEn": "Mining of metal ores"
          },
          {
            "code": "008",
            "intro": "採石及其他非金屬礦的採掘",
            "introEn": "Quarrying and other mining of non-metal ores"
          },
          {
            "code": "009",
            "intro": "礦物開採輔助服務活動",
            "introEn": "Mining support service activities"
          },
          {
            "code": "C",
            "intro": "製造",
            "introEn": "Manufacturing"
          },
          {
            "code": "010",
            "intro": "食品的製造",
            "introEn": "Manufacture of food products"
          },
          {
            "code": "011",
            "intro": "飲品的製造",
            "introEn": "Manufacture of beverages"
          },
          {
            "code": "012",
            "intro": "煙草製品的製造",
            "introEn": "Manufacture of tobacco products"
          },
          {
            "code": "013",
            "intro": "紡織品的製造",
            "introEn": "Manufacture of textiles"
          },
          {
            "code": "014",
            "intro": "成衣的製造",
            "introEn": "Manufacture of wearing apparel"
          },
          {
            "code": "015",
            "intro": "皮革及相關製品的製造",
            "introEn": "Manufacture of leather and related products"
          },
          {
            "code": "016",
            "intro": "木材及木製品、水松製品、草編及編結材料製品的製造(傢具及玩具除外)",
            "introEn": "Manufacture of wood and of products of wood and cork, articles of straw and plaiting materials (except furniture and toys)"
          },
          {
            "code": "017",
            "intro": "紙及紙製品的製造",
            "introEn": "Manufacture of paper and paper products"
          },
          {
            "code": "018",
            "intro": "印刷及已儲錄資料媒體的複製",
            "introEn": "Printing and reproduction of recorded media"
          },
          {
            "code": "019",
            "intro": "焦煤和精煉石油產品的製造",
            "introEn": "Manufacture of coke and refined petroleum products"
          },
          {
            "code": "020",
            "intro": "化學品及化學產品的製造",
            "introEn": "Manufacture of chemicals and chemical products"
          },
          {
            "code": "021",
            "intro": "藥品、醫藥化學劑和植物藥材的製造",
            "introEn": "Manufacture of pharmaceuticals, medicinal chemical and botanical products"
          },
          {
            "code": "022",
            "intro": "橡膠及塑膠產品的製造(傢具、玩具、體育用品及文具除外)",
            "introEn": "Manufacture of rubber and plastics products (except furniture, toys, sports goods and stationery)"
          },
          {
            "code": "023",
            "intro": "其他非金屬礦產製品的製造",
            "introEn": "Manufacture of other non-metallic mineral products"
          }, {
            "code": "024",
            "intro": "基本金屬的製造",
            "introEn": "Manufacture of basic metals"
          }, {
            "code": "025",
            "intro": "金屬製品的製造(機械及設備除外)",
            "introEn": "Manufacture of fabricated metal products, except machinery and equipment"
          },
          {
            "code": "026",
            "intro": "電腦、電子及光學產品的製造",
            "introEn": "Manufacture of computer, electronic and optical products"
          }, {
            "code": "027",
            "intro": "電器設備的製造",
            "introEn": "Manufacture of electrical equipment"
          }, {
            "code": "028",
            "intro": "其他機械設備的製造",
            "introEn": "Manufacture of machinery and equipment n.e.c."
          }, {
            "code": "029",
            "intro": "汽車的裝嵌",
            "introEn": "Body assembly of motor vehicles"
          }, {
            "code": "030",
            "intro": "其他運輸設備的製造",
            "introEn": "Manufacture of other transport equipment"
          }, {
            "code": "031",
            "intro": "傢具的製造",
            "introEn": "Manufacture of furniture"
          }, {
            "code": "032",
            "intro": "其他製造業",
            "introEn": "Other manufacturing"
          }, {
            "code": "033",
            "intro": "機械及設備的維修及安裝",
            "introEn": "Repair and installation of machinery and equipment"
          }, {
            "code": "035",
            "intro": "電力及燃氣供應",
            "introEn": "Electricity and gas supply"
          }, {
            "code": "036",
            "intro": "自來水集取、處理及供應",
            "introEn": "Water collection, treatment and supply"
          }, {
            "code": "037",
            "intro": "污水處理",
            "introEn": "Sewerage"
          }, {
            "code": "038",
            "intro": "廢棄物的收集、處理及處置活動；資源的回收處理",
            "introEn": "Waste collection, treatment and disposal activities; materials recovery"
          }, {
            "code": "041",
            "intro": "樓房的建造",
            "introEn": "Construction of buildings"
          }, {
            "code": "042",
            "intro": "土木工程",
            "introEn": "Civil engineering"
          }, {
            "code": "045",
            "intro": "進出口貿易",
            "introEn": "Import and export trade"
          }, {
            "code": "046",
            "intro": "批發",
            "introEn": "Wholesale"
          }, {
            "code": "047",
            "intro": "零售業",
            "introEn": "Retail trade"
          }, {
            "code": "049",
            "intro": "陸路運輸",
            "introEn": "Land transport"
          }, {
            "code": "050",
            "intro": "水上運輸",
            "introEn": "Water transport"
          }, {
            "code": "051",
            "intro": "航空運輸",
            "introEn": "Air transport"
          },
          {
            "code": "052",
            "intro": "航空運輸",
            "introEn": "Air transport"
          }, {
            "code": "053",
            "intro": "貨倉及運輸輔助活動",
            "introEn": "Warehousing and support activities for transportation"
          }, {
            "code": "055",
            "intro": "短期住宿活動",
            "introEn": "Short term accommodation activities"
          }, {
            "code": "056",
            "intro": "餐飲服務活動",
            "introEn": "Food and beverage service activities"
          }, {
            "code": "058",
            "intro": "出版活動",
            "introEn": "Publishing"
          }, {
            "code": "059",
            "intro": "電影、錄像及電視節目製作活動、錄音及音樂出版活動",
            "introEn": "Motion picture, video and television programme production, sound recording and music publishing activities"
          }, {
            "code": "060",
            "intro": "節目編製及廣播活動",
            "introEn": "Programming and broadcasting activities"
          }, {
            "code": "061",
            "intro": "電訊",
            "introEn": "Telecommunications"
          }, {
            "code": "062",
            "intro": "資訊科技服務活動",
            "introEn": "Information technology service activities"
          }, {
            "code": "063",
            "intro": "資訊服務活動",
            "introEn": "Information service activities"
          }, {
            "code": "064",
            "intro": "金融服務活動，包括投資及控股公司、信託、基金及相關金融工具的活動",
            "introEn": "Financial service activities, including investment and holding companies, and the activities of trusts, funds and similar financial entities."
          }, {
            "code": "065",
            "intro": "保險(包括退休基金)",
            "introEn": "Insurance (including pension funding)"
          }, {
            "code": "066",
            "intro": "金融保險輔助活動",
            "introEn": "Activities auxiliary to financial service and insurance activities"
          }, {
            "code": "068",
            "intro": "地產活動",
            "introEn": "Real estate activities"
          }, {
            "code": "069",
            "intro": "法律及會計活動",
            "introEn": "Legal and accounting activities"
          }, {
            "code": "070",
            "intro": "總辦事處活動；管理及管理顧問活動",
            "introEn": "Activities of head offices; management and management consultancy activities"
          }, {
            "code": "071",
            "intro": "建築及工程活動、技術測試及分析",
            "introEn": "Architecture and engineering activities, technical testing and analysis"
          }, {
            "code": "072",
            "intro": "科學研究及發展",
            "introEn": "Scientific research and development"
          }, {
            "code": "073",
            "intro": "獸醫活動",
            "introEn": "Veterinary activities"
          }, {
            "code": "074",
            "intro": "廣告及市場研究",
            "introEn": "Advertising and market research"
          }, {
            "code": "075",
            "intro": "其他專業、科學及技術活動",
            "introEn": "Other professional, scientific and technical activities"
          }, {
            "code": "077",
            "intro": "租賃活動",
            "introEn": "Rental and leasing activities"
          },
          {
            "code": "078",
            "intro": "就業活動",
            "introEn": "Employment activities"
          }, {
            "code": "079",
            "intro": "旅行代理、代訂服務及相關活動",
            "introEn": "Travel agencies, reservation services and related activities"
          }, {
            "code": "080",
            "intro": "保安及偵查活動",
            "introEn": "Security and investigation activities"
          }, {
            "code": "081",
            "intro": "建築物及園境護理服務活動",
            "introEn": "Services to buildings and landscape care activities"
          }, {
            "code": "082",
            "intro": "辦公室行政、辦公室支援及其他商業支援活動",
            "introEn": "Office administrative, office support and other business support activities"
          }, {
            "code": "084",
            "intro": "公共行政",
            "introEn": "Public administration"
          }, {
            "code": "085",
            "intro": "教育",
            "introEn": "Education"
          }, {
            "code": "086",
            "intro": "人類保健活動",
            "introEn": "Human health activities"
          }, {
            "code": "087",
            "intro": "住宿護理活動",
            "introEn": "Residential care activities"
          }, {
            "code": "088",
            "intro": "不提供住宿的社會工作活動",
            "introEn": "Social work activities without accommodation"
          }, {
            "code": "090",
            "intro": "創作及表演藝術活動",
            "introEn": "Creative and performing arts activities"
          }, {
            "code": "091",
            "intro": "圖書館、檔案保存、博物館及其他文化活動",
            "introEn": "Libraries, archives, museums and other cultural activities"
          }, {
            "code": "093",
            "intro": "體育及其他娛樂活動",
            "introEn": "Sports and other entertainment activities"
          }, {
            "code": "094",
            "intro": "會員制組織活動",
            "introEn": "Activities of membership organisations"
          }, {
            "code": "095",
            "intro": "汽車、電單車、電腦、個人及家庭用品修理",
            "introEn": "Repair of motor vehicles, motorcycles, computers, personal and household goods"
          }, {
            "code": "096",
            "intro": "其他個人服務活動",
            "introEn": "Other personal service activities"
          }, {
            "code": "097",
            "intro": "受聘於住戶的家居活動",
            "introEn": "Activities of households as employers of domestic personnel"
          }, {
            "code": "098",
            "intro": "用以自給的私人家庭商品及勞務生產活動",
            "introEn": "Goods- and services-producing activities of private households for own use"
          }, {
            "code": "099",
            "intro": "享有治外法權的組織及團體活動",
            "introEn": "Activities of extraterritorial organisations and bodies"
          }
        ]
    }
  },
}).$mount('#app')
