<template>
  <div>
    <!-- 添加法人 -->
    <el-row :gutter="30">
      <Division :title="$t('dialog.txt11')" />
      <el-col :span="12">
        <div class="box">
          <div class="form-title">{{ $t("basic.txt1") }}: {{ item.companyName }}</div>
        </div>
      </el-col>

      <el-col :span="12">
        <div class="box">
          <div class="form-title">{{ $t('basic.txt4') }}: {{ item.companyNameE }}</div>

        </div>
      </el-col>



      <!-- <el-col :span="12">
        <div class="box">
          <div class="form-title"> <span class="must">*</span>{{ $t('staff.isHkCom') }}: {{ item.isHkComp }}
          </div>
          <div>
            <el-select v-model="item.isHkComp" :disabled="true" :placeholder="$t('dialog.txt12')">
              <el-option :label="$t('yes')" :value=1 />
              <el-option :label="$t('no')" :value=0 />

            </el-select>
          </div>
        </div>
      </el-col> -->

      <el-col :span="12">
        <div class="box">
          <div class="form-title">{{ $t('dialog.txt13') }}: {{ item.certificate }}</div>
          <!-- <div>
            <el-input v-model="item.companyCode" :disabled="true" :placeholder="$t('dialog.txt14')"></el-input>
          </div> -->
        </div>
      </el-col>


      <Division title="地址" />

      <el-col :span="24">
        <div class="box">
          <div class="form-title"> <span class="must">*</span>{{ $t('dialog.txt12') + $t('dialog.txt10') }}:</div>
          <div>
            <el-radio-group v-model="select_address">
              <el-radio v-for="(v, i) in item.companyAddress" :key="i" :label="v">{{ v.region
  + v.state +
  v.street + v.building +
  v.address
                }}</el-radio><br>
            </el-radio-group>
          </div>
        </div>
      </el-col>

      <el-col :span="24" v-if="show_share">
        <div class="box">
          <div class="space-between align-center">
            <div class="form-title"> <span class="must">*</span>{{ $t('dialog.txt5') }}:</div>
            <div class="header-btn">
              <el-button type="primary" @click="stockAdd">{{ $t('dialog.txt6') }}</el-button>
            </div>
          </div>

          <div>
            <Table :table-data="stack" :title="stockControlX">

              <template v-slot:class_s="scope">
                <el-select v-model="scope.scope.class_s" :placeholder="$t('dialog.txt7')"
                  @change="currency_change(scope)">
                  <el-option :label="$t('company.stock1')" value="ordinary"></el-option>
                  <el-option :label="$t('company.stock2')" value="preference"></el-option>
                </el-select>
              </template>

              <template v-slot:total="scope">
                <el-input onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="scope.scope.total"
                  @blur="totalBlurChange(scope)" :placeholder="$t('dialog.txt8')"></el-input>
              </template>

              <template v-slot:currency="scope">

                <el-select :disabled="true" v-model="scope.scope.currency" :placeholder="$t('company.stock-txt4')">
                  <el-option label="HKD" value="HKD"></el-option>
                  <el-option label="CNY" value="CNY"></el-option>
                  <el-option label="USD" value="USD"></el-option>
                </el-select>
              </template>


              <template v-slot:amount2="scope">
                <el-input @blur="blurChange(scope)" v-model="scope.scope.amount2"
                  :placeholder="$t('dialog.txt9')"></el-input>
              </template>

              <template v-slot:edit="scope">
                <el-button type="primary" size="small" plain round
                  @click.native.prevent="deleteRow(scope.$index, item.shares)">{{ $t('del') }}</el-button>
              </template>
            </Table>
          </div>
        </div>
      </el-col>

    </el-row>

    <div class="foot-btn">
      <el-button @click="onClear">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="onConfirm">{{ $t('confirm') }}</el-button>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table/table";
import Division from '@/components/divisions'
import bus from '@/bus'

export default {
  components: {
    Table,
    Division
  },

  props: {
    package: Object,
    show_share: Boolean,
    detail: Object

  },
  watch: {

    'package': {
      immediate: true,
      handler(newVal) {

        console.log(newVal)
        if (newVal.currencyOrdinary) {
          this.currency_ord = newVal.currencyOrdinary
        }
        if (newVal.currencyPre) {
          this.currency_pre = newVal.currencyPre
        }
        this.price = newVal.priceOrdinary
        this.price2 = newVal.pricePre
        // console.log(this.currency_ord)


      },
      deep: true
    },



  },

  data() {
    return {



      item: {},

      // currency1: 'HKD',
      currency_pre: 'HKD',
      currency_ord: 'HKD',
      set_price: 1,
      price: 1,
      price2: 2,
      amount1: 0,
      amount2: 0,
      amount3: 0,
      total: 0,
      hkAddress: [],
      stack: [],
      select_address: 0,
      stockControlX: [],
      stockControl: [
        {
          id: 1,
          prop: 'class_s',
          label: '股份类别',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'total',
          label: '建議發行的股份總數',
          align: 'center',
          template: true
        },
        {
          id: 3,
          prop: 'currency',
          label: '貨幣',
          align: 'center',
          template: true
        },
        {
          id: 4,
          prop: 'amount1',
          label: '創辦成員認購的股份总数',
          align: 'center',
          // template: true
        },
        {
          id: 5,
          prop: 'amount2',
          label: '建設發行的股份的將要繳付或視為已繳付的股數',
          align: 'center',
          template: true
        },
        // {
        //   id: 6,
        //   prop: 'amount3',
        //   label: '建議發行的股份的尚未繳付或視為尚未繳付的總款額',
        //   align: 'center',
        //   template: true
        // },
        {
          id: 12,
          prop: 'edit',
          label: '操作',
          align: 'center',
          width: '120px',
          template: true
        }
      ],
      stockControlEn: [
        {
          id: 1,
          prop: 'class_s',
          label: 'Share class',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'total',
          label: 'The total number of shares proposed to be issued',
          align: 'center',
          template: true
        },
        {
          id: 3,
          prop: 'currency',
          label: 'currency',
          align: 'center',
          template: true
        },
        {
          id: 4,
          prop: 'amount1',
          label: 'Total share capital subscribed by founding members',
          align: 'center',

        },
        {
          id: 5,
          prop: 'amount2',
          label: 'The number of shares to be paid or deemed to have been paid',
          align: 'center',
          template: true
        },
        // {
        //   id: 6,
        //   prop: 'amount3',
        //   label: '建議發行的股份的尚未繳付或視為尚未繳付的總款額',
        //   align: 'center',
        //   template: true
        // },
        {
          id: 12,
          prop: 'edit',
          label: 'Operation',
          align: 'center',
          width: '120px',
          template: true
        }
      ]
    }
  },

  created() {


    bus.$on('item', (data) => {

      if (data.staffType == 2) {
        let after = JSON.stringify(data);
        let change = JSON.parse(after);
        console.log("jieshou" + JSON.stringify(change))
        this.item = change
        this.item.companyName = change.companyName[0]
        this.item.companyNameE = change.companyNameE[0]
        this.item.staffId = change.id
      }




    })



    if (localStorage.getItem("lang") == "en") {

      this.stockControlX = this.stockControlEn

    }
    if (localStorage.getItem("lang") == "cn") {
      this.stockControlX = this.stockControl

    }
  },
  watch: {

    "$i18n.locale"() {

      if (localStorage.getItem("lang") == "en") {
        console.log()
        this.stockControlX = this.stockControlEn

      }
      if (localStorage.getItem("lang") == "cn") {
        this.stockControlX = this.stockControl

      }


    },


  },

  methods: {


    // class_s_cahnge(item) {
    //   item.scope.currency = item.scope.class_s == 'ordinary' ? this.currency1 : this.currency_pre
    // },

    currency_change(row) {

      console.log(row)
      row.scope.set_price = row.scope.class_s == 'ordinary' ? this.price : this.price2
      row.scope.currency = row.scope.class_s == 'ordinary' ? this.currency_ord : this.currency_pre

      this.blurChange(row)
      this.totalBlurChange(row)

    },

    // 取消
    onClear() {
      this.$emit('onCancel')
    },

    // 确认
    onConfirm() {
      // if ()

      this.item.region = this.select_address.region
      this.item.state = this.select_address.state
      this.item.street = this.select_address.street
      this.item.building = this.select_address.building
      this.item.address = this.select_address.address
      this.item.shares = this.stack
      // console.log("法人" + JSON.stringify(this.item))
      this.$emit('onConfirm', this.item)

    },

    // 认购的股本添加
    stockAdd() {

      if (this.stack.length >= 2) {
        this.$message.error("同一个股东只能够有两条股份记录")
      } else {
        let obj = { class_s: 'ordinary', total: 0, currency: this.currency_ord, amount1: 0, amount2: 0, amount3: 0, set_price: this.price }
        this.stack.push(obj)
      }

    },

    deleteRow(index, rows) {

      rows.splice(index, 1);
    },

    //总金额计算
    blurChange(scope) {

      console.log(scope.scope.set_price)
      let price = scope.scope.set_price//Number(this.price)
      if (Number(scope.scope.total) < Number(scope.scope.amount2)) {
        this.$message.error('认购股本不能大于发行总数')
        return false
      }


      if (Number(scope.scope.amount1) < Number(scope.scope.amount2)) {
        this.$message.error('已缴纳总额不能大于股本总额')
        return false
      }
      if (Number(scope.scope.amount2)) {
        // scope.scope.amount3 = parseInt(scope.scope.amount1) - parseInt(scope.scope.amount2)
        scope.scope.amount3 = parseInt(scope.scope.amount2) * price
      }
    },

    totalBlurChange(scope) {

      let price = scope.scope.set_price
      scope.scope.amount1 = price * scope.scope.total

    },
  }
}
</script>

<style lang="less" scoped>
.table-header {
  .box {
    padding-right: 20px;

    .form-title {
      padding-right: 10px;
      padding-bottom: 0px;
    }
  }
}
</style>