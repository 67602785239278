<template>
    <div>
        <el-row class="line-up-row">
            {{ $t('pagination.total') }} {{ totalRecord }} {{ $t('pagination.items') }}
            <el-select v-model="pagesize" style="width: 100px" @change="handleSizeChange" size="small">
                <el-option :label="10 + $t('pagination.pageSize')" value="10"></el-option>
                <el-option :label="20 + $t('pagination.pageSize')" value="20"></el-option>
                <el-option :label="50 + $t('pagination.pageSize')" value="50"></el-option>

            </el-select>
            <el-pagination @current-change="paging" :current-page="currentpage" :page-size="pagesize"
                :total-text="$t('pagination.total')" @size-change="handleSizeChange" layout=" prev, pager, next"
                :total="totalRecord" :prev-text="$t('pagination.prev')" :next-text="$t('pagination.next')"
                :page-sizes="[10, 20, 50]">
            </el-pagination>

        </el-row>
    </div>
</template>
<script>
export default {
    props: {
        totalRecord: Number,
        currentpage: Number,

    }, data() {
        return {
            pagesize: 10
        };
    },

    methods: {
        paging(e) {
            console.log("第几页" + e)
            this.$emit("paging", e);
        },

        // 每页大小
        handleSizeChange(val) {
            this.$emit("handleSizeChange", val);
            console.log(`每页 ${val} 条`);
        },
    },

    computed: {
        page_pieces() {
            return this.$store.state.user.limit
        }
    }
}
</script>