<template>
    <div class="ibox ">
        <div class="ibox-title">
            <el-button-group>
                <el-button type="primary" icon="el-icon-edit" @click="handleNew">添加菜单</el-button>
            </el-button-group>
        </div>
        <div class="ibox-content">
            <el-tree :data="data" node-key="id" default-expand-all :render-content="renderContent"
                :expand-on-click-node="false">
            </el-tree>
        </div>


        <!-- 编辑表单 -->
        <el-dialog title="分类信息" :visible.sync="dialogFormVisible" :destroy-on-close="true" :close-on-click-modal="false"
            width="600px">
            <el-form :model="ruleForm" :rules="rules" size="big" label-position="left" ref="ruleForm">
                <el-form-item label="菜单名称" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="ruleForm.title" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单名称(繁体)" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="ruleForm.titleTc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单名称（English）" :label-width="formLabelWidth" prop="title">
                    <el-input v-model="ruleForm.titleEn" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单链接" :label-width="formLabelWidth" prop="link">
                    <el-input v-model="ruleForm.link" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单图标" :label-width="formLabelWidth" prop="icon">
                    <el-input v-model="ruleForm.icon" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单权限" :label-width="formLabelWidth" prop="rights">
                    <!-- <el-checkbox-group v-model="check_list" @change="handleCheckChange($event)">
                                <el-checkbox v-for="(item,index) in rights" :key="index" :label="item.label" label="super_admin" >{{item.name}}</el-checkbox>
                            </el-checkbox-group> -->
                    <el-checkbox-group v-model="check_list" @change="handleCheckedChange">
                        <el-checkbox v-for="(item, i) in rights" :label="item.value" :key="i">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item label="上级分类" :label-width="formLabelWidth" prop="fatherId">
                    <el-select v-model="ruleForm.fatherId" placeholder="选择">
                        <el-option v-for="(item, index) in tableData" :key="index" :label="item.label" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序" :label-width="formLabelWidth" prop="fatherId">
                    <el-input-number v-model="ruleForm.sortOrder" :step="1"></el-input-number>
                </el-form-item>

                <el-form-item label="菜单备注" :label-width="formLabelWidth" prop="intro">
                    <el-input v-model="ruleForm.intro" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
let id = 1000;
import * as api from "../../api/api"
export default {

    data() {
        const data = [];
        return {
            data: JSON.parse(JSON.stringify(data)),
            rights: [{ "name": "超级管理员", "value": "super_admin" }, { "name": "普通用户", "value": "system" }],
            currt_index: 1,
            tableData: [],
            check_list: [],
            search: "",
            dialogFormVisible: false,
            editting: false,
            editId: 0,
            totalRecord: 0,
            currentpage: 1,
            pagesize: 100,

            ruleForm: {
                id: "",
                title: "",
                link: "",
                icon: "",
                level: "",
                fatherId: "",
                intro: "",
                rights: [],
                sortOrder: 0
            },
            rules: {
                title: [
                    { required: true, message: '菜单名称为必填', trigger: 'blur' },
                    { min: 2, max: 20, message: '菜单名称长度3-20字符', trigger: 'blur' }
                ]
            },
            form_editing: {},
            formLabelWidth: "120px",
            type: 0
        };

    },
    created: async function () {
        this.type = this.$route.params.type
        console.log(this.type)
        this.get_data();

    },
    methods: {
        get_data() {

            let which = this.type + ""
            console.log(which)
            var params = {
                keywords: "",
                which: which
            }
            api.getRequest('/menuData',
                params).then(resp => {
                    console.log(resp)
                    this.tableData = []
                    this.tableData.push({ label: "无上级分类", fatherId: 0, id: 0, intro: "" })
                    let newdata = resp.data.records
                    let newNodes = []
                    for (var i = 0; i < newdata.length; i++) {
                        if (newdata[i].level == 0) {
                            var newRecord = {
                                label: newdata[i].title, fatherId: newdata[i].fatherId,
                                id: newdata[i].id, intro: newdata[i].intro, link: newdata[i].link, icon: newdata[i].icon, rights: newdata[i].rights, sortOrder: newdata[i].sortOrder,
                                children: []
                            }
                            newNodes.push(newRecord)

                            this.tableData.push(newRecord)
                        }
                    }

                    for (var i = 0; i < newdata.length; i++) {
                        if (newdata[i].level == 1) {
                            for (var j = 0; j < newNodes.length; j++) {
                                if (newNodes[j].id == newdata[i].fatherId) {
                                    newNodes[j].children.push({
                                        label: newdata[i].title, fatherId: newdata[i].fatherId,
                                        id: newdata[i].id, intro: newdata[i].intro, link: newdata[i].link, icon: newdata[i].icon, rights: newdata[i].rights, sortOrder: newdata[i].sortOrder
                                    })
                                }
                            }
                        }
                    }
                    this.data = newNodes
                    console.log(this.data)
                    this.totalRecord = resp.data.total
                    this.total
                })
        },



        handleNodeClick(data) {

            var a = data.label
            this.ruleForm.title = data.title
            this.ruleForm.titleEn = data.titleEn
            this.ruleForm.titleTc = data.titleTc
            this.ruleForm.intro = data.intro
            this.ruleForm.id = data.id
            this.ruleForm.fatherId = data.fatherId
            this.ruleForm.intro = data.intro
            this.ruleForm.link = data.link
            this.ruleForm.icon = data.icon
            this.ruleForm.sortOrder = data.sortOrder
            if (data.rights != "") {
                this.check_list = JSON.parse(data.rights)
            }
            console.log(data)
            this.dialogFormVisible = true;
            this.editting = true;
            this.editId = data.id;
        },
        handleNew() {
            this.editting = false;
            this.editId = 0;
            this.ruleForm = {
                id: "",
                title: "",
                link: "",
                icon: "",
                level: "",
                fatherId: "",
                intro: "",
                rights: [],
            }
            this.dialogFormVisible = true;
        },
        handleCancel() {
            this.dialogFormVisible = false;
            this.check_list = []
            this.ruleForm = {
                id: "",
                title: "",
                link: "",
                icon: "",
                level: "",
                fatherId: "",
                intro: "",
                rights: [],
                which: this.which
            }
        },

        handleCheckedChange(value) {
            console.log(this.check_list)
            let checkedCount = value.length;   //选中值的长度
            this.checkAll = checkedCount === this.rights.length;  //如果选中值的长度和源数据的长度一样，返回true，就表示你已经选中了全部checkbox，那么就把true赋值给this.checkAll
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.rights.length; //同全选按钮事件里面的那个样式控制

        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                api.deleteRequest('/menuData/' + row.id,
                    {}).then(resp => {
                        console.log(resp)
                        if (resp.data == 1) {
                            this.$message.success(resp.message)
                        }
                        else {
                            this.$message.error(resp.message)
                        }
                        let x = this.tableData.length
                        if (x == 1) {
                            let y = this.currentpage
                            this.currentpage = y - 1
                        }
                        this.get_data()
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        save() {
            let newdata = this.ruleForm;
            console.log(newdata.fatherId);
            if (newdata.fatherId == 0) {
                newdata.level = 0
            } else {
                newdata.level = 1
            }
            newdata.rights = JSON.stringify(this.check_list)
            console.log(newdata);
            if (this.editting == false) {
                api.postRequest('/menuData', newdata).then(resp => {

                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.get_data();
                        this.handleCancel();
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }
            if (this.editting == true) {
                api.putRequest('/menuData', newdata).then(resp => {
                    console.log(resp)
                    if (resp.data == 1) {
                        this.$message.success(resp.message);
                        this.get_data();
                        this.handleCancel();
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }

        },

        renderContent(h, { node, data, store }) {

            return (
                <span class="custom-tree-node">
                    <span>{node.label}</span>
                    <span>
                        <el-button type="text" on-click={() => this.handleNodeClick(data)}>编辑分类</el-button>
                        <el-button type="text" class="text-danger" on-click={() => this.handleDelete(data)}>删除</el-button>
                    </span>
                </span>);

        }
    }
};
</script>

<style>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding-right: 8px;
}
</style>