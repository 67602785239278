<template>
  <div>

    <el-dialog :visible.sync="taskShow" width="50%">
      <task :task-detail="editTask" @saveOk="closeTask"></task>
    </el-dialog>
    <div class="row">
      <div class="col-lg-12">

        <div class="ibox">
          <div class="ibox-title">
            <el-radio-group v-model="currt_index" @change="get_data" size="small">
              <el-radio-button label="0">全部任务</el-radio-button>
              <el-radio-button label="1">分配给我的任务</el-radio-button>
              <el-radio-button label="2">我发起的任务</el-radio-button>

            </el-radio-group>
          </div>
          <div class="ibox-content">
            <div class="row m-b-sm m-t-sm">
              <div class="col-md-2">

                <button type="button" class="btn btn-white btn-sm" style="margin-left: 20px;" @click="newTask"><i
                    class="fa fa-check-square"></i>新建任务</button>
              </div>
              <div class="col-md-4">

              </div>
            </div>


            <div class="project-list">

              <table class="table table-hover">
                <tbody>
                  <template v-for="(item, index) in tasks">
                    <tr>
                      <td class="project-status">

                      </td>
                      <td class="project-title">
                        <el-checkbox v-model="item.taskStatus === 1" @change="changeStatus(item)"></el-checkbox>
                        <span v-show="item.taskStatus == 0" style="margin-left: 20px;">{{ item.title }}</span>
                        <span v-show="item.taskStatus == 1" style="text-decoration: line-through;margin-left: 20px;">{{
                          item.title }}</span>
                        <span v-show="item.companyName"> 相關單位 : </span>
                        <a class="text-info" href="javascript:;" v-show="item.companyName"
                          @click="showCompany(item.clientId)">{{
                            item.companyName
                          }}</a>
                        <span class="label label-primary" style="margin-left: 30px;" v-show="item.taskType">{{
                          item.taskTypes }}</span>
                        <i class="fa fa-calendar" style="margin-left: 20px;margin-right: 15px;"></i>过期日期 :
                        {{ item.endDate }}
                      </td>
                      <td class="project-completion">

                      </td>
                      <td class="project-people">

                      </td>
                      <td class="project-actions">
                        <a href="javascript:;" class="btn btn-primary btn-sm" v-if="item.crtBy == adminKey"
                          @click="openTask(item)"><i class="fa fa-pencil"></i> 编辑
                        </a>
                        <a href="javascript:;" class="btn btn-success btn-sm" v-if="item.crtBy !== adminKey"
                          @click="openTask(item)"><i class=" fa fa-file"></i> 查看
                        </a>

                      </td>
                    </tr>
                  </template>


                </tbody>
              </table>
              <el-collapse v-model="activeNames">
                <el-collapse-item title="已完成" name="1">
                  <table class="table table-hover">
                    <tbody>
                      <template v-for="(item, index) in tasksFinish">
                        <tr :key="index">
                          <td class="project-status">

                          </td>
                          <td class="project-title">
                            <el-checkbox v-model="item.taskStatus === 1" @change="changeStatus(item)"></el-checkbox>
                            <span v-show="item.taskStatus == 0" style="margin-left: 20px;">{{ item.title }}</span>
                            <span v-show="item.taskStatus == 1"
                              style="text-decoration: line-through;margin-left: 20px;">{{ item.title }}</span>
                            <i class="fa fa-calendar" style="margin-left: 20px;margin-right: 15px;"></i>过期日期 :
                            {{ item.endDate }}
                          </td>
                          <td class="project-completion">

                          </td>
                          <td class="project-people">

                          </td>
                          <td class="project-actions">
                            <a href="javascript:;" class="btn btn-white btn-sm" @click="openTask(item)"><i
                                class="fa fa-pencil"></i> 编辑 </a>
                          </td>
                        </tr>
                      </template>


                    </tbody>
                  </table>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer title="公司详细资料" :visible.sync="drawer" size="80%" :wrapper-closable="false" :destroy-on-close="true">
      <company_detail :c_id="c_id"></company_detail>
    </el-drawer>
  </div>
</template>
<script>
import task from "@/components/common/task.vue";
import * as api from "@/api/api";
import company_detail from "../hk/company_detail_view.vue";
export default {
  components: { task, company_detail },
  data() {
    return {

      checked: true,
      tasks: [],
      tasksFinish: [],
      taskShow: false,
      editTask: null,
      currt_index: 0,
      activeNames: ['0'],
      adminKey: sessionStorage.getItem('adminKey'),
      drawer: false,
      c_id: 0
    }
  },
  created: async function () {
    this.get_data();
    console.log(sessionStorage.getItem("adminKey"))
  },
  methods: {
    get_data() {
      var params = {
        userToken: sessionStorage.getItem("adminKey"),
        taskType: this.currt_index
      }
      api.getRequest('/tasks',
        params).then(resp => {

          var tasks = []
          resp.data.records.forEach(function (item) {
            if (item.taskStatus == 0) {
              tasks.push(item)
            }
          })
          this.tasks = tasks

          var tasksFinish = []
          resp.data.records.forEach(function (item) {
            if (item.taskStatus == 1) {
              tasksFinish.push(item)
            }
          })
          this.tasksFinish = tasksFinish
          console.log(this.tasks)
        })
    },
    changeStatus(row) {
      console.log(row)
      if (row.taskStatus == 1) {
        row.taskStatus = 0
      }
      else if (row.taskStatus == 0) {
        row.taskStatus = 1
      }
      row.updateBy = sessionStorage.getItem("adminKey")
      api.putRequest('/tasks',
        row).then(resp => {
          console.log(resp)
          this.get_data()
        })
    },
    newTask() {
      this.taskShow = true
      this.editTask = null
    },
    openTask(row) {
      this.taskShow = true
      this.editTask = row
    },
    closeTask(res) {
      if (res == true) {
        this.taskShow = false
        this.get_data()
      }
    },
    ///查看公司资料
    showCompany(id) {
      this.drawer = true
      this.c_id = id
    }
  }
}
</script>