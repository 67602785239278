<template>
    <div>
        <el-select v-model="value" multiple placeholder="選擇項目類型" @change="show_rs" style="width: 80%;">
            <el-option v-for="item in options" :key="item.id" :label="item.typeName" :value="item.id">
            </el-option>
        </el-select>
        <el-popover placement="right" width="400" trigger="click" v-model="dialogFormVisible">
            <el-form :model="form" size="big" label-position="left" ref="form" :rules="rules">
                <el-form-item label="類別名稱" :label-width="formLabelWidth" prop="typeName">
                    <el-input v-model="form.typeName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="類別名稱(英语)" :label-width="formLabelWidth" prop="typeNameE">
                    <el-input v-model="form.typeNameE" autocomplete="off"></el-input>
                </el-form-item>

                <el-button type="primary" @click="submitForm('form')">保存</el-button>
                <el-button @click="handleCancel">取消</el-button>
            </el-form>

            <el-button slot="reference" style="margin-left: 25px;">添加類別</el-button>
        </el-popover>

    </div>
</template>
<script>
import * as api from "@/api/api"

export default {
    props: {
        'selectTypes': {
            type: String,
            Default: ""
        },
    },
    watch: {
        'selectTypes': {
            immediate: true,
            handler(newVal) {
                console.log(newVal)
                this.value = api.return_jason(newVal)
            },
            deep: true
        },

    },
    data() {
        return {
            options: [],
            value: [],
            list: [],
            loading: false,
            states: [],
            form: {
                id: "",
                typeName: "",
                typeNameE: "",

            },
            rules: {
                typeName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, max: 20, message: '用户名长度3-20字符', trigger: 'blur' }
                ],
                typeNameE: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, max: 20, message: '用户名长度3-20字符', trigger: 'blur' }
                ],

            },
            dialogFormVisible: false,
            formLabelWidth: "120px",
        }
    },
    mounted() {
        // this.list = this.states.map(item => {
        //     return { value: `value:${item}`, label: `label:${item}` };
        // });
    },
    created() {
        this.get_data()
    },
    methods: {

        show_rs() {
            console.log(this.value)
            this.$emit('selectType', this.value);

        },
        handleNew() {
            this.editting = false;
            this.editId = 0;
            this.dialogFormVisible = true;
        },
        get_data() {
            var params = {}
            api.getRequest('/taskType',
                params).then(resp => {

                    this.options = resp.data
                    console.log(this.options)
                })
        },

        handleCancel() {
            this.dialogFormVisible = false;
            this.form = this.form = {};
            this.$refs['form'].resetFields();
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.save()
                } else {
                    this.$message.error("请输入必填项目!");
                    return false;
                }
            });
        },
        save() {

            api.postRequest('/taskType', this.form).then(resp => {

                if (resp.data == 1) {
                    this.$message.success(resp.message);
                    this.get_data()
                } else {
                    this.$message.error(resp.message);
                }
            })
        }

    },

}
</script>