<template>
  <div>
    <div class="search-box flex-start align-center" v-if="type != '3'">
      <div> {{ $t('search-txt') }}：</div>
      <el-input v-model="search" size="medium" :placeholder="$t('keyWord')" @keyup.enter.native="get_data"
        v-on:input="empty_key" style="width: 260px">
        <el-button slot="append" icon="el-icon-search" @click="get_data"></el-button>
      </el-input>
    </div>
    <el-table :data="tableData" style="width: 100%"
      :header-cell-style="{ color: '#666', fontSize: '14px', backgroundColor: '#f7f8f9', }" size="medium"
      :empty-text="$t('noRes')">
      <el-table-column label="id" prop="id" width="80px" sortable> </el-table-column>
      <el-table-column :label="$t('company.companyName')" prop="companyName" v-if="type == 2" sortable>
      </el-table-column>
      <el-table-column :label="$t('company.companyNameE')" prop="companyNameE" v-if="type == 2" sortable>
      </el-table-column>
      <el-table-column :label="$t('staff.nameC')" prop="nameC" v-if="type == 1" sortable> </el-table-column>
      <el-table-column :label="$t('staff.nameE2')" prop="nameE2" v-if="type == 1" sortable> </el-table-column>
      <el-table-column :label="$t('staff.nameE1')" prop="nameE1" v-if="type == 1" sortable> </el-table-column>
      <el-table-column align="center" width="280px" :label="$t('action')">
        <template v-slot="scope">

          <el-button class="table-btn" size="small" @click="selectCom(scope.row)">
            {{ $t('select')
            }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="line-up-row">
      {{ $t('pagination.total') }} {{ totalRecord }} {{ $t('pagination.items') }}

      <el-pagination @current-change="paging" :current-page="currentpage" :total-text="$t('pagination.total')"
        :page-size="pagesize" layout=" prev, pager, next" :total="totalRecord" :prev-text="$t('pagination.prev')"
        :next-text="$t('pagination.next')">
      </el-pagination>
    </el-row>

  </div>
</template>

<script>
import bus from "@/bus";
import * as api from "@/api/api"
export default {

  props: {
    type: String
  },

  data() {
    return {
      name: ''
    }
  },
  watch: {

    'type': {
      immediate: true,
      handler(newVal) {
        this.type = newVal
        this.get_data()
      },
      deep: true
    },



  },
  created() {
    // console.log(this.type)
    // this.get_data()
  }, data() {
    return {
      search: "",
      staffName: "",
      totalRecord: 0,
      currentpage: 1,
      pagesize: 5,
      tableData: [],
    }
  },
  methods: {
    ///搜索结果
    get_data() {
      this.tableData = []
      let type = this.type

      if (type == '2') {
        var params = {
          keywords: this.search,
          topage: this.currentpage,
          pagesize: this.pagesize,
          applyStatus: "datas"
        }
        api.getRequest('/company/v2',
          params).then(resp => {

            this.tableData = resp.data.records
            this.totalRecord = resp.data.total
          })
      }
      if (type == '1') {
        var params = {
          type: type,
          keywords: this.search,
          pagesize: this.pagesize,
          topage: this.currentpage,
        }
        api.getRequest('/seniorStaffs',
          params).then(resp => {
            this.tableData = resp.data.records
            this.totalRecord = resp.data.total
          })
      }

    },
    ///清空关键字
    empty_key() {
      if (this.search == "") {
        this.get_data()
      }

    },
    //翻页
    paging(val) {
      // console.log(val)
      this.currentpage = val;
      this.get_data();
    },


    ///選擇對應公司記錄
    selectCom(data) {
      data.staffType = this.type
      bus.$emit('item', data)
      this.$emit('selectStaff', data)
    },

    // 选择
    async handleSelect(e) {
      console.log(e)
      e.staffType = this.type
      bus.$emit('item', e)
      this.$emit('selCom', e)
    },


    // 搜索
    async querySearch(queryString, cb) {
      let type = this.type
      console.log(this.type)
      if (this.type == "3") {
        type = "2"
      }

      if (type == 1) {
        var params = {
          type: type,
          keywords: queryString,
          pagesize: 100
        }
        api.getRequest('/seniorStaffs',
          params).then(resp => {
            // console.log(resp.data)
            let res = resp.data.records.map(d => ({ ...d, value: d.nameC }));

            let results = queryString ? res : '';
            // 调用 callback 返回建议列表的数据
            cb(results);

          })
      }
      if (type == 2) {
        var params = {
          keywords: queryString,
          pagesize: 100,
          applyStatus: "datas"
        }
        api.getRequest('/company/v2',
          params).then(resp => {
            // console.log(resp.data)
            let res = resp.data.records.map(d => ({ ...d, value: d.companyName[0] }));

            let results = queryString ? res : '';
            // 调用 callback 返回建议列表的数据
            cb(results);

          })
      }




    },
  }
}
</script>

<style lang="less" scoped>
.search-box {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #EBEEF5;
}

.label {
  font-size: 18px;
}

.el-autocomplete {
  width: 250px;
}
</style>
