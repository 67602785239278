<template>
  <div>
    <!-- 添加自然人 -->
    <el-row :gutter="30">

      <Division :title="$t('dialog.txt')" />

      <el-col :span="12">
        <div class="box">
          <!-- 中文名 -->
          <div class="form-title">{{ $t("dialog.cname") }}: {{ item.nameC }}</div>
          <!-- <div>
           
            <el-input v-model="item.nameC" :disabled="true"
              :placeholder="$t('dialog.penter') + $t('dialog.cname')"></el-input>

          </div> -->
        </div>
      </el-col>

      <el-col :span="12">
        <div class="box">
          <!-- 英文名 -->
          <div class="form-title">{{ $t('dialog.efirst') }}: {{ item.nameE1 }} {{ $t('dialog.elast') }} :{{ item.nameE2
            }}
          </div>

        </div>
      </el-col>



      <Division :title="$t('dialog.txt10')" />
      <!-- 地址选择 -->
      <el-col :span="24">
        <div class="box">
          <div class="form-title"> <span class="must">*</span>{{ $t('staff.inputAdd') }}:</div>
          <div>
            <el-radio-group v-model="select_address">
              <el-radio v-for="(    v, i    ) in     item.address    " :key="i" :label="v" @click="showthis()">{{
      v.region
      + v.state +
      v.street + v.building +
      v.address
    }}</el-radio><br>
            </el-radio-group>
          </div>
        </div>
      </el-col>
      <el-col :span="24"> <el-radio-group v-model="id_or_passport">
          <el-radio-button label="2">選擇身份證</el-radio-button>
          <el-radio-button label="1">選擇護照</el-radio-button>
        </el-radio-group></el-col>
      <!-- 身份证选择 -->
      <Division :title="$t('dialog.txt2')" v-if="id_or_passport == 2" />


      <el-col :span="24" v-if="id_or_passport == 2">
        <div class="box">
          <div class="form-title"> <span class="must">*</span>{{ $t('dialog.txt1') }}:</div>
          <div>
            <el-radio-group v-model="select_idcard">
              <el-radio v-for="(    v, i    ) in     item.idNumbers    " :key="i" :label="v">{{ v.area +
      $t('staff.idNum')
      + ": " + v.idNumber
                }}</el-radio><br>
            </el-radio-group>
          </div>
        </div>
      </el-col>

      <!-- 选择护照 -->
      <Division :title="$t('dialog.txt3')" v-if="id_or_passport == 1" />

      <el-col :span="24" v-if="id_or_passport == 1">
        <div class="box">
          <div class="form-title"> <span class="must">*</span>{{ $t('dialog.txt4') }}:</div>
          <div>
            <el-radio-group v-model="select_passport">
              <el-radio v-for="(    v, i    ) in     item.passports    " :key="i" :label="v">{{ v.country +
      $t('staff.pasNum') + ": " +
      v.passport
                }}</el-radio><br>
            </el-radio-group>
          </div>
        </div>
      </el-col>



      <el-col :span="24" v-if="show_share">
        <div class="box">
          <div class="space-between align-center">
            <div class="form-title"> <span class="must">*</span>{{ $t('dialog.txt5') }}:</div>
            <div class="header-btn">
              <el-button type="primary" @click="stockAdd">{{ $t('dialog.txt6') }}</el-button>
            </div>
          </div>

          <div>
            <Table :table-data="stack" :title="stockControlX">

              <template v-slot:class_s="scope" >
                <el-select v-model="scope.scope.class_s" :placeholder="$t('dialog.txt7')"
                  @change="currency_change(scope)">
                  <el-option :label="$t('company.stock1')" value="ordinary"></el-option>
                  <el-option :label="$t('company.stock2')" value="preference"></el-option>
                </el-select>
              </template>

              <template v-slot:total="scope" >
                <el-input onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" @blur="totalBlurChange(scope)"
                  v-model="scope.scope.total" :placeholder="$t('dialog.txt8')"></el-input>
              </template>

              <template v-slot:currency="scope" >
                <el-select :disabled="true" v-model="scope.scope.currency" :placeholder="$t('company.stock-txt4')">
                  <el-option label="HKD" value="HKD"></el-option>
                  <el-option label="CNY" value="CNY"></el-option>
                  <el-option label="USD" value="USD"></el-option>
                </el-select>
              </template>


              <template v-slot:amount2="scope" >
                <el-input @blur="blurChange(scope)" v-model="scope.scope.amount2"
                  :placeholder="$t('dialog.txt9')"></el-input>
              </template>


              <template v-slot:edit="scope" >
                <el-button type="primary" size="small" plain round
                  @click.native.prevent="deleteRow(scope.$index, item.stack)">{{ $t('del') }}</el-button>
              </template>
            </Table>
          </div>
        </div>
      </el-col>


      <el-col :span="24" v-if="show_controller">
        <Table :table-data="controll" :title="Controller">
          <template v-slot:date1="scope" >
            <el-date-picker type="date" placeholder="选择日期" v-model="scope.scope.date1" style="width: 100%;"
              value-format="yyyy-MM-dd"></el-date-picker>
          </template>

          <template v-slot:date2="scope" >
            <el-date-picker type="date" placeholder="选择日期" v-model="scope.scope.date2" style="width: 100%;"
              value-format="yyyy-MM-dd"></el-date-picker>
          </template>

          <template v-slot:class="scope" >
            <el-select v-model="scope.scope.class" :placeholder="$t('controller.txt3')"
              @change="currency_change(scope)">
              <el-option :label="$t('controller.txt1')" value="1"></el-option>
              <el-option :label="$t('controller.txt2')" value="2"></el-option>
            </el-select>
          </template>

          <template v-slot:shares="scope" >
            <!-- <el-input onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="scope.scope.shares"
              @blur="input_100(scope.scope.shares)" :placeholder="$t('controller.txt4')"></el-input> -->
            <el-input onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="scope.scope.shares"
              :placeholder="$t('controller.txt4')"></el-input>
          </template>

          <template v-slot:company="scope" >
            <search_input :type="type_2" @selCom="selCom" />

          </template>

          <template v-slot:remark="scope" >
            <el-input v-model="scope.scope.remark" :placeholder="$t('controller.txt6')"></el-input>
          </template>


        </Table>
      </el-col>

    </el-row>


    <div class="foot-btn">
      <el-button @click="onClear">{{ $t('cancel') }} </el-button>
      <el-button type="primary" @click="onConfirm">{{ $t('confirm') }} </el-button>
    </div>

  </div>
</template>

<script>

import bus from "@/bus";
import Division from '@/components/divisions'
import Table from "@/components/table/table";
import Search from '../search.vue'
import search_input from "../search_input.vue";
export default {
  components: {
    Table,
    Division,
    Search,
    search_input
  },

  props: {
    package: Object,
    show_share: Boolean,
    show_controller: Boolean,
  },
  watch: {
    'package': {
      immediate: true,
      handler(newVal) {
        // console.log(newVal)
        if (newVal.currencyOrdinary) {
          this.currency_ord = newVal.currencyOrdinary
        }
        if (newVal.currencyPre) {
          this.currency_pre = newVal.currencyPre
        }
        this.price = newVal.priceOrdinary
        this.price2 = newVal.pricePre
        // console.log(this.price)


      },
      deep: true
    },
  },
  data() {
    return {

      // price: 0,

      currency1: '',
      currency2: '',
      currency_pre: 'HKD',
      currency_ord: 'HKD',
      set_price: 1,
      price: 1,
      price2: 2,
      amount1: 0,
      amount2: 0,
      amount3: 0,
      total: 0,
      submit_check: false,
      type_2: "3",
      id_or_passport: 1,

      item: {

        "staffType": 1,
      },
      hkAddress: [],
      select_address: {},

      hkPassport: [],
      select_passport: 0,

      hkIdCards: [],
      select_idcard: 0,

      stack: [],
      controll: [{

      }],

      stockControlX: [],
      stockControl: [
        {
          id: 1,
          prop: 'class_s',
          label: '股份类别',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'total',
          label: '建議發行的股份總數',
          align: 'center',
          template: true
        },
        {
          id: 3,
          prop: 'currency',
          label: '貨幣',
          align: 'center',
          template: true
        },
        {
          id: 4,
          prop: 'amount1',
          label: '創辦成員認購的股本总额',
          align: 'center',

        },
        {
          id: 5,
          prop: 'amount2',
          label: '建設發行的股份的將要繳付或視為已繳付的股數',
          align: 'center',
          template: true
        },
        // {
        //   id: 6,
        //   prop: 'amount3',
        //   label: '建議發行的股份的尚未繳付或視為尚未繳付的總款額',
        //   align: 'center',
        //   template: true
        // },
        {
          id: 12,
          prop: 'edit',
          label: '操作',
          align: 'center',
          width: '120px',
          template: true
        }
      ],
      stockControlEn: [
        {
          id: 1,
          prop: 'class_s',
          label: 'Share class',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'total',
          label: 'The total number of shares proposed to be issued',
          align: 'center',
          template: true
        },
        {
          id: 3,
          prop: 'currency',
          label: 'currency',
          align: 'center',
          template: true
        },
        {
          id: 4,
          prop: 'amount1',
          label: 'Total share capital subscribed by founding members',
          align: 'center',

        },
        {
          id: 5,
          prop: 'amount2',
          label: 'The number of shares to be paid or deemed to have been paid',
          align: 'center',
          template: true
        },
        // {
        //   id: 6,
        //   prop: 'amount3',
        //   label: '建議發行的股份的尚未繳付或視為尚未繳付的總款額',
        //   align: 'center',
        //   template: true
        // },
        {
          id: 12,
          prop: 'edit',
          label: 'Operation',
          align: 'center',
          width: '120px',
          template: true
        }
      ],
      // 实际控制人列表
      Controller: [
        {
          id: 1,
          prop: 'date1',
          label: '委任日期',
          align: 'center',
          template: true
        },
        {
          id: 2,
          prop: 'date2',
          label: '辭任日期',
          align: 'center',
          template: true
        },
        {
          id: 3,
          prop: 'class',
          label: '持股類別',
          align: 'center',
          template: true
        },
        {
          id: 4,
          prop: 'shares',
          label: '最終持股股數量',
          align: 'center',
          template: true
        },
        {
          id: 5,
          prop: 'company',
          label: '投資公司名稱',
          align: 'center',
          template: true
        },
        {
          id: 6,
          prop: 'remark',
          label: '備註',
          align: 'center',
          template: true
        }
      ],
    }
  },

  created() {
    let self = this
    bus.$on('item', (data) => {
      this.select_address = {}
      this.select_idcard = ""
      this.select_passport = ""
      if (data.staffType == 1) {
        let after = JSON.stringify(data);
        let change = JSON.parse(after);
        // console.log(self.item)
        self.item = Object.assign(self.item, change)
        self.item.staffId = self.item.id

      }

    })
    if (localStorage.getItem("lang") == "en") {

      this.stockControlX = this.stockControlEn

    }
    if (localStorage.getItem("lang") == "cn") {
      this.stockControlX = this.stockControl

    }
  },
  watch: {
    "$i18n.locale"() {

      if (localStorage.getItem("lang") == "en") {
        console.log()
        this.stockControlX = this.stockControlEn

      }
      if (localStorage.getItem("lang") == "cn") {
        this.stockControlX = this.stockControl

      }


    }
  },
  methods: {
    // 地址选择
    addressChange(e) {
      console.log(e)
    },

    currency_change(row) {

      console.log(row)
      row.scope.set_price = row.scope.class_s == 'ordinary' ? this.price : this.price2
      row.scope.currency = row.scope.class_s == 'ordinary' ? this.currency_ord : this.currency_pre

      this.blurChange(row)
      this.totalBlurChange(row)
    },

    // 取消
    onClear() {
      this.$emit('onCancel')
    },

    // 确认
    onConfirm() {

      if (!this.select_address) {
        this.$message.error("請選擇地址");
      }
      else if (this.id_or_passport == 2 && !this.select_idcard.idNumber) {
        this.$message.error("請選擇身份證");
      }
      else if (this.id_or_passport == 1 && !this.select_passport.passport) {
        this.$message.error("請選擇護照");
      }
      else {
        this.item.region = this.select_address.region
        this.item.state = this.select_address.state
        this.item.street = this.select_address.street
        this.item.building = this.select_address.building
        this.item.address = this.select_address.address
        this.item.selAddress = this.select_address
        this.item.selPassport = this.select_passport
        this.item.selIdNumber = this.select_idcard
        this.item.shares = this.stack
        this.item.controll = this.controll[0]
        // console.log("d1" + JSON.stringify(this.item))
        this.$emit('onConfirm', this.item)
      }

    },

    // 认购的股本添加
    stockAdd() {

      if (this.stack.length >= 2) {
        this.$message.error("同一个股东只能够有两条股份记录")
      }
      else {
        let obj = { class_s: this.currency1, total: 0, currency: this.currency_ord, amount1: 0, amount2: 0, amount3: 0, set_price: this.price }
        this.stack.push(obj)
      }


    },

    deleteRow(index, rows) {
      rows.splice(index, 1);
    },


    totalBlurChange(scope) {

      let price = scope.scope.set_price
      if (!this.price) {
        return false
      }

      scope.scope.amount1 = price * scope.scope.total

    },

    // 股本总额计算
    blurChange(scope) {
      // console.log(scope)
      let price = scope.scope.set_price
      if (Number(scope.scope.total) < Number(scope.scope.amount2)) {
        this.$message.error('认购股本不能大于发行总数')
        return false
      }

      if (Number(scope.scope.amount1) < Number(scope.scope.amount2)) {
        this.$message.error('已缴纳总额不能大于股本总额')
        return false
      }
      if (Number(scope.scope.amount2)) {
        scope.scope.amount3 = parseInt(scope.scope.amount2) * price
      }
    },


    onAddressQuestion() {
      this.$alert('因相關條例要求, 創辦成員提供地址必需為住宅地址, 如被發現申報地址為非住宅地址, 本申請將有機會被公司註冊署退回, 相關申請時間延遲, 本公司概不負責。', '提示', {
        confirmButtonText: '确定',
        callback: action => {
          console.log(action)
        }
      });
    },
    input_100(val) {
      console.log(val)
      // 转换为整数并限制范围为1-100
      let num = parseInt(val)
      if (num < 0 || num > 100) {
        this.$message.error(this.$t('controller.txt5'))
      } else {
        // 不符合要求则清空输入框
        val = ''
      }
    },
    selCom(e) {
      this.controll[0].company = e
      console.log(this.controll)
    },
    showthis() {
      this.select_address
    }
  },

  computed: {

  }
}
</script>

<style lang="less" scoped>
.table-header {
  .box {
    padding-right: 20px;

    .form-title {
      padding-right: 10px;
      padding-bottom: 0px;
    }
  }
}
</style>