<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>{{ $t('myCompany.title') }}</span>
      </div>


      <FormTabel :config="basiConfig" />
      <el-button type="warning" @click="edit_company">{{ $t('myCompany.edit') }}</el-button>
    </el-card>

  </div>
</template>

<script>
import FormTabel from '@/components/form/form.vue'
import * as api from "@/api/api"
export default {
  components: {
    FormTabel
  },

  data() {
    return {
      // 基本信息
      basiConfig: {
        title: this.$t('company.companyName'),//'基本信息',
        labelWidth: '120px',

        title: this.$t('company.companyNameE'),
        list: [
          {
            title: this.$t('company.companyNameE'),//'公司中文名',
            key: 'companyName',
            type: 'input',
            value: '',
          },
          {
            title: '公司英文名',
            key: 'companyNameE',
            type: 'input',
            required: true,
            value: ''
          },
          {
            title: '企业邮箱',
            key: 'companyEmail',
            type: 'input',
            value: ''
          },
          {
            title: '企业地址',
            key: 'companyAddr',
            type: 'input',
            value: ''
          },
          {
            title: '企业电话',
            key: 'companyPhone',
            type: 'input',
            required: true,
            value: ''
          },

          {
            title: '企业代码',
            key: 'code',
            type: 'input',
            required: false,
            value: ''
          },
        ],
      },
      form: {
        name: ""
      }


    }
  },
  created() {
    this.get_data()
    this.$parent.$watch('$i18n.locale', () => {


      // 处理语言切换事件
      console.log('language changed')
      this.basiConfig.list[0].title = this.$t('company.companyName')
      this.basiConfig.list[1].title = this.$t('company.companyNameE')
      this.basiConfig.list[2].title = this.$t('myCompany.email')
      this.basiConfig.list[3].title = this.$t('myCompany.address')
      this.basiConfig.list[4].title = this.$t('myCompany.phone')
      this.basiConfig.list[5].title = this.$t('myCompany.code')
      console.log(this.basiConfig)
    })
  },

  methods: {

    get_data() {
      var params = {
        id: 1,
      }
      api.getRequest('/myCompany',
        params).then(resp => {
          this.basiConfig.list.map(d => {
            d.value = resp.data[d.key]
          })
          // console.log(resp)
          // this.basiConfig = 

        })
    },
    edit_company() {
      var obj = {
        id: 1,
        companyName: this.basiConfig.list[0].value,
        companyNameE: this.basiConfig.list[1].value,
        companyEmail: this.basiConfig.list[2].value,
        companyAddr: this.basiConfig.list[3].value,
        companyPhone: this.basiConfig.list[4].value,
        code: this.basiConfig.list[5].value

      }

      console.log(obj)
      api.putRequest('/myCompany', obj).then(resp => {
        // console.log(resp)
        if (resp.data == 1) {
          this.$message.success(resp.message);
          sessionStorage.setItem("myCompany", this.basiConfig.list[0].value)
          this.get_data()

        } else {
          this.$message.error(resp.message);
        }
      })
    }
  }


}
</script>

<style  scoped>
.header-btn {
  padding-bottom: 10px;
}

.el-card {
  margin-bottom: 20px;
}
</style>
